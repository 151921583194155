import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { Spinner, Form } from "react-bootstrap";
import { BASE } from "../../utils/Constants";
import Accordion from 'react-bootstrap/Accordion';

const Tutorials = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  async function getCMSDetail() {
    const response = await AuthService.getAllTutorial({title:searchQuery});
    setData(response?.data?.data);
  }



  useEffect(() => {
    getCMSDetail();
  }, [searchQuery]);





  return (
    <>
      <div className="wraper-inner bg-grey  space-top">
        <section className="terms-box pt-4 pb-5">
          <div className="container">
          

            <div className="similar-shadow-box main-box pb-5">
              <h3 className="inner-title mb-4 text-center">Tutorials</h3>
              <Form.Group controlId="searchQuery">
              <Form.Control
                  type="text"
                  className="hh"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Form.Group>
            <br></br>


            <Accordion defaultActiveKey="0">


            {data?.map((item, index) => (
  item?.title && (
    
    <Accordion.Item key={index} eventKey={item?.id}>
      
      <Accordion.Header>{item?.title}</Accordion.Header>

      <Accordion.Body>
      <div dangerouslySetInnerHTML={{ __html: item?.description }} />
      <br></br>     <br></br>
      <div className="attachment mb-4 text-center">
        {(item?.attachment[0]?.mimetype === "image/jpeg" || item?.attachment[0]?.mimetype === "image/png") && (
           <a href={BASE + "/" + item?.attachment[0]?.path} target="_blank" rel="noopener noreferrer">
           <img src={BASE + "/" + item?.attachment[0]?.path} />
          </a>
        )}

        {(item?.attachment[0]?.mimetype === "video/mp4" || item?.attachment[0]?.mimetype === "video/mov" ) && (
          <video
            controls  width="600"
          >
            <source
              src={BASE + "/" + item?.attachment[0]?.path}
              type={item?.attachment[0]?.mimetype}
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
        </Accordion.Body>

    </Accordion.Item>
  )
))}


    
            </Accordion>






          
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Tutorials;