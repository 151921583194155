import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getAllGroupPosts, getAllMemberOfSelectedGroup, getAllParticipatedGroups, getAllPosts, setSearched } from "../features/postSlice";

const SearchBar = () => {
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func?.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (e) => {
    dispatch(setSearched(e.target?.value));
    if (pathname === "/home") {
      const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
      dispatch(getAllPosts({ institutes: allInstitutesIds, search: e.target?.value, current_page: 1, adminInstitute }));
      const selectedIDs = profile?.roledata?.filter(data => !data?.is_deleted && data?.membership_status)?.filter(data => data?.role !== "member").map((data) => data?.instituteid);
      dispatch(getAllParticipatedGroups({ allInstitutesIds : selectedIDs, search: e.target?.value, current_page: 1 , per_page : 999999 }));
    } else {
      dispatch(getAllGroupPosts({ groupid: state?.id, search: e.target?.value, current_page: 1 }));
      dispatch(getAllMemberOfSelectedGroup({ groupid: state?.id, search: e.target?.value, current_page: 1, per_page : 999999 }));
    }
  };

  const optimisedCall = useCallback(debounce(handleChange), []);
  return (
    <div className="search-box mt-4 mb-4">
      <input type="text" name="" className="form-control" placeholder="Search here.." onChange={optimisedCall} />
      <button className="serch-btn">
        <i className="ti ti-search"></i>
      </button>
    </div>
  );
};

export default SearchBar;
