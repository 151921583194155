import ical from 'ical-generator';


export const downloadICS = (data) => {



  const cal = ical({ domain: 'yourdomain.com', name: data?.title });
  const event = cal.createEvent({
    start: new Date(data?.start_date),
    end: new Date(data?.end_date),
    summary: data?.title,
    description: data?.description,
    location: data?.location,
  });

  // Generate the .ics file as a string
  const icsString = cal.toString();

  // Create a Blob object from the string
  const blob = new Blob([icsString], { type: 'text/calendar' });

  // Create a link element
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', 'MyEvent.ics');

  // Simulate a click on the link to trigger the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};
