import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SchoolAdmin from "../../services/admin.service";
import { Delete, Error, Info, Note } from "../../utils/Alert";
import AddAdmin from "./tabs/AddAdmin";
import { useDispatch, useSelector } from "react-redux";

const ManageAdmin = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const {adminInfo} = useSelector((state) => state?.auth);

  useEffect(() => {
    SchoolAdmin.viewMemberByRoles({ role: "admin" })
      .then((res) => setData(res?.data?.members))
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });
  }, [refresh]);

  const handleDelete = (row) => {
    const { userid, instituteid } = row;
    Swal.fire({ title: "Are you sure you want to delete the Admin?", ...Delete }).then((result) => {
      if (result?.isConfirmed) {
        let a = []
        let grpid = row?.grps?.map((grp) => grp?.group_id);

        if(!grpid[0])  grpid = a;
  
        SchoolAdmin.deleteMember({ userid, instituteid, role: "admin", grpid })
          .then(() => {
            setRefresh(!refresh);
            Swal.fire({ text: Note.AdminDeleted, ...Info });
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            Swal.fire({ text: message ? message : Note.Err, ...Error });
          });
      }
    });
  };

  return (
    <>
      <div className="tab-pane fade show active" id="tabs-4" role="tabpanel" aria-labelledby="tabbing-4">
        <div className="pb-5">
          <div className="d-flex justify-content-between">
            <h3 className="inner-title mb-4">Manage Admins</h3>
            <Link to="/add-members" state={{ role: "admin" }} className="blue-link">
              Add New Admin
            </Link>
          </div>
          <ul className="manage-admin-list">
            {data?.length ? (
              data?.map((user, index) => (
                <li className="manage-admin-list-box similar-shadow-3 d-flex justify-content-between" key={index}>
                  <div className="manage-admin-list-L">
                    <h4>
                      {user?.firstname} {user?.lastname}
                    </h4>
                    <div className="contact-info d-flex">
                      <Link to="#">
                        <i className="ti ti-mail"></i> {user?.email}
                      </Link>
                      <Link to="#">
                        <i className="ti ti-phone"></i> {user?.phoneno}
                      </Link>
                    </div>
                  </div>
                  <div className="manage-admin-list-R d-flex">
                    <Link to="/edit-member-profile" state={{ id: user?.userid, memberid: user?.memberid }} className="edit-post ms-3">
                      <i className="ti ti-pencil"></i>
                    </Link>

                    {adminInfo?.memberid != user?.memberid && 

                    <Link to="#" className="delete-post ms-2" onClick={() => handleDelete(user)}>
                    <i className="ti ti-trash"></i>
                    </Link>

                    }
                  
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center">There are no records to display</div>
            )}
          </ul>
          {/* <div className="mt-5 btn-box  d-flex justify-content-start">
            <button className="btn min-btn black-btn hvr-sweep-to-right me-2">Cancel</button>
            <button className="btn min-btn hvr-sweep-to-right ms-2">Update</button>
          </div> */}
        </div>
      </div>
      {/* <!-- Add New Admin--> */}
      <AddAdmin refresh={refresh} setRefresh={setRefresh} />
    </>
  );
};

export default ManageAdmin;
