import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllDMPosts, setPriorityIds } from "../../features/postSlice";
import CardW from "../../components/wrapper/CardW";
import AllPosts from "../../components/post/AllPosts";

function ListSentDM() {
  const dispatch = useDispatch();
  const {  allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const { refresh } = useSelector((state) => state?.posts);
  const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
  const {priorityIds} = useSelector(state => state.posts)

  useEffect(() => {
    return () =>{
      dispatch(setPriorityIds([]))
    }
  }, []);

  useEffect(() => {
    dispatch(getAllDMPosts({ current_page: 1 , institutes: allInstitutesIds, priorityIds, adminInstitute }));
    // eslint-disable-next-line
  }, [refresh]);
  return (
    <CardW>
      <div className="head-btn d-flex justify-content-between mb-4 align-items-center">
        <h3 id="top" className="inner-title">Direct Messages</h3>
        <Link className="btn common-btn hvr-sweep-to-right" to="/createmessage">
          Create DM
        </Link>
      </div>
      <AllPosts dm={true} />
    </CardW>
  );
}

export default ListSentDM;
