import { ErrorMessage, Field ,useFormikContext} from "formik";
import { handleBlurTrim } from "../../utils/FileSize";

export const CustomInputField = ({ name, placeholder, label, disabled, handleChange, handleKeyPress, handleNumberInput }) => {
  const { setFieldValue } = useFormikContext();
  const handleBlur = (event) => {
    let { value } = event.target;
    value = value.replace(/^\s+/, '');  
    value = value.replace(/\s\s+/g, ' '); 
    handleBlurTrim((field, value) => setFieldValue(field, value))(event);

    setFieldValue(name, value);
  };
  return (
    <label className="custom-field one without-icon">
      <Field 
          name={name}
          placeholder={placeholder} 
          disabled={disabled} 
          onChange={handleBlur}
          onKeyPress={handleKeyPress}
          onInput={handleNumberInput}
      />
      <span className="placeholder">{label}</span>
      <ErrorMessage component="p" className="text-danger" name={name} />
    </label>
  );
};
