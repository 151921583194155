import { EMAIL_REGEX } from "./Constants";

export const complexEmailRegex = (val) => {
  if (EMAIL_REGEX?.test(val)) {
    if (val?.match(/\+/g)?.length > 1) {
      return false;
    }
    if (val?.includes(".+.")) {
      return false;
    } else if (val?.includes(".+")) {
      return true;
    } else if (val?.includes("+.")) {
      return true;
    } else if (val?.includes("++")) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
