import { useState } from "react";
import Finance from "../../services/finance.service";
import { PostDescription, PostMedia, PostTabs, PostTitle } from "./postBlocks";

const AdPost = ({ data }) => {
  // const [click, setClickable] = useState(true);
  // const fxClick = () => {
  //   setClickable(false);
  //   Finance.countNumberOfClicks({ unique_id: data?.unique_id })
  //     .then(() => setClickable(true))
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="bg-grey-post sponsored-box">
      <div className="all-posts-box sponsored-box-head border-box mb-3">
        <PostTitle data={data} />
        <PostTabs data={data} />
        <PostDescription data={data} />
        <PostMedia data={data} />
      </div>
    </div>
  );
};

export default AdPost;
