import { useEffect, useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Post from "../../services/post.service";
import Receiver from "../../components/modalBlocks/Receiver";
import Swal from "sweetalert2";
import { Error, Info, Note, Success } from "../../utils/Alert";
import { showPostError } from "../../utils/PostError";
import { useDispatch, useSelector } from "react-redux";
import CardW from "../../components/wrapper/CardW";
import { getUserInfo, roleCheck } from "../../utils/RoleCheck";
import { dateTimeFormat, filterPassedTime } from "../../utils/DateFormat";
import DatePicker from "react-datepicker";
import Fees from "../../components/misc/Fees";
import { validationSchema } from "./DMValidationSchema";
import { TagsInput } from "react-tag-input-component";
import { beforeAddValidate } from "../../utils/ValidateTag";
import SelectInstitute from "../../components/modalBlocks/SelectInstitute";
import { Spinner, Modal } from "react-bootstrap";
import GoogleCalenderEvent from "../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import OutlookAuth from "../../components/modalBlocks/OutlookAuth";
import { AWS_BUCKET_NAME, supportedVideoFormats } from "../../utils/Constants";
import SchoolAdmin from "../../services/admin.service";
import { TextCharacterLimitShow, TrimehandleChange, handleBlurTrim, handleFileChange, upload_thumbnail_on_S3 } from "../../utils/FileSize";
import Finance from "../../services/finance.service";
import { getMe } from "../../features/authSlice";

function CreateDM() {
  const { state } = useLocation();
  const { allInstitutesIds, profile, adminInfo } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const [show, setShow] = useState(false);
  const [showInstituteM, setShowInstituteM] = useState(false);
  const [loading, setLoading] = useState(true);

  const [syncShow, setSyncShow] = useState(false);
  const [syncToken, setSyncToken] = useState(null);
  const [syncOn, setSyncOn] = useState(null);
  const [errorMsg, setErrormsg] = useState('');


  const updateParentState = newState => {
    setSyncShow(newState?.popup);
    setSyncToken(newState?.access_token);
    setSyncOn(newState?.sync_on);
  };


  useEffect(() => {
    Post.viewAllDMReceiver({ institutes: allInstitutesIds })
      .then((res) => {
        if (state?.userid) {
          let user = res?.data?.ViewData?.find((x) => x?.userid === state?.userid);
          setSelectedUser(user);
        } else {
          let temp = profile?.roledata;
          if (temp?.length === 1) {
            setSelectedUser(temp[0]);
          }
        }

        setData(res?.data?.ViewData);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleShow = (e) => {
    e.preventDefault();
    if (data?.length) {
      setShow(true);
    } else {
      Swal.fire({ text: Note?.NoMemberForDM, ...Info });
    }
  };



  const handlePayment = (e, values) => {
    if (e?.currentTarget?.checked) {
      const user = profile?.roledata?.find((row) => row?.instituteid === values?.instituteid);
      if (showPostError(user, navigate)) { return false };
    }
  };


  const initialValues = {
    dmpost: true,
    title: "",
    description: "",
    disable_comments: false,
    instituteid: selectedUser?.instituteid ? selectedUser?.instituteid : "",
    institutename: selectedUser?.institutename ? selectedUser?.institutename : "",
    receiver_id: selectedUser?.userid ? selectedUser?.userid : "",
    receiver_name: selectedUser?.fullname ? selectedUser?.fullname : "",
    added_by: selectedUser?.instituteid ? getUserInfo(profile?.roledata, selectedUser?.instituteid)?.role : "",
    event_type: "standard_event",
    start_date: new Date(),
    end_date: "",
    location: "",
    price: "",
    sales_tax: "",
    colours: [],
    size: [],
    fileuploaded: [],
    fileValidation: "",
    thumbnailValidation: "",
    member_id: "",
    rsvp: false,
    paid_events: false,
    paid_event_amount: "",
    due_date: "",
    sync: false,
  };

  const onSubmit = (values, { setSubmitting }) => {
    const { start_date, end_date, due_date, group_id, colours, size, event_type, ...rest } = values;
    const user = profile?.roledata?.find((row) => row?.instituteid === selectedUser?.instituteid);
    if (start_date || due_date) {
      const startDateTime = new Date(start_date);
      const dueDateTime = new Date(due_date);
      const startDay = `${startDateTime.getFullYear()}-${startDateTime.getMonth() + 1}-${startDateTime.getDate()}`;
      const dueDay = `${dueDateTime.getFullYear()}-${dueDateTime.getMonth() + 1}-${dueDateTime.getDate()}`;
      if (startDay === dueDay || !due_date) {
        dueDateTime.setTime(startDateTime.getTime() + 30 * 60 * 1000);
      } else if (startDateTime > dueDateTime) {
        dueDateTime.setHours(23, 59, 0);
      }
      values.due_date = dueDateTime;
    }

    values.start_date = values?.start_date ? values?.start_date : "";
    values[event_type] = true;
    values.end_date = values?.end_date ? values?.end_date : "";
    values.due_date = values?.due_date ? values?.due_date : "";
    values.group_id = group_id;
    values.colours = colours;
    values.size = size;
    values.stripe_account_id = user?.stripe_account_id;
    values.accept_payment = user?.accept_payment;
    values.access_token = syncToken;
    values.sync_on = syncOn;



    if (event_type === 'calendar_event' && values?.paid_events === true) {
      if (showPostError(user, navigate)) { return false };
    }
    else if (event_type === 'merchandise_sale') {
      if (showPostError(user, navigate)) { return false };
    }

    Finance.List({ type: "view", ...adminInfo })
    dispatch(getMe());

    Post?.addpost(values)
      .then(() => {
        setSubmitting(true);
        Swal.fire({ text: Note.PostCreated, ...Success });
        navigate("/sendmessage");
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        if (message) {
          Swal.fire({ text: message, ...Error });
        } else Swal.fire({ text: Note?.Err, ...Error });
        setSubmitting(false);
      });
  };

  let content = (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors, setSubmitting }) => (
        <form onSubmit={handleSubmit}>

          <div className="mb-4 ">
            <Link to="#" className="select-group d-flex justify-content-between" onClick={() => setShowInstituteM(true)}>
              Select Institute
              <div className="d-flex align-items-center value-select">
                <i className="ti ti-chevron-right"></i>
              </div>
            </Link>
            <ErrorMessage component="p" className="custom-field text-danger" name="instituteid" />
            {values?.instituteid && (
              <div className="tags-input-wrapper" style={{ border: "none" }}>
                <span className="tag">{values?.institutename}</span>
              </div>
            )}
          </div>
          <div className="mb-4 ">
            {values?.instituteid && (
              <Link to="#" onClick={handleShow} className="select-group d-flex justify-content-between">
                Select Receiver
                <div className="d-flex align-items-center value-select">
                  <i className="ti ti-chevron-right"></i>
                </div>
              </Link>
            )}
            <ErrorMessage component="p" className="custom-field text-danger" name="receiver_id" />
            <span className="text-success" style={{ fontSize: "14px" }}>
              {values?.receiver_id && (
                <div className="tags-input-wrapper" style={{ border: "none" }}>
                  <span className="tag">{values?.receiver_name}</span>
                </div>
              )}
            </span>
          </div>
          <div className="mb-4">
            <label className="custom-field one without-icon">
              <Field
                name="title"
                placeholder=" "
                // onBlur={handleBlurTrim(handleChange)}
                onChange={TrimehandleChange(setFieldValue)('title')}
              />
              <span className="placeholder">Post Title</span>
              <ErrorMessage component="p" className="text-danger" name="title" />
            </label>
          </div>
          <div className="mb-4 textarea-box">
            <label className="custom-field one without-icon">
              <textarea className="label-textarea" placeholder="Description" rows="5" name="description" onChange={handleChange} onBlur={handleBlurTrim(handleChange)} value={values?.description} />
              <ErrorMessage component="p" className="text-danger" name="description" />
            </label>
          </div>
          <div className="mb-4">
            <div className="file-upload-wrapper" data-text="Add Attachment">
              <input
                name="fileValidation"
                type="file"
                className="file-upload-field"
                onChange={(e) => handleFileChange(e, setFieldValue, setSubmitting, setErrormsg)}
                onClick={(e) => {
                  e.target.value = null;
                  setFieldValue("thumbnailValidation", "");
                  setFieldValue("fileuploaded[0].thumbnail", "");
                  setErrormsg('');
                }}
              />
              <button>
                <i className="ti ti-paperclip"></i>
              </button>
            </div>
            <div className="custom-field text-danger">{errorMsg}</div>

            {values?.fileValidation && (
              <div className="text-success d-flex align-items-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                {TextCharacterLimitShow(values?.fileValidation?.name, 50) + " file selected"}
                <div className="delete-post ms-2" onClick={() => {
                  setFieldValue("thumbnailValidation", "");
                  setFieldValue("fileuploaded[0].thumbnail", "");
                  setFieldValue("fileValidation", "");
                  setFieldValue("fileuploaded[0].file", "");
                  setFieldValue("thumbnailValidation", "");
                  setFieldValue("fileuploaded[0].thumbnail", "");
                }}>
                  <i className="ti ti-trash"></i>
                </div>
              </div>
            )}

            {(supportedVideoFormats.includes(values?.fileuploaded[0]?.mimetype)) && (
              <>
                <div className="file-upload-wrapper" data-text="Add Thumbnail">
                  <input
                    name="thumbnailValidation"
                    type="file"
                    className="file-upload-field"
                    onChange={(e) => {
                      if (e?.target?.files[0]?.type?.startsWith('image/')) {
                        upload_thumbnail_on_S3(e.target?.files[0], setFieldValue, setSubmitting);
                      } else {
                        Swal.fire({ text: "Please upload an image", ...Error });
                      }
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    accept="image/*"
                  />
                  <button>
                    <i className="ti ti-paperclip"></i>
                  </button>
                </div>

                <ErrorMessage component="p" className="custom-field text-danger" name="thumbnailValidation" />

                {values?.thumbnailValidation && (
                  <div className="text-success d-flex align-items-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                    {TextCharacterLimitShow(values?.thumbnailValidation?.name, 50) + " file selected"}
                    <div className="delete-post ms-2" onClick={() => {
                      setFieldValue("thumbnailValidation", "");
                      setFieldValue("fileuploaded[0].thumbnail", "");
                    }}>
                      <i className="ti ti-trash"></i>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>


          <div className="mb-4 d-flex">
            <label className="coustom-checkbox s-check-box me-4">
              <Field type="checkbox" name="disable_comments" />
              <span className="checkmark"></span>
              <p>Disable Comments</p>
            </label>

            <div className="radio-flex-box d-flex">
              {values?.added_by && values?.added_by !== "member" ? (
                <label className="radio-coustom-design me-4">
                  <Field type="radio" name="event_type" value="calendar_event" />
                  <span className="checkmark"></span>
                  <p>Calendar Event</p>
                </label>
              ) : null}
              {values?.added_by === "admin" ? (
                <label className="radio-coustom-design me-4">
                  <Field type="radio" name="event_type" value="merchandise_sale" onClick={(e) => { handlePayment(e, values) }} />
                  <span className="checkmark"></span>
                  <p>Merchandise Sale</p>
                </label>
              ) : null}
            </div>
          </div>

          {values?.event_type === "calendar_event" ? (
            <>
              <h4 className="sub-title mb-4">Calendar Event</h4>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <DatePicker
                    placeholderText="Start Date"
                    selected={values?.start_date}
                    dateFormat={dateTimeFormat}
                    className="form-control"
                    name="start_date"
                    showTimeSelect
                    onChange={(date) => setFieldValue(`start_date`, date)}
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    onChangeRaw={(e) => e.preventDefault()}
                    autoComplete="off"
                    timeIntervals={15}
                  />
                  <ErrorMessage component="p" className="custom-field text-danger" name="start_date" />
                </div>
                <div className="col-md-6 mb-4" >
                  <DatePicker
                    placeholderText="End Date"
                    selected={values?.end_date}
                    dateFormat={dateTimeFormat}
                    className="form-control"
                    name="end_date"
                    showTimeSelect
                    onChange={(date) => setFieldValue(`end_date`, date)}
                    minDate={values?.start_date}
                    filterTime={(time) => filterPassedTime(time, values?.start_date)}
                    onChangeRaw={(e) => e.preventDefault()}
                    autoComplete="off"
                    timeIntervals={15}
                  />
                  <ErrorMessage component="p" className="custom-field text-danger" name="end_date" />
                </div>
                <div className="col-md-12 mb-4">
                  <label className="custom-field one without-icon">
                    <Field type="text" name="location" onBlur={handleBlurTrim(handleChange)} />
                    <span className="placeholder">Location</span>
                    <ErrorMessage component="p" className="text-danger" name="location" />
                  </label>
                </div>
              </div>
              <div className="mb-4 d-flex">
                <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="rsvp" />
                  <span className="checkmark"></span>
                  <p>Allow RSVP</p>
                  <ErrorMessage component="p" className="text-danger" name="rsvp" />
                </label>
                {roleCheck(["admin", "teacher"], [values?.added_by]) && (
                  <label className="coustom-checkbox s-check-box me-4">
                    <Field type="checkbox" name="paid_events" onClick={(e) => { handlePayment(e, values) }} />
                    <span className="checkmark"></span>
                    <p>Paid Event</p>
                  </label>
                )}

                <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="sync" onChange={(e) => {
                    if (e.target?.checked) {
                      setFieldValue('sync', true);
                      setSyncShow(true);
                    } else { setFieldValue('sync', false); }
                  }} />
                  <span className="checkmark"></span>
                  <p>Add to external calendar</p>
                </label>
              </div>
              {values?.paid_events ? (
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="with-icon">
                      <i className="ti ti-currency-dollar"></i>
                      <Field type="text" name="paid_event_amount" placeholder="Enter amount" className="form-control" />
                    </div>
                    <ErrorMessage component="p" className="text-danger" name="paid_event_amount" />
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <DatePicker
                        placeholderText="Due Date"
                        selected={values?.due_date}
                        dateFormat={"MM-dd-yyyy"}
                        className="form-control"
                        name="due_date"
                        onChange={(date) => setFieldValue(`due_date`, date)}
                        minDate={new Date()}
                        onChangeRaw={(e) => e.preventDefault()}
                        autoComplete="off"
                      />
                      {values?.due_date && <i className="ti ti-trash" style={{ cursor: "pointer" }} onClick={() => { setFieldValue(`due_date`, "") }}>Remove</i>}

                    </div>
                    <ErrorMessage component="p" className="text-danger" name="due_date" />




                  </div>

                  <Fees />
                </div>
              ) : null}
            </>
          ) : null}

          {values?.event_type === "merchandise_sale" ? (
            <>
              <h4 className="sub-title mb-4">Merchandise Sale</h4>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="with-icon custom-field">
                    <i className="ti ti-currency-dollar"></i>
                    <Field type="text" name="price" placeholder="Price" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                  </div>
                  <ErrorMessage component="p" className="text-danger" name="price" />
                </div>
                <div className="col-md-6 mb-4">
                  <div className="with-icon custom-field">
                    <i className="ti ti-currency-dollar"></i>
                    <Field type="text" name="sales_tax" placeholder="Estimated Sales Tax" className="form-control" onBlur={handleBlurTrim(handleChange)} />
                  </div>
                  <ErrorMessage component="p" className="text-danger" name="sales_tax" />
                </div>
              </div>
              <div className="mb-4 ">
                <TagsInput
                  name="colours"
                  value={values?.colours}
                  onChange={(values) => setFieldValue("colours", values)}
                  beforeAddValidate={beforeAddValidate}
                  separators={[","]}
                  placeHolder={values?.colours?.length ? "" : "Pick color (use comma to enter multiple color options)"}
                />
                <ErrorMessage component="p" className="text-danger" name="colours" />
              </div>
              <div className="mb-4">
                <TagsInput
                  name="size"
                  value={values?.size}
                  onChange={(values) => setFieldValue("size", values)}
                  beforeAddValidate={beforeAddValidate}
                  separators={[","]}
                  placeHolder={values?.size?.length ? "" : "Pick size (use comma to enter multiple size options)"}
                />
                <ErrorMessage component="p" className="text-danger" name="size" />
              </div>
              <Fees />
            </>
          ) : null}

          <div className="mt-4 d-flex justify-content-center">
            <button type="button" className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button type="submit" disabled={isSubmitting} className="btn min-btn hvr-sweep-to-right">
              Post
            </button>

          </div>
          {show && <Receiver show={show} setShow={setShow} setReceipient={setFieldValue} instituteid={values?.instituteid} data={data} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
          {showInstituteM && (
            <SelectInstitute
              showInstituteM={showInstituteM}
              setShowInstituteM={setShowInstituteM}
              setReceipient={setFieldValue}
              data={data}
              onlyInstitute={true}
              instituteid={values?.instituteid}
              institutename={values?.institutename}
            />
          )}

          <Modal show={syncShow} onHide={() => { setSyncShow(false); setFieldValue('sync', false); }}>
            <Modal.Header closeButton>
              <Modal.Title>Add to calendar</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div className='syncButton' style={{ textAlign: "center" }}>
                <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
                  <GoogleCalenderEvent updateParentState={updateParentState}></GoogleCalenderEvent>
                </GoogleOAuthProvider>
              </div>
              <br></br>
              <OutlookAuth updateParentState={updateParentState} />

            </Modal.Body>

          </Modal>
        </form>
      )}
    </Formik>
  );

  return (
    <CardW>
      <h3 className="inner-title mb-4">Create DM</h3>
      {loading ? <Spinner animation="border" variant="success" /> : content}
    </CardW>
  );
}

export default CreateDM;
