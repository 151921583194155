import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import postUser from "../../../assets/images/DefaultUserIcon.png";
import { close } from "../../../features/eventSlice";
import { PostDescription, PostGroups } from "../postBlocks";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { NOSPACE_REGEX } from "../../../utils/Constants";
import { MESSAGE } from "../../../utils/ValidationMessage";

const validationSchema = Yup.object().shape({
  comment: Yup.string().strict(true).max(100, "Comment should be less than 100").matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
});

const Renew = () => {
  const { data: post, renew, payment } = useSelector((state) => state?.event);
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(close("renew"));
  let admin = adminInfo?.instituteid === post?.instituteid;
  let member = profile?.roledata?.find((user) => user?.instituteid === post?.instituteid);


  const initialValues = {
    title: post?.title,
    accept_payment : payment?.accept_payment,
    stripe_account_id : payment?.stripe_account_id,
    event_type: "renew",
    instituteid: post?.instituteid,
    postid: post?.postid,
    description: post?.description,
    memberid: member?.memberid,
    userid: profile?.id,
    childid: post?.renewalchild[0] ? post?.renewalchild[0]?.childid : -1,
    child_name: post?.renewalchild[0] ? post?.renewalchild[0]?.child_name : "",
    comment: "",
    price : post?.price,
    cardData : true,
    stripe_account_id: member?.stripe_account_id,
    commenters_member: post?.commenters_member[0],
    renewalchild: post?.renewalchild[0],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleClose();
      navigate("/payment", { state: { details: { ...values } } });
    },
  });

  let content = null;
  if (admin) {
    let totalprice = post?.postevent[0]
    ? parseFloat(post?.postevent.reduce((accumulator, currentObject) => {
        return accumulator + currentObject?.price;
      }, 0).toFixed(2))
    : 0.00;
  totalprice = totalprice.toFixed(2);

    let renewContent = post?.postevent?.map((data, index) => {
      const user = post?.eventusers[0] && post?.eventusers?.find((e) => e?.id === data?.userid);
      return (
        <tr>
          <td>
            <div className="List-Attendees-user d-flex align-items-center">
              <figure>
                <img className="avatarimage" src={postUser} alt="icon" />
              </figure>
              <h4>{user?.fullname}</h4>
            </div>
          </td>
          <td>{post?.renewalchild[0]?.child_name}</td>
          <td>${post?.price ? post?.price.toFixed(2) : 0.00}</td>
          <td>{data?.confirmPaymentId}</td>
          <td>{data?.comment}</td>
        </tr>
      );
    });

    content = (
      <>
        <div className="">
          <div className="all-posts-box similar-shadow-3">
            <h4>
              {post?.title}
              <Link to="#" className="calender-box">
                <i className="ti ti-calendar"></i>
              </Link>
            </h4>
            <PostGroups data={post} />
            <PostDescription data={post} />
          </div>
          <hr className="border-grey w-50 d-flex mx-auto" />
          <div className="row ">
            <div className="col-md-12 text-center">
              <div className="adult-info grey-bg d-flex justify-content-between align-items-center pt-3 pb-3">
                <h4 className="mb-0"> Total Renewal Amount</h4>
                <div className="adult-info-num d-flex justify-content-center">
                  <span>
                    <b>${totalprice}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h4 className="sub-title mb-3 mt-4">Renewals</h4>
          <div className="over-table">
            {post?.postevent[0] ? (
              <table width="100%" className="list-attendees">
                <thead>
                  <tr>
                    <th>Member Name</th>
                    <th>Child Name</th>
                    <th>Paid</th>
                    <th>Confirmation#</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>{renewContent}</tbody>
              </table>
            ) : (
              <div className="text-center">No data found</div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <div className="pay-box-content pb-0 d-flex justify-content-between">
            <h4 className="pb-0">Institute</h4>
            <p>{post?.institutename} </p>
          </div>
          <hr className="grey-line" />
          <div className="pay-box-content pb-0 d-flex justify-content-between">
            <h4 className="pb-0">{post?.renewalchild[0] ? "Child Name" : "Member Name"}</h4>
            <p>{post?.renewalchild[0] ? post?.renewalchild[0]?.child_name : post?.renewalusers[0]?.fullname} </p>
          </div>
          <hr className="grey-line" />
          <div className="pay-box-content pb-0 d-flex justify-content-between">
            <h4 className="pb-0">Amount</h4>
            <p>
              <b className="blue-text">${post?.price ? post?.price.toFixed(2) : 0.00} </b>
            </p>
          </div>
          <hr className="grey-line" />
          <div className="mb-4 textarea-box">
            <Field as="textarea" className="form-control" name="comment" placeholder="Add a comment" />
            <ErrorMessage component="p" className="text-danger" name="comment" />
          </div>
          <div className="btn-group-modal mt-4">
            <button type="submit" className="btn full-btn hvr-sweep-to-right">
              Pay
            </button>
          </div>
        </Form>
      </FormikProvider>
    );
  }

  return (
    <Modal size={admin ? "xl" : "md"} centered show={renew} onHide={handleClose} keyboard={false}>
      <Modal.Header className="p-4 border-0" closeButton>
        <Modal.Title className="ms-auto event-title">Renew</Modal.Title>
      </Modal.Header>
      <div className="modal-body px-5">{content}</div>
    </Modal>
  );
};

export default Renew;
