import { Link } from "react-router-dom";
import ContactUs from "../modalBlocks/ContactUs";

const Footer = () => {
  return (
    <>
      <footer className="fixed-footer">
        <div className="footer-box">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <ul className="footer-link d-flex">
                  <li>
                    <Link to="/termsandcondition" target="_blank">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy" target="_blank">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact" target="_blank">Contact Us</Link>
                    {/* <Link to="#" aria-current="page" data-bs-toggle="modal" data-bs-target="#contact-us">Contact Us</Link> */}
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-right d-flex justify-content-end all-right">
                <p>© All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ContactUs />
    </>
  );
};

export default Footer;
