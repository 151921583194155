import { useSelector } from "react-redux";
import { getUserInfo } from "./RoleCheck";

export const PostPermission = (post, data) => {
  const { profile } = useSelector((state) => state?.auth);

  
  let { role: viewer } = getUserInfo(profile?.roledata, data?.instituteid) || {};

  let access = { showEdit: false, showDelete: false, showEvent: true };

  if (data?.dmpost) {
    if (profile?.id === data?.sender_id) {
      access = { showEdit: true, showDelete: true, showEvent: true };
    } else if (viewer === "admin") {
      if (profile?.id === data?.receiver_id) {
        access = { showEdit: false, showDelete: true, showEvent: true };
      } else {
        access = { showEdit: true, showDelete: true, showEvent: true };
      }
    }
    return access;
  }

  if (viewer === "admin") {
    access = { showEdit: true, showDelete: true, showEvent: true };
    return access;
  }

  if (post === "standard") {
    if (viewer === "teacher") {
      if (data?.added_by_role === "admin") {
        access = { showEdit: false, showDelete: false, showEvent: false };
      } else if (data?.added_by_role === "teacher") {
        access = { showEdit: true, showDelete: true, showEvent: false };
      } else if (data?.added_by_role === "parent") {
        access = { showEdit: false, showDelete: false, showEvent: false };
      }
    } else if (viewer === "parent") {
      if (data?.added_by_role === "admin") {
        access = { showEdit: false, showDelete: false, showEvent: false };
      } else if (data?.added_by_role === "teacher") {
        access = { showEdit: false, showDelete: false, showEvent: false };
      } else if (data?.added_by_role === "parent") {
        if (profile?.id === data?.sender_id) {
          access = { showEdit: true, showDelete: true, showEvent: true };
        }
      }
    }
  } else if (post === "calendar") {
    let rsvpAndPay = data?.rsvp && data?.paid_events;
    let rsvp = data?.rsvp && !data?.paid_events;
    let pay = !data?.rsvp && data?.paid_events;

    if (rsvp) {
      if (viewer === "teacher") {
        if (data?.added_by_role === "admin") {
          access = { showEdit: false, showDelete: false, showEvent: false };
        } else if (data?.added_by_role === "teacher") {
          access = { showEdit: true, showDelete: true, showEvent: true };
        } else if (data?.added_by_role === "parent") {
          access = { showEdit: false, showDelete: false, showEvent: true };
        }
      } else if (viewer === "parent") {
        if (data?.added_by_role === "admin") {
          access = { showEdit: false, showDelete: false, showEvent: true };
        } else if (data?.added_by_role === "teacher") {
          access = { showEdit: false, showDelete: false, showEvent: true };
        } else if (data?.added_by_role === "parent") {
          if (profile?.id === data?.sender_id) {
            access = { showEdit: true, showDelete: true, showEvent: true };
          }
        }
      }
    } else if (pay) {
      if (viewer === "teacher") {
        if (data?.added_by_role === "admin") {
          access = { showEdit: false, showDelete: false, showEvent: false };
        } else if (data?.added_by_role === "teacher") {
          access = { showEdit: true, showDelete: true, showEvent: true };
        }
      }
    } else if (rsvpAndPay) {
      if (viewer === "teacher") {
        if (data?.added_by_role === "admin") {
          access = { showEdit: false, showDelete: false, showEvent: false };
        } else if (data?.added_by_role === "teacher") {
          access = { showEdit: true, showDelete: true, showEvent: true };
        }
      }
    } else {
      if (profile?.id === data?.sender_id) {
        access = { showEdit: true, showDelete: true, showEvent: true };
      }
    }
  } else if (post === "merchandise") {
    if (viewer === "teacher") {
      access = { showEdit: false, showDelete: false, showEvent: false };
    }
  }

  return access;
};

export const PermissionCheck = ({ children, instituteid, checkAdmin }) => {
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  let user = getUserInfo(profile?.roledata, instituteid);
  if (instituteid) {
    if (checkAdmin) {
      if (adminInfo?.instituteid === instituteid) return <>{children}</>;
      else return null;
    } else {
      if (user?.role === "member") return null;
      else return <>{children}</>;
    }
  } else return null;
};
