import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SchoolAdmin from "../../../services/admin.service";
import { Error } from "../../../utils/Alert";
import { open } from "../../../features/eventSlice";
import { useDispatch, useSelector } from "react-redux";

function ListMember({ small, instituteid }) {
  const { list } = useSelector((state) => state.posts);
  const dispatch = useDispatch();

  const [data, setData] = useState(list);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      try {
        if (!small) {
          const res = await SchoolAdmin.getAllMemberOfGroups({ search, current_page: page });
          setData(res?.data?.users);
          setTotalPages(res?.data?.totalPages);
        }
      } catch (error) {
        console.log(error);
        const { message } = error?.response?.data;
        if (message) {
          Swal.fire({ text: message, ...Error });
        }
      }
    })();
  }, [search, page]);

  const handlePageChange = (pageNo) => setPage(pageNo);

  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }

  const CxPagination = ({ onChangePage, currentPage }) => {
    const handleBackButtonClick = () => onChangePage(currentPage - 1);

    const handleNextButtonClick = () => onChangePage(currentPage + 1);

    const handlePageNumber = (e) => onChangePage(Number(e.target.value));

    // const totalpages = Math.trunc(data.length / 10 + 1);
    const pageItems = toPages(totalPages);
    const nextDisabled = currentPage === totalPages;
    const previosDisabled = currentPage === 1;

    return (
      <>
        {totalPages > 1 ? (
          <div className="d-flex justify-content-center">
            <ul className="pagination">
              <li className={`page-item ${previosDisabled ? "disabled" : ""}`}>
                <button className={`page-link`} onClick={handleBackButtonClick} disabled={previosDisabled} aria-disabled={previosDisabled} aria-label="previous page">
                  <span aria-hidden="true">
                    <i className="ti ti-arrow-narrow-left"></i>
                  </span>
                </button>
              </li>
              {pageItems?.map((page) => {
                const className = page === currentPage ? "page-item active" : "page-item";
                return (
                  <li key={page} className={className}>
                    <button className="page-link" onClick={handlePageNumber} value={page}>
                      {page}
                    </button>
                  </li>
                );
              })}
              <li className={`page-item ${nextDisabled ? "disabled" : ""}`}>
                <button className="page-link" onClick={handleNextButtonClick} disabled={nextDisabled} aria-disabled={nextDisabled} aria-label="next page">
                  <span aria-hidden="true">
                    <i className="ti ti-arrow-narrow-right"></i>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </>
    );
  };

  const sxLink = { fontSize: "20px", color: "#3d4cd2" };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      style: { flex: "1" },
      cell: (row) => (
        <>
          <div className="d-flex justify-content-between w-100">
            <div className="manage-groups-list-box-L" onClick={() => small && row?.membership_status && dispatch(open({ event: "card", data: row }))}>
              <h4 style={{ cursor: small ? "pointer" : "initial" }}>{row?.fullname + " " + (row?.membership_status ? "" : "(Inactive)")}</h4>
              <div className="manage-groups-info">
                <span>
                  <b>Groups: </b>
                  {row?.grp_count}
                </span>
              </div>
            </div>
            {/* {small && (
              <div className="all-group-L-list-R d-flex">
                <Link to="#" style={sxLink}><i className="ti ti-pencil"></i></Link>
                <Link to="#" style={sxLink}><i className="ti ti-trash"></i></Link>
              </div>
            )} */}
          </div>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
    setPage(1);
  };

  const content = small ? list : data;

  const paginate = small ? false : true;
  return (
    <>
      <div className={`wraper-inner bg-grey ${small ? "" : "space-top"}`}>
        <section className={`manage-groups pb-5 ${small ? "small" : "pt-4 min-box"}`}>
          <div className={`${small ? "" : "container"}`}>
            <div className={`similar-shadow-box pb-5 m-auto ${small ? "p-4" : "col-md-7"}`}>
              <h3 className="inner-title mb-4">Members</h3>
              {!small && (
                <div className="search-box mt-4 mb-4">
                  <input type="text" name="" className="form-control" placeholder="Search here.." onChange={handleSearch} />
                  <button className="serch-btn">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
              )}
              <ul className="manage-groups-list">
                <DataTable data={content} columns={columns} noHeader pagination={paginate} paginationServer onChangePage={handlePageChange} paginationComponent={CxPagination} />
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ListMember;