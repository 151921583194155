import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GoogleCalenderEvent from "../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';

function SyncCalender() {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add to calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className='syncButton' style={{textAlign:"center"}}>
        <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
        <GoogleCalenderEvent></GoogleCalenderEvent>
        </GoogleOAuthProvider>
        </div>
        <br></br>
        

        </Modal.Body>

      </Modal>
    </>
  );
}

export default SyncCalender;