import React, { useEffect } from "react";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import logoform from "../../assets/images/logo-form.png";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import * as Yup from "yup";
import { MESSAGE } from "../../utils/ValidationMessage";
import { Formik } from "formik";
import { EMAIL_REGEX } from "../../utils/Constants";

const Verified = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // useEffect(() => {
  //   if (!state) {
  //     navigate("/login", { replace: true });
  //   }
  // }, []);

  const initialValues = { email: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string().strict(true).matches(EMAIL_REGEX, MESSAGE.EMAIL).max(255).required(MESSAGE.REQUIRED),
  });

  const onSubmit = (values) => {
    AuthService.resendlink(values)
      .then(() => {
        Swal.fire({ text: "Email verification link sent again", icon: "info", timer: 3000 });
      })
      .catch((error) => {
        const { message } = error?.response?.data;
        Swal.fire({ text: message, icon: "error", timer: 3000 });
      });
  };

  return (
    <>
      <div className="wraper-inner bg-box space-top">
        <section className="reset-password d-flex justify-content-center align-items-center">
          <span className="shape-1">
            <img src={shape1} alt="logo" />
          </span>
          <span className="shape-2">
            <img src={shape2} alt="logo" />
          </span>
          <div className="container">
            <div className="row">
              <div className="col-md-4 m-auto">
                <div className="form-head text-center">
                  <figure>
                    <img src={logoform} alt="logo" />
                  </figure>
                  <h5 className="form-title">Email Verification Link Sent </h5>
                  <p className="pb-2">Link expires in 24 hours</p>
                  <p>Did not receive email?</p>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ values, errors, touched, submitCount, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mt-4">
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input
                              className={`form-control ${submitCount > 0 ? (errors?.email && touched?.email ? "error-input" : "") : ""}`}
                              placeholder="Email Address"
                              type="text"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.email}
                            />
                            <p className="text-danger">{errors?.email && touched?.email}</p>
                            <i className="ti ti-mail"></i>
                          </label>
                        </div>
                        <button className="btn full-btn hvr-sweep-to-right">Resend Link</button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Email Verification */}
      <div className="modal fade common-modal" id="email-verification" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center mb-3">
              <figure>
                <img src={logoform} alt="logo" />
              </figure>
              <h5 className="modal-title" id="exampleModalLabel" name="verifyemail">
                Email Verification Link <br />
                Sent
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center content-modal">
                <p className="pb-2">Link expires in 24 hours</p>
                <p>Did not receive email?</p>
              </div>
              <div className="mt-4">
                <button className="btn full-btn hvr-sweep-to-right">Resend Link</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verified;
