import { useState, useEffect, Fragment } from "react";
import { Formik, FieldArray, ErrorMessage, Field } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import SchoolAdmin from "../../../services/admin.service";
import { DECIMALPATTERN, MOBILE_REGEX, NOSPACE_REGEX } from "../../../utils/Constants.js";
import { MESSAGE } from "../../../utils/ValidationMessage";
import Modal from "react-bootstrap/Modal";
import { Days, Month } from "../../../utils/Options";
import { complexEmailRegex } from "../../../utils/EmailCheck";
import { Delete, Error, Info, Note, Success } from "../../../utils/Alert";
import { useSelector , useDispatch} from "react-redux";
import Tags from "../../../components/misc/Tags";
import CardW from "../../../components/wrapper/CardW";
import { registrationDetails } from "../../../utils/RegistrationDetails";
import { handleSort } from "../../../utils/Sort";
import { CustomInputField } from "../../../components/misc/CustomInputField";
import { close } from "../../../features/eventSlice";
import { handleBlurTrim } from "../../../utils/FileSize.js";

const Add = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { adminInfo } = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [selectegGroup, setSelectedGroup] = useState([]);

  const formatArr = (arr) => {
    let newArr = arr
      ?.filter((group) => group?.instituteid === adminInfo?.instituteid)
      ?.map((element) => (element?.group_id === state?.groupid ? { ...element, isChecked: true } : { ...element, isChecked: false }));
    setData(newArr);
    setSelectedGroup(newArr);
  };

  useEffect(() => {

    dispatch(close("bulk"));
    formatArr(state?.groupData);
  }, []);

  const handleClose = () => {
    setShow(false);
    if (selectegGroup?.length) {
      setData(selectegGroup);
    } else {
      let arr = data?.map((element) => ({ ...element, isChecked: false }));
      setData(arr);
    }
  };

  const handleShow = (e, values) => {
    e?.preventDefault();
    if (values?.membership_status === "true") {
      if (data?.length) {
        setShow(true);
      } else {
        Swal.fire({ text: Note.NoGroup, ...Info });
      }
    } else Swal.fire({ title: "Cannot add user to groups, if user is inactive", ...Info });
  };
  
  const role = state ? state?.role : "member";
  let initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phoneno: "",
    notification: true,
    addChild: true,
    role: role,
    membership_status: "true",
    group_id: state?.groupid ? [state?.groupid] : role === "admin" ? [1] : [],
    child: [],
    registration_detail: false,
    renewal_amount: "",
    renewal_period: "",
    renewal_notes: "",
    renewal_month: "",
    renewal_day: "",
    zipcode: "",
    auto_renewal: adminInfo?.auto_renewal,
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().strict(true).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
      .strict(true)
      .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
      .max(255)
      .required(MESSAGE.REQUIRED)
      .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val)),
    group_id: Yup.array().required().min(1, "Must be a member of at least one group"),
    phoneno: Yup.string().matches(MOBILE_REGEX, MESSAGE.PHONE).required(MESSAGE.REQUIRED),
    role: Yup.string().required(MESSAGE.REQUIRED),
    child: Yup.array().of(
      Yup.object().shape({
        child_name: Yup.string().strict(true).max(255, "First name should be less than 255 chars").required(MESSAGE.REQUIRED),
        renewal_amount: Yup.number().when("registration_detail", {
          is: true,
          then: Yup.number()
            .required(MESSAGE.REQUIRED)
            .typeError(MESSAGE.RENEWALAMOUNT)
            .positive(MESSAGE.RENEWALAMOUNT)
            .test("is-decimal", MESSAGE.RENEWALAMOUNT, (val) => {
              if (val !== undefined) {
                return DECIMALPATTERN?.test(val);
              }
              return true;
            })
            .max(999999999, MESSAGE.RENEWALAMOUNT),
        }),
        renewal_period: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
        renewal_notes: Yup.string().when("registration_detail", { is: true, then: Yup.string().matches(NOSPACE_REGEX, MESSAGE.RENEWALNOTES).required(MESSAGE.REQUIRED) }),
        renewal_month: Yup.string().when(["registration_detail", "renewal_period"], {
          is: (registration_detail, renewal_period) => registration_detail && renewal_period === "yearly",
          then: Yup.string().matches(/[^0]/, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
        }),
        renewal_day: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
      })
    ),
    renewal_amount: Yup.number().when("registration_detail", {
      is: true,
      then: Yup.number()
        .typeError(MESSAGE.RENEWALAMOUNT)
        .required(MESSAGE.REQUIRED)
        .positive(MESSAGE.RENEWALAMOUNT)
        .test("is-decimal", MESSAGE.RENEWALAMOUNT, (val) => {
          if (val !== undefined) {
            return DECIMALPATTERN?.test(val);
          }
          return true;
        })
        .max(999999999, MESSAGE.RENEWALAMOUNT),
    }),
    renewal_period: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
    renewal_notes: Yup.string().when("registration_detail", { is: true, then: Yup.string().matches(NOSPACE_REGEX, MESSAGE.RENEWALNOTES).required(MESSAGE.REQUIRED) }),
    renewal_month: Yup.string().when(["registration_detail", "renewal_period"], {
      is: (registration_detail, renewal_period) => registration_detail && renewal_period === "yearly",
      then: Yup.string().matches(/[^0]/, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
    }),
    renewal_day: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
    zipcode: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(/^[0-9]{5}$/, "Invalid zip code"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    const details = registrationDetails(values);
    SchoolAdmin.addMember(details)
      .then((res) => {
        Swal.fire({ text: res?.data?.message, ...Success });
        handleNavigation(values?.role);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
        setSubmitting(false);
      });
  };

  const cxhandleChange = (e) => {
    const { id, checked, name } = e?.target;
    let temp;
    if (name === "allSelect") {
      temp = data?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setData(temp);
    } else {
      temp = data?.map((user) => (user?.group_id == id ? { ...user, isChecked: checked } : user));
      setData(temp);
    }
  };

  const handleNavigation = (role) => {
    if (role === "member") {
      navigate("/manage-members");
    } else {
      navigate(-1);
    }
  };

  const sx = { background: "transparent", border: "none" };

  const viewTitle = (role) => {
    switch (role) {
      case "admin":
        return "Add Admin";
      case "parent":
        return "Add Class Parent";
      case "teacher":
        return "Add Teacher";
      default:
        return "Add Member";
    }
  };
  const handleNumberInput = (event) => {
    const value = event?.target?.value;
    event.target.value = value?.replace(/\D/g, '');
  };

  const handleKeyPress = (event) => {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode < 48 || charCode > 57) {
      event?.preventDefault();
    }
  };


  return (
    <CardW>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, submitCount, setFieldTouched }) => (
          <>
            <h3 className="inner-title mb-4 text-capitalize">{viewTitle(values?.role)}</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <CustomInputField name="firstname" placeholder=" " label="First Name" handleChange={handleChange} />
              </div>
              <div className="mb-4">
                <CustomInputField name="lastname" placeholder=" " label="Last Name" handleChange={handleChange}/>
              </div>
              <div className="mb-4">
                <CustomInputField name="email" placeholder=" " label="Email Address" handleChange={handleChange}/>
              </div>
              <div className="mb-4">
                <CustomInputField name="phoneno" placeholder=" " label="Mobile Number" handleNumberInput={handleNumberInput} handleKeyPress={handleKeyPress}/>
              </div>
              <div className="mb-4">
                <CustomInputField name="zipcode" placeholder=" " label="Zip Code" handleNumberInput={handleNumberInput} handleKeyPress={handleKeyPress}/>
              </div>

              {role !== "admin" && (
                <div className="mb-1 ">
                  <Link to="#" onClick={(e) => handleShow(e, values)} className="select-group d-flex justify-content-between">
                    Select Groups
                    <div className="d-flex align-items-center ">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </Link>
                  <span className="custom-field text-danger">{submitCount !== 0 && errors.group_id}</span>
                  {data?.length > 0 ? (
                    <div className="tags-input-wrapper" style={{ border: "none" }}>
                      {selectegGroup?.map((group, index) => (group?.isChecked ? <Tags text={group?.group_name} key={index} /> : null))}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Membership Status</label>
                <div className="radio-flex-box d-flex">
                  <label className="radio-coustom-design me-4 mb-0">
                    <Field type="radio" name="membership_status" value={"true"} />
                    <span className="checkmark"></span>
                    <p>Active</p>
                  </label>
                  <label className="radio-coustom-design me-4 mb-0">
                    <Field
                      type="radio"
                      name="membership_status"
                      value={"false"}
                      onChange={() => {
                        setFieldValue("membership_status", "false");
                        setFieldValue("group_id", []);
                        formatArr(data);
                      }}
                    />
                    <span className="checkmark"></span>
                    <p>Deactivate</p>
                  </label>
                  <ErrorMessage component="p" className="text-danger" name="membership_status" />
                </div>
              </div>
              <div className="mb-4 d-flex">
                <label className="coustom-checkbox s-check-box mb-0">
                  <input type="checkbox" name="notification" checked={values.notification} onChange={handleChange} />
                  <span className="checkmark new_check"></span>
                  <label className="form-label">Push Email Notifications</label>
                  <ErrorMessage component="p" className="text-danger" name="notification" />
                </label>
              </div>

              {/* addChild */}
              <FieldArray
                name="child"
                render={({ remove, push }) => (
                  <>
                    <div className="add-registration-details">
                      {values?.child?.length > 0 &&
                        values?.child?.map((_, index) => (
                          <Fragment key={index}>
                            <div className="row justify-content-between">
                              <div className="col-10">
                                <h4 className="sub-title mb-3">Add Child</h4>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="delete-post me-2"
                                  onClick={() =>
                                    Swal.fire({ title: "Are you sure you want to delete child details?", ...Delete }).then((result) => {
                                      if (result?.isConfirmed) {
                                        remove(index);
                                      }
                                    })
                                  }
                                >
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </div>
                            <div className="mb-3">
                              <CustomInputField name={`child.${index}.child_name`} placeholder=" " label="Child Name" handleChange={handleChange} />
                            </div>
                            {values?.child[index]?.hide ? (
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="add-child add-btn d-flex align-items-center"
                                  style={sx}
                                  onClick={() => {
                                    setFieldValue(`child.${index}.hide`, false);
                                    setFieldValue(`child.${index}.registration_detail`, true);
                                  }}
                                >
                                  <i className="ti ti-plus"></i> Add Registration Details
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="mb-3">
                                  <CustomInputField name={`child.${index}.renewal_amount`} placeholder=" " label="Renewal Amount" handleChange={handleChange}/>
                                </div>
                                <div className="mb-3">
                                  <label className="form-label">Select Renewal Date</label>
                                  <div className="radio-flex-box d-flex">
                                    <label className="radio-coustom-design me-4 mb-0">
                                      <Field
                                        type="radio"
                                        name={`child.${index}.renewal_period`}
                                        value="monthly"
                                        onChange={(e) => {
                                          setFieldValue(`child.${index}.renewal_period`, e?.target?.value);
                                          setFieldValue(`child.${index}.renewal_month`, 0);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <p>Monthly</p>
                                    </label>
                                    <label className="radio-coustom-design me-4 mb-0">
                                      <Field
                                        type="radio"
                                        name={`child.${index}.renewal_period`}
                                        value="yearly"
                                        onChange={(e) => {
                                          setFieldValue(`child.${index}.renewal_period`, e?.target?.value);
                                          setFieldValue(`child.${index}.renewal_month`, 0);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <p>Yearly</p>
                                    </label>
                                    <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_period`} />
                                  </div>
                                </div>
                                {values?.child[index]?.renewal_period === "yearly" && (
                                  <div className="mb-3">
                                    <label className="custom-field one">
                                      <select
                                        className="form-control selected-text"
                                        name={`child.${index}.renewal_month`}
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.child[index]?.renewal_month}
                                      >
                                        {Month?.map((day, index) => (
                                          <option key={index} value={day?.value}>
                                            {day?.label}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_month`} />
                                    </label>
                                  </div>
                                )}
                                {(values?.child[index]?.renewal_period === "yearly" || values?.child[index]?.renewal_period === "monthly") && (
                                  <div className="mb-3">
                                    <label className="custom-field one">
                                      <select
                                        className="form-control selected-text"
                                        name={`child.${index}.renewal_day`}
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.child[index]?.renewal_day}
                                      >
                                        {Days?.map((day, index) => (
                                          <option key={index} value={day?.value}>
                                            {day?.label}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_day`} />
                                    </label>
                                  </div>
                                )}
                                <div className="monthly-plan">
                                  <div className="mb-3 textarea-box">
                                    <label className="custom-field one without-icon">
                                      <Field
                                       onBlur={handleBlurTrim(handleChange)}
                                        as="textarea"
                                        name={`child.${index}.renewal_notes`}
                                        rows="4"
                                        cols="100"
                                        placeholder="Add Notes to Clarify Renewal Reason (notes entered here will automatically be added as description on renewal posts) "
                                      />
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_notes`} />
                                    </label>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    className="add-child add-btn d-flex align-items-center"
                                    style={sx}
                                    onClick={() =>
                                      Swal.fire({ title: "Are you sure you want to delete registration details?", ...Delete }).then((result) => {
                                        if (result?.isConfirmed) {
                                          setFieldValue(`child.${index}.hide`, true);
                                          setFieldValue(`child.${index}.renewal_amount`, "");
                                          setFieldValue(`child.${index}.renewal_period`, "");
                                          setFieldValue(`child.${index}.renewal_day`, "");
                                          setFieldValue(`child.${index}.renewal_month`, "0");
                                          setFieldValue(`child.${index}.renewal_notes`, "");
                                          setFieldValue(`child.${index}.registration_detail`, false);
                                          setFieldTouched(`child.${index}.renewal_amount`, false);
                                          setFieldTouched(`child.${index}.renewal_period`, false);
                                          setFieldTouched(`child.${index}.renewal_day`, false);
                                          setFieldTouched(`child.${index}.renewal_month`, false);
                                          setFieldTouched(`child.${index}.renewal_notes`, false);
                                        }
                                      })
                                    }
                                  >
                                    <i className="ti ti-minus"></i> Delete Registration Details
                                  </button>
                                </div>
                              </>
                            )}

                            <hr className="grey-line" />
                          </Fragment>
                        ))}
                      {showReg ? (
                        <>
                          {/* parent reg. detail */}
                          <div className="mb-3">
                            <label className="custom-field one without-icon">
                              <input type="text" placeholder="Renewal Amount" name={`renewal_amount`} onChange={handleChange}  onBlur={handleBlurTrim(handleChange)} />
                              <ErrorMessage component="p" className="text-danger" name={`renewal_amount`} />
                            </label>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Select Renewal Date</label>
                            <div className="radio-flex-box d-flex">
                              <label className="radio-coustom-design me-4 mb-0">
                                <Field
                                  type="radio"
                                  name={`renewal_period`}
                                  value="monthly"
                                  onChange={(e) => {
                                    setFieldValue(`renewal_period`, e?.target?.value);
                                    setFieldValue("renewal_month", 0);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <p>Monthly</p>
                              </label>
                              <label className="radio-coustom-design me-4 mb-0">
                                <Field
                                  type="radio"
                                  name={`renewal_period`}
                                  value="yearly"
                                  onChange={(e) => {
                                    setFieldValue(`renewal_period`, e?.target?.value);
                                    setFieldValue("renewal_month", 0);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <p>Yearly</p>
                              </label>
                              <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_period`} />
                            </div>
                          </div>
                          {values?.renewal_period === "yearly" && (
                            <div className="mb-3">
                              <label className="custom-field one">
                                <select className="form-control selected-text" name={`renewal_month`} type="text" onChange={handleChange} onBlur={handleBlur} value={values?.renewal_month}>
                                  {Month?.map((day, index) => (
                                    <option key={index} value={day?.value}>
                                      {day?.label}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="p" className="text-danger" name={`renewal_month`} />
                              </label>
                            </div>
                          )}
                          {(values?.renewal_period === "yearly" || values?.renewal_period === "monthly") && (
                            <div className="mb-3">
                              <label className="custom-field one">
                                <select className="form-control selected-text" name={`renewal_day`} type="text" onChange={handleChange} onBlur={handleBlur} value={values?.renewal_day}>
                                  {Days?.map((day, index) => (
                                    <option key={index} value={day?.value}>
                                      {day?.label}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_day`} />
                              </label>
                            </div>
                          )}
                          <div className="monthly-plan">
                            <div className="mb-3 textarea-box">
                              <label className="custom-field one without-icon">
                                <textarea
                                  className="label-textarea"
                                  placeholder="Add Notes to Clarify Renewal Reason (notes entered here will automatically be added as description on renewal posts) "
                                  rows="4"
                                  cols="100"
                                  name={`renewal_notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlurTrim(handleChange)}
                                ></textarea>
                                <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_notes`} />
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <button
                              type="button"
                              className="add-child add-btn d-flex align-items-center"
                              style={sx}
                              onClick={() =>
                                Swal.fire({ title: "Are you sure you want to delete registration details?", ...Delete }).then((result) => {
                                  if (result?.isConfirmed) {
                                    setShowReg(false);
                                    setFieldValue("renewal_amount", "");
                                    setFieldValue("renewal_period", "");
                                    setFieldValue("renewal_notes", "");
                                    setFieldValue("renewal_month", "0");
                                    setFieldValue("renewal_day", "");
                                    setFieldValue("registration_detail", false);
                                  }
                                })
                              }
                            >
                              <i className="ti ti-minus"></i> Delete Registration Details
                            </button>
                          </div>
                          {/* parent reg. detail */}
                        </>
                      ) : role !== "admin" && role !== "teacher" ? (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="add-child add-btn d-flex align-items-center"
                            style={sx}
                            onClick={() =>
                              push({ child_name: "", renewal_amount: "", renewal_period: "", renewal_day: "", renewal_month: "0", renewal_notes: "", registration_detail: false, hide: true })
                            }
                          >
                            <i className="ti ti-plus"></i>Add Child
                          </button>
                          {!values?.child?.length && (
                            <button
                              type="button"
                              className="add-child add-btn d-flex align-items-center ms-5"
                              style={sx}
                              onClick={() => {
                                setShowReg(true);
                                setFieldValue("registration_detail", true);
                              }}
                            >
                              <i className="ti ti-plus"></i>Add Registration Details
                            </button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              />

              <div className="mt-5 btn-box d-flex justify-content-center">
                <button type="button" onClick={() => handleNavigation(values?.role)} className="btn min-btn black-btn hvr-sweep-to-right me-2">
                  Cancel
                </button>
                <button type="submit" className="btn min-btn hvr-sweep-to-right ms-2" disabled={isSubmitting}>
                  Add
                </button>
              </div>
              {/* <!-- select-groups --> */}
              <Modal className="common-modal" centered show={show} onHide={handleClose}>
                <div className="modal-head text-center">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select Groups
                  </h5>
                  <button type="button" className="btn-close" onClick={handleClose}>
                    <i className="ti ti-x"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-4">
                    <label className="coustom-checkbox s-check-box ">
                      <input name="allSelect" type="checkbox" checked={!data?.some((user) => user?.isChecked !== true)} onChange={cxhandleChange} />
                      <span className="checkmark"></span>
                      <p className="select-all-blue">Select All</p>
                    </label>
                    <div className="select-group-box">
                      {data?.sort(handleSort)?.map((group, i) => (
                        <label className="coustom-checkbox s-check-box select-group" key={i}>
                          <input type="checkbox" name="group_id" id={group?.group_id} checked={group?.isChecked || false} onChange={cxhandleChange} />
                          <span className="checkmark"></span>
                          <p>{group?.group_name}</p>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn full-btn hvr-sweep-to-right"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setSelectedGroup(data);
                        let arr = data?.filter((x) => x?.isChecked)?.map((x) => x?.group_id);
                        setFieldValue("group_id", arr);
                        setShow(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Modal>
            </form>
          </>
        )}
      </Formik>
    </CardW>
  );
};

export default Add;