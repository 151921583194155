import { useState, useEffect, Fragment } from "react";
import { Formik, FieldArray, ErrorMessage, Field } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import SchoolAdmin from "../../../services/admin.service";
import { BASE, DECIMALPATTERN, MOBILE_REGEX, NOSPACE_REGEX, SUPPORTED_FORMATS } from "../../../utils/Constants.js";
import Modal from "react-bootstrap/Modal";
import userProfile from "../../../assets/images/DefaultUserIcon.png";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { Days, Month } from "../../../utils/Options";
import { Delete, Error, Info, Note, Success } from "../../../utils/Alert";
import { useSelector } from "react-redux";
import Tags from "../../../components/misc/Tags";
import CardW from "../../../components/wrapper/CardW";
import { registrationDetails } from "../../../utils/RegistrationDetails";
import { handleSort } from "../../../utils/Sort";
import { Spinner } from "react-bootstrap";
import { CustomInputField } from "../../../components/misc/CustomInputField";
import { TrimehandleChange } from "../../../utils/FileSize";

const EditMemberProfile = () => {
  const { state } = useLocation();
  // console.log("state", state);
  // const { id, memberid, renewal_dates, childid } = state;
  const { adminInfo } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [groupeddata, setGroupedData] = useState([]);

  const [profileImage, setProfileImage] = useState("");

  const [show, setShow] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [prevGroups, setPrevGroups] = useState([]);

  function getDifference(array1, array2) {
    return array1?.filter((object1) => {
      return !array2?.some((object2) => {
        return object1?.group_id === object2?.group_id;
      });
    });
  }
  // console.log("dtaa------", data);

  const [id, setId] = useState(state?.id)
  const [memberid, setMemberid] = useState(state?.memberid)
  const [childid, setChildid] = useState(state?.childid)
  const [renewal_dates, setRenewal_dates] = useState(state?.renewal_dates)

  useEffect(() => {

    (async () => {
      try {
        let res = await SchoolAdmin.viewMember({ id, memberid, childid });
        const { profilepic } = res?.data?.data;
        let path = profilepic ? `${BASE}/${profilepic}` : "";
        setProfileImage(path);
        setData(res?.data?.data);
        let selectedGroup = res?.data?.data?.group;
        let resGroupData = await SchoolAdmin.viewGroupDropDown({ allInstitutesIds: [adminInfo?.instituteid] });
        let arr = resGroupData?.data?.data?.map((element) => {
          if (selectedGroup.find((group) => group?.group_id === element?.group_id)) {
            return { ...element, isChecked: true };
          } else {
            return { ...element, isChecked: false };
          }
        });
        let difference = getDifference(selectedGroup, resGroupData?.data?.data);
        if (difference.length) {
          difference = difference.map((group) => ({ ...group, isChecked: true, archived: true }));
          setSelectedGroup([...arr, ...difference]);
          setGroupedData([...arr, ...difference]);
          setPrevGroups([...arr, ...difference]);
        } else {
          setSelectedGroup(arr);
          setGroupedData(arr);
          setPrevGroups(arr);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const checkIsDecimal = (val) => {
    if (val !== undefined) {
      return DECIMALPATTERN.test(val);
    }
    return true;
  };

  let initialValues = {
    id: id,
    memberid: data?.memberid,
    profilepic: data?.profilepic ? data?.profilepic : "",
    firstname: data?.firstname ? data?.firstname : "",
    lastname: data?.lastname ? data?.lastname : "",
    email: data?.email ? data?.email : "",
    phoneno: data?.phoneno ? data?.phoneno : "",
    zipcode: data?.zipcode ? data?.zipcode : "",
    notification: data?.notification,
    addChild: true,
    membership_status: data?.membership_status ? data?.membership_status?.toString() : "false",
    role: data?.role ? data?.role : "",
    group_id: data?.role !== "admin" ? selectedGroup?.filter((x) => x?.isChecked)?.map((x) => x?.group_id) : [1],
    child: data?.child ? data?.child : [],
    renewal_amount: data?.renewal_amount ? data?.renewal_amount : "",
    renewal_period: data?.renewal_period ? data?.renewal_period : "",
    renewal_notes: data?.renewal_notes ? data?.renewal_notes : "",
    renewal_month: data?.renewal_month ? data?.renewal_month : "",
    renewal_day: data?.renewal_day ? data?.renewal_day : "",
    registration_detail: data?.registration_detail,
    sys_date: new Date(),
  };

  const validationSchema = Yup.object().shape({
    profilepic: Yup.mixed()
      .test("fileFormat", "Unsupported Format", (value) => {
        if (typeof value === "string") {
          return true;
        } else {
          return !value || (value && SUPPORTED_FORMATS?.includes(value?.type));
        }
      })
      .test("fileSize", "File too large (max size 5MB)", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value.size <= 5000000;
        }
      }),
    firstname: Yup.string().max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
    lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
    // email: Yup.string().email().max(255).required(MESSAGE.REQUIRED),
    group_id: Yup.array().required().min(1, "Must be a member of at least one group"),
    phoneno: Yup.string().required(MESSAGE.REQUIRED).matches(MOBILE_REGEX, MESSAGE.PHONE),
    role: Yup.string().required(MESSAGE.REQUIRED),
    child: Yup.array().of(
      Yup.object().shape({
        childid: Yup.number(),
        child_name: Yup.string().strict(true).max(255, "First name should be less than 255 chars").required(MESSAGE.REQUIRED),
        renewal_amount: Yup.number()
          .nullable()
          .when("registration_detail", {
            is: true,
            then: Yup.number()
              .required(MESSAGE.REQUIRED)
              .typeError(MESSAGE.RENEWALAMOUNT)
              .positive(MESSAGE.RENEWALAMOUNT)
              .test("is-decimal", MESSAGE.RENEWALAMOUNT, (val) => checkIsDecimal(val))
              .max(999999999, MESSAGE.RENEWALAMOUNT),
          }),
        renewal_period: Yup.string()
          .nullable()
          .when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED).nullable() }),
        renewal_notes: Yup.string()
          .nullable()
          .when("registration_detail", { is: true, then: Yup.string().matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).required(MESSAGE.REQUIRED).nullable() }),
        renewal_month: Yup.string()
          .nullable()
          .when(["registration_detail", "renewal_period"], {
            is: (registration_detail, renewal_period) => registration_detail && renewal_period === "yearly",
            then: Yup.string().matches(/[^0]/, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED).nullable(),
          }),
        renewal_day: Yup.string()
          .nullable()
          .when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED).nullable() }),
      })
    ),
    renewal_amount: Yup.number().when("registration_detail", {
      is: true,
      then: Yup.number()
        .required(MESSAGE.REQUIRED)
        .typeError(MESSAGE.RENEWALAMOUNT)
        .positive(MESSAGE.RENEWALAMOUNT)
        .test("is-decimal", MESSAGE.RENEWALAMOUNT, (val) => checkIsDecimal(val))
        .max(999999999, MESSAGE.RENEWALAMOUNT),
    }),
    renewal_period: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
    renewal_notes: Yup.string().when("registration_detail", { is: true, then: Yup.string().matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).required(MESSAGE.REQUIRED) }),
    renewal_month: Yup.string().when(["registration_detail", "renewal_period"], {
      is: (registration_detail, renewal_period) => registration_detail && renewal_period === "yearly",
      then: Yup.string().matches(/[^0]/, MESSAGE.REQUIRED).required(MESSAGE.REQUIRED),
    }),
    renewal_day: Yup.string().when("registration_detail", { is: true, then: Yup.string().required(MESSAGE.REQUIRED) }),
    zipcode: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE).matches(/^[0-9]{5}$/, "Invalid zip code"),
  });
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const details = registrationDetails(values);
    // new Child
    let updated_child = details.child?.map((x) => {
      if (data?.child?.find((group) => group?.childid === x?.childid)) {
        return { ...x, element: "old" };
      } else {
        return { ...x, element: "new" };
      }
    });

    // deleted Child
    let deleted_child = data?.child?.filter((x) => !details?.child?.find((group) => group?.childid === x?.childid))?.map((x) => x?.childid);

    // new group
    const groupSelected = selectedGroup?.filter((x) => x?.isChecked)?.map((x) => x?.group_id);
    let new_group = [];
    new_group = groupSelected?.filter((x) => !data?.group?.find((o) => o?.group_id == x));

    // delete group
    let deleted_group = [];
    deleted_group = data?.group?.filter((x) => !groupSelected?.find((o) => x?.group_id == o))?.map((x) => x?.group_id);

    const { child, child_exists, renewal_date, ...rest } = details;
    // let renewalDate = new Date(renewal_date);
    // let period = values?.renewal_period;
    // let finalDate = new Date(renewalDate);
    // if (child_exists) {
    //   updated_child?.forEach((item) => {
    //     if (item?.childid === childid) {
    //       renewalDate = new Date(item?.renewal_date);
    //       period = item?.renewal_period;
    //       finalDate = calculateFinalDate(renewalDate, renewal_dates, period);
    //       item.renewal_date = finalDate;
    //     } 
    //     // else {
    //     //   data?.child?.forEach((i) => {
    //     //     if (i?.childid === item?.childid) {
    //     //       item.renewal_date = i?.renewal_date;
    //     //     }
    //     //   });
    //     // }
    //   });
    // } else {
    //   finalDate = calculateFinalDate(renewalDate, renewal_dates, period);
    // }

    // function calculateFinalDate(date1, date2, period) {
    //   if (!date2) return date1;
    //   const d1 = new Date(date1);
    //   const d2 = new Date(date2);
    //   const currentDate = new Date();
    //   const [day1, month1, year1] = [d1?.getDate(), d1?.getMonth(), d1?.getFullYear()];
    //   const [day2, month2, year2] = [d2?.getDate(), d2?.getMonth(), d2?.getFullYear()];
    //   const currentDay = currentDate.getDate();
    //   const currentMonth = currentDate.getMonth();
    //   const currentYear = currentDate.getFullYear();

    //   if (period === 'monthly') {
    //     if (data?.payment_status) {
    //       if (day1 >= day2) {
    //         return new Date(year2, month2, day1);
    //       } else {
    //         return new Date(year2, month2 + 1, day1);
    //       }
    //     } else {
    //       if (day1 < currentDay) {
    //         return new Date(currentYear, currentMonth + 1, day1);
    //       } else {
    //         return new Date(currentYear, currentMonth, day1);
    //       }
    //     }
    //   }

    //   if (period === 'yearly') {
    //     if (data?.payment_status) {
    //       if (day1 !== day2) {
    //         if (month1 === month2) {
    //           return day1 < day2 ? new Date(year2 + 1, month2, day1) : new Date(year2, month2, day1);
    //         }
    //         return new Date(month1 <= month2 ? year2 + 1 : year2, month1, day1);
    //       }
    //       return month1 !== month2
    //         ? new Date(month1 < month2 ? year2 + 1 : year2, month1, day2)
    //         : d2;

    //     } else {
    //       if (day1 !== currentDay) {
    //         if (month1 === currentMonth) {
    //           return day1 < currentDay ? new Date(currentYear + 1, currentMonth, day1) : new Date(currentYear, currentMonth, day1);
    //         }
    //         return new Date(month1 < currentMonth ? currentYear + 1 : currentYear, month1, day1);
    //       }
    //       return month1 !== currentMonth
    //         ? new Date(month1 < currentMonth ? currentYear + 1 : currentYear, month1, day1)
    //         : currentDate;
    //     }
    //   }

    //   return d1;
    // }

    let formData = new FormData();
    Object.keys(rest)?.map((item) => {
      formData.append(item, values[item]);
    });
    // if (!child_exists) {
    //   formData.append("renewal_date", JSON.stringify(finalDate));
    // }
    formData.append("renewal_date",renewal_date)
    formData.append("child", JSON.stringify(child));
    formData.append("child_exists", JSON.stringify(child_exists));
    formData.append("updated_child", JSON.stringify(updated_child));
    formData.append("deleted_child", JSON.stringify(deleted_child));
    formData.append("new_group", JSON.stringify(new_group));
    formData.append("deleted_group", JSON.stringify(deleted_group));

    SchoolAdmin.updateMember(formData)
      .then((res) => {
        Swal.fire({ text: res?.data?.message, ...Success });
        handleNavigation();
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
        resetForm();
        setSubmitting(false);
      });
  };

  const handleNavigation = () => {
    if (state?.sendToMembers) {
      navigate("/manage-members");
    } else {
      navigate(-1);
    }
  };

  const sx = { background: "transparent", border: "none" };

  const handleShow = (values) => {
    if (values?.membership_status === "true") {
      setShow(true);
    } else Swal.fire({ title: "Cannot add user to groups, if user is inactive", ...Info });
  };

  const handleClose = () => {
    setShow(false);
    // let arr = data.map((element) => ({ ...element, isChecked: false }));
    setGroupedData(selectedGroup);
  };

  const cxhandleChange = (e) => {
    const { id, checked, name } = e?.target;
    let temp;
    if (name === "allSelect") {
      temp = groupeddata?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setGroupedData(temp);
    } else {
      temp = groupeddata?.map((user) => (user?.group_id == id ? { ...user, isChecked: checked } : user));
      setGroupedData(temp);
    }
  };

  useEffect(() => {
    if (!state?.id) {
      navigate("/home");
    }
  }, [state?.id, navigate]);

  if (!state?.id) {
    return
  }


  return (
    <CardW>
      <h3 className="inner-title mb-4">Edit Profile</h3>
      {loading ? (
        <Spinner animation="border" variant="success" />
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, submitCount }) => (
            <form onSubmit={handleSubmit}>
              <div className="upload-photo">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      name="profilepic"
                      onChange={(e) => {
                        setFieldValue("profilepic", e?.target?.files[0]);
                        setProfileImage(URL.createObjectURL(e?.target?.files[0]));
                      }}
                    />
                    <label htmlFor="imageUpload" className="img-upload-icon">
                      <i className="ti ti-pencil"></i>
                    </label>
                  </div>
                  <div className="text-center profile-head">
                    <figure>
                      <img
                        src={profileImage ? profileImage : userProfile}
                        alt="logo"
                        onError={(e) => {
                          e.target.src = userProfile;
                        }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="mb-4 text-center">
                <ErrorMessage component="p" className="text-danger" name="profilepic" />
              </div>

              <div className="mb-4">
                <CustomInputField name="firstname" placeholder=" " label="First Name" />
              </div>
              <div className="mb-4">
                <CustomInputField name="lastname" placeholder=" " label="Last Name" />
              </div>
              <div className="mb-4">
                <CustomInputField name="email" placeholder=" " label="Email Address" disabled />
              </div>
              <div className="mb-4">
                <CustomInputField name="phoneno" placeholder=" " label="Mobile Number" />
              </div>
              <div className="mb-4">
                <CustomInputField name="zipcode" placeholder=" " label="Zip Code" />
              </div>
              {/* <div className="mb-4">
                <label className="custom-field one">
                  <select className="form-control bg-light border-light text-dark" name="role" type="text" onChange={handleChange} onBlur={handleBlur} value={values.role} disabled>
                    <option value="">Choose a Role:</option>
                    <option value="admin">Admin</option>
                    <option value="teacher">Leader</option>
                    <option value="parent">Parent</option>
                    <option value="member">Member</option>
                  </select>
                  <ErrorMessage component="p" className="text-danger" name="role" />
                </label>
              </div> */}
              {data?.role !== "admin" && (
                <div className="mb-4 ">
                  <div onClick={() => handleShow(values)} className="select-group d-flex justify-content-between">
                    Select Groups
                    <div className="d-flex align-items-center ">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </div>
                  <ErrorMessage component="p" className="text-danger" name="group_id" />
                  <div className="tags-input-wrapper" style={{ border: "none" }}>
                    {selectedGroup?.length > 0
                      ? selectedGroup?.sort(handleSort)?.map((data, index) => (data?.isChecked ? <Tags text={data?.group_name + " " + (data?.archived ? "(archived)" : "")} key={index} /> : null))
                      : null}
                  </div>
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Membership Status</label>
                <div className="radio-flex-box d-flex">
                  <label className="radio-coustom-design me-4 mb-0">
                    <Field type="radio" name="membership_status" value={"true"} />
                    <span className="checkmark"></span>
                    <p>Active</p>
                  </label>
                  <label className="radio-coustom-design me-4 mb-0">
                    <Field
                      type="radio"
                      name="membership_status"
                      value={"false"}
                      onChange={() => {
                        setFieldValue("membership_status", "false");
                        setSelectedGroup(prevGroups);
                        setGroupedData(prevGroups);
                      }}
                    />
                    <span className="checkmark"></span>
                    <p>Deactivate</p>
                  </label>
                  <ErrorMessage component="p" className="text-danger" name="membership_status" />
                </div>
              </div>
              <div className="mb-4 d-flex">
                <label className="coustom-checkbox s-check-box mb-0">
                  <Field type="checkbox" name={`notification`} />
                  <span className="checkmark new_check"></span>
                  <label className="form-label">Push Email Notifications</label>
                  <ErrorMessage component="p" className="text-danger" name="notification" />
                </label>
              </div>
              <div className="add-member-child-box" style={{ display: "none" }}>
                <h4 className="sub-title mb-3">Add Child</h4>

                <div className="mb-3">
                  <input type="text" name="" placeholder="/abc" className="form-control mb-3" />
                  <Link to="#" className="add-registration add-btn d-flex align-items-center">
                    <i className="ti ti-plus"></i> Add Registration Details
                  </Link>
                  <hr className="grey-line" />
                </div>
              </div>

              {/* addChild */}
              <FieldArray
                name="child"
                render={({ remove, push }) => (
                  <>
                    <div className="add-registration-details">
                      {values?.child?.length > 0 &&
                        values?.child?.map((childData, index) => (
                          <Fragment key={index}>

                            <div className="row justify-content-between">
                              {/* {console.log("dataaaaaaaaaa", childData)} */}
                              <div className="col-10">
                                <h4 className="sub-title mb-3">Child</h4>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="delete-post me-2"
                                  onClick={() =>
                                    Swal.fire({ title: "Are you sure you want to delete child details?", ...Delete }).then((result) => {
                                      if (result?.isConfirmed) {
                                        remove(index);
                                      }
                                    })
                                  }
                                >
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mb-3">
                              <CustomInputField name={`child.${index}.child_name`} placeholder=" " label="Child Name" />
                            </div>
                            {!values?.child[index]?.registration_detail ? (
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="add-child add-btn d-flex align-items-center"
                                  style={sx}
                                  onClick={() => {
                                    setFieldValue(`child.${index}.registration_detail`, true);
                                  }}
                                >
                                  <i className="ti ti-plus"></i> Add Registration Details
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="mb-3">
                                  <CustomInputField name={`child.${index}.renewal_amount`} placeholder=" " label="Renewal Amount" />
                                </div>
                                {console.log("childData?.childid",childData?.childid)}
                                {console.log("state?.childid",state?.childid)}
                                {console.log("data?.renewal_period",childData)}
                                <div className="mb-3">
                                  <label className="form-label">Select Renewal Date</label>
                                  <div className="radio-flex-box d-flex">
                                    <label className="radio-coustom-design me-4 mb-0">
                                      <Field
                                        type="radio"
                                        name={`child.${index}.renewal_period`}
                                        value="monthly"
                                        // disabled={childData?.childid && childData?.renewal_period && childData?.childid != state?.childid}
                                        onChange={(e) => {
                                          setFieldValue(`child.${index}.renewal_period`, e?.target?.value);
                                          setFieldValue(`child.${index}.renewal_month`, 0);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <p>Monthly</p>
                                    </label>
                                    <label className="radio-coustom-design me-4 mb-0">
                                      <Field
                                        type="radio"
                                        name={`child.${index}.renewal_period`}
                                        value="yearly"
                                        // disabled={childData?.childid && childData?.renewal_period && childData?.childid != state?.childid}
                                        onChange={(e) => {
                                          setFieldValue(`child.${index}.renewal_period`, e?.target?.value);
                                          setFieldValue(`child.${index}.renewal_month`, 0);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      <p>Yearly</p>
                                    </label>
                                    <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_period`} />
                                  </div>
                                </div>
                                {values?.child[index]?.renewal_period === "yearly" && (
                                  <div className="mb-3">
                                    <label className="custom-field one">
                                      <select
                                        className="form-control selected-text"
                                        name={`child.${index}.renewal_month`}
                                        type="text"
                                        // disabled={childData?.childid && childData?.childid != state?.childid}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.child[index]?.renewal_month}
                                      >
                                        {Month?.map((day, index) => (
                                          <option key={index} value={day?.value}>
                                            {day?.label}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_month`} />
                                    </label>
                                  </div>
                                )}
                                
                                {(values?.child[index]?.renewal_period === "yearly" || values?.child[index]?.renewal_period === "monthly") && (
                                  <div className="mb-3">
                                    <label className="custom-field one">
                                      <select
                                        className="form-control selected-text"
                                        name={`child.${index}.renewal_day`}
                                        type="text"
                                        onChange={handleChange}
                                        // disabled={childData?.childid && childData?.childid != state?.childid}
                                        onBlur={handleBlur}
                                        value={values?.child[index]?.renewal_day}
                                      >
                                        {Days?.map((day, index) => (
                                          <option key={index} value={day?.value}>
                                            {day?.label}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_day`} />
                                    </label>
                                  </div>
                                )}
                                <div className="monthly-plan">
                                  <div className="mb-3 textarea-box">
                                    <label className="custom-field one without-icon">
                                      <Field
                                        as="textarea"
                                        name={`child.${index}.renewal_notes`}
                                        rows="4"
                                        cols="100"
                                        placeholder="Add Notes to Clarify Renewal Reason (notes entered here will automatically be added as description on renewal posts) "
                                      />
                                      <ErrorMessage component="p" className="text-danger" name={`child.${index}.renewal_notes`} />
                                    </label>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    className="add-child add-btn d-flex align-items-center"
                                    style={sx}
                                    onClick={() =>
                                      Swal.fire({ title: "Are you sure you want to delete registration details?", ...Delete }).then((result) => {
                                        if (result.isConfirmed) {
                                          // setFieldValue(`child.${index}.hide`, true);
                                          setFieldValue(`child.${index}.renewal_amount`, "");
                                          setFieldValue(`child.${index}.renewal_period`, "");
                                          setFieldValue(`child.${index}.renewal_day`, "");
                                          setFieldValue(`child.${index}.renewal_month`, "0");
                                          setFieldValue(`child.${index}.renewal_notes`, "");
                                          setFieldValue(`child.${index}.registration_detail`, false);
                                        }
                                      })
                                    }
                                  >
                                    <i className="ti ti-minus"></i> Delete Registration Details
                                  </button>
                                </div>
                              </>
                            )}

                            <hr className="grey-line" />
                          </Fragment>
                        ))}
                      {values?.registration_detail ? (
                        <>
                          {/* parent reg. detail */}
                          <div className="mb-3">
                            <label className="custom-field one without-icon">
                              <input type="text" placeholder="Renewal Amount" name={`renewal_amount`} onChange={handleChange} onBlur={handleBlur} value={values?.renewal_amount} />
                              <ErrorMessage component="p" className="text-danger" name={`renewal_amount`} />
                            </label>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Select Renewal Date</label>
                            <div className="radio-flex-box d-flex">
                              <label className="radio-coustom-design me-4 mb-0">
                                <Field
                                  type="radio"
                                  name={`renewal_period`}
                                  value="monthly"
                                  onChange={(e) => {
                                    setFieldValue(`renewal_period`, e?.target?.value);
                                    setFieldValue("renewal_month", 0);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <p>Monthly</p>
                              </label>
                              <label className="radio-coustom-design me-4 mb-0">
                                <Field
                                  type="radio"
                                  name={`renewal_period`}
                                  value="yearly"
                                  onChange={(e) => {
                                    setFieldValue(`renewal_period`, e?.target?.value);
                                    setFieldValue("renewal_month", 0);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <p>Yearly</p>
                              </label>
                              <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_period`} />
                            </div>
                          </div>
                          {values?.renewal_period === "yearly" && (
                            <div className="mb-3">
                              <label className="custom-field one">
                                <select className="form-control selected-text" name={`renewal_month`} type="text" onChange={handleChange} onBlur={handleBlur} value={values?.renewal_month}>
                                  {Month?.map((day, index) => (
                                    <option key={index} value={day?.value}>
                                      {day?.label}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="p" className="text-danger" name={`renewal_month`} />
                              </label>
                            </div>
                          )}
                          {(values?.renewal_period === "yearly" || values?.renewal_period === "monthly") && (
                            <div className="mb-3">
                              <label className="custom-field one">
                                <select className="form-control selected-text" name={`renewal_day`} type="text" onChange={handleChange} onBlur={handleBlur} value={values?.renewal_day}>
                                  {Days?.map((day, index) => (
                                    <option key={index} value={day?.value}>
                                      {day?.label}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_day`} />
                              </label>
                            </div>
                          )}
                          <div className="monthly-plan">
                            <div className="mb-3 textarea-box">
                              <label className="custom-field one without-icon">
                                <textarea
                                  className="label-textarea"
                                  placeholder="Add Notes to Clarify Renewal Reason (notes entered here will automatically be added as description on renewal posts) "
                                  rows="4"
                                  cols="100"
                                  name={`renewal_notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.renewal_notes ?? ""}
                                ></textarea>
                                <ErrorMessage component="p" className="text-danger custom-field" name={`renewal_notes`} />
                              </label>
                            </div>
                          </div>
                          <div className="mb-3">
                            <button
                              type="button"
                              className="add-child add-btn d-flex align-items-center"
                              style={sx}
                              onClick={() =>
                                Swal.fire({ title: "Are you sure you want to delete registration details?", ...Delete }).then((result) => {
                                  if (result?.isConfirmed) {
                                    setFieldValue("renewal_amount", "");
                                    setFieldValue("renewal_period", "");
                                    setFieldValue("renewal_notes", "");
                                    setFieldValue("renewal_month", "0");
                                    setFieldValue("renewal_day", "");
                                    setFieldValue("registration_detail", false);
                                  }
                                })
                              }
                            >
                              <i className="ti ti-minus"></i> Delete Registration Details
                            </button>
                          </div>
                          {/* parent reg. detail */}
                        </>
                      ) : values?.role !== "admin" && values?.role !== "teacher" ? (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="add-child add-btn d-flex align-items-center"
                            style={sx}
                            onClick={() => push({ child_name: "", renewal_amount: "", renewal_period: "", renewal_day: "", renewal_month: "", renewal_notes: "", registration_detail: false })}
                          >
                            {/*  hide: true, */}
                            <i className="ti ti-plus"></i>Add Child
                          </button>
                          {!values?.child?.length && (
                            <button
                              type="button"
                              className="add-child add-btn d-flex align-items-center ms-5"
                              style={sx}
                              onClick={() => {
                                setFieldValue("registration_detail", true);
                              }}
                            >
                              <i className="ti ti-plus"></i>Add Registration Details
                            </button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              />

              <div className="mt-5 btn-box d-flex justify-content-center">
                <button type="button" onClick={handleNavigation} className="btn min-btn black-btn hvr-sweep-to-right me-2">
                  Cancel
                </button>
                <button type="submit" className="btn min-btn hvr-sweep-to-right ms-2" disabled={isSubmitting}>
                  Update
                </button>
              </div>

              {/* <!-- select-groups --> */}
              <Modal className="common-modal" centered show={show} onHide={handleClose}>
                <div className="modal-head text-center">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select Groups
                  </h5>
                  <button type="button" className="btn-close" onClick={handleClose}>
                    <i className="ti ti-x"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-4">
                    <label className="coustom-checkbox s-check-box ">
                      <input name="allSelect" type="checkbox" checked={!groupeddata.some((user) => user?.isChecked !== true)} onChange={cxhandleChange} />
                      <span className="checkmark"></span>
                      <p className="select-all-blue">Select All</p>
                    </label>
                    <div className="select-group-box">
                      {groupeddata?.sort(handleSort)?.map((group, i) => (
                        <label className="coustom-checkbox s-check-box select-group" key={i}>
                          <input type="checkbox" name="group_id" id={group?.group_id} checked={group?.isChecked || false} onChange={cxhandleChange} />
                          <span className="checkmark"></span>
                          <p>
                            {group?.group_name} {group?.archived ? "(archived)" : null}
                          </p>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn full-btn hvr-sweep-to-right"
                      onClick={() => {
                        setSelectedGroup(groupeddata);
                        let arr = groupeddata?.filter((x) => x?.isChecked)?.map((x) => x?.group_id);
                        setFieldValue("group_id", arr);
                        setShow(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Modal>
            </form>
          )}
        </Formik>
      )}
    </CardW>
  );
};

export default EditMemberProfile;