import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SchoolAdmin from "../../services/admin.service";
import { Delete, Error, Info, Note } from "../../utils/Alert";
import AddClassParent from "./tabs/AddClassParent";

const ManageClass = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    SchoolAdmin.viewMemberByRoles({ role: "parent" })
      .then((res) => setData(res?.data?.members))
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });
  }, [refresh]);

  const handleDelete = (row) => {
    const { userid, instituteid } = row;
    let title = "Are you sure you want to delete the parent?";
    let text = "Deleting the class parent will delete them from the Institute. Past billing information (if any) will remain.";
    Swal.fire({ title, text, ...Delete }).then((result) => {
      if (result?.isConfirmed) {
        let grpid = row?.grps?.map((grp) => grp?.group_id);
        SchoolAdmin.deleteMember({ userid, instituteid, role: "parent", grpid })
          .then(() => {
            setRefresh(!refresh);
            Swal.fire({ text: Note.ParentDeleted, ...Info });
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            if (message) {
              Swal.fire({ text: message, ...Error });
            } else {
              Swal.fire({ text: Note.Err, ...Error });
            }
          });
      }
    });
  };

  return (
    <>
      <div className="tab-pane fade show active" id="tabs-6" role="tabpanel" aria-labelledby="tabbing-6">
        <div className="pb-5">
          <div className="d-flex justify-content-between">
            <h3 className="inner-title mb-4">Manage Class Parents</h3>
            <Link to="/add-members" state={{ role: "parent", datanew :data }} className="blue-link">
              Add New Class Parent
            </Link>
          </div>
          <ul className="manage-admin-list">
            {data?.length ? (
              data?.map((user, index) => (
                <li className="manage-admin-list-box similar-shadow-3 d-flex justify-content-between" key={index}>
                  <div className="manage-admin-list-L">
                    <h4>
                      {user?.firstname} {user?.lastname}
                    </h4>
                    <div className="contact-info d-flex">
                      <Link to="#">
                        <i className="ti ti-mail"></i> {user?.email}
                      </Link>
                      <Link to="#">
                        <i className="ti ti-phone"></i> {user?.phoneno}
                      </Link>
                      {/* <Link to="#"><i className="ti ti-users"></i> {user.grps.length} Groups</Link> */}
                    </div>
                  </div>
                  <div className="manage-admin-list-R d-flex">
                    <Link to="/edit-member-profile" state={{ id: user?.userid, memberid: user?.memberid }} className="edit-post ms-3">
                      <i className="ti ti-pencil"></i>
                    </Link>
                    <Link to="#" className="delete-post ms-2" onClick={() => handleDelete(user)}>
                      <i className="ti ti-trash"></i>
                    </Link>
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center">There are no records to display</div>
            )}
          </ul>
        </div>
      </div>

      {/* <!-- Add New Class Parent--> */}
      <AddClassParent refresh={refresh} setRefresh={setRefresh} />
    </>
  );
};

export default ManageClass;
