import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllPosts from "../../components/post/AllPosts";
import { getAllParticipatedGroups, getAllPosts, setPriorityIds, setSearched } from "../../features/postSlice";
import SchoolAdmin from "../../services/admin.service";

const HomePage = () => {

  const [info, setInfo] = useState({});
  const { adminInfo, allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const { refresh } = useSelector((state) => state?.posts);
  const dispatch = useDispatch();
  const {priorityIds} = useSelector(state => state.posts)

  useEffect(() => {
    return () =>{
      dispatch(setPriorityIds([]))
    }
  }, []);

  useEffect(() => {
    dispatch(setSearched(""));
    const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
    const promise = dispatch(getAllPosts({ search: "", current_page: 1, institutes: allInstitutesIds, adminInstitute, priorityIds }));
    const selectedIDs = profile?.roledata
      .filter((data) => !data?.is_deleted && data?.membership_status)
      .filter((data) => data?.role !== "member")
      .map((data) => data?.instituteid);
    dispatch(getAllParticipatedGroups({ allInstitutesIds: selectedIDs, search: "", current_page: 1, per_page: 999999 }));
    if (adminInfo?.instituteid) {
      SchoolAdmin.countUserData({ instituteid: adminInfo?.instituteid })
        .then((res) => setInfo(res?.data))
        .catch((err) => console.log(err));
    }
    return () => {
      promise.abort();
    };
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <>
      <AllPosts home={true} info={info} instituteid={adminInfo?.instituteid} />
    </>
  );
};

export default HomePage;
