import { Link } from "react-router-dom";

const BreadCrumbs = ({ group_name }) => {
  let content;
  if (group_name) {
    content = (
      <>
        <li className="breadcrumb-item">
          <Link to="/manage-groups">Manage Groups</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          / {group_name}
        </li>
      </>
    );
  } else {
    content = (
      <>
        <li className="breadcrumb-item">
          <Link to="/manage-members">Manage Members</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          / Member Profile
        </li>
      </>
    );
  }
  return (
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/home">Home</Link> /
      </li>
      {content}
    </ol>
  );
};

export default BreadCrumbs;
