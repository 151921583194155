const CardW = ({ children }) => {
  return (
    <div className="wraper-inner bg-grey space-top">
      <section className="create-new-post pt-4 pb-5 min-box">
        <div className="container">
          <div className="similar-shadow-box pb-5">{children}</div>
        </div>
      </section>
    </div>
  );
};

export default CardW;
