import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FinanceForm from "./FinanceForm";
import { parseISO } from "date-fns";

const Edit = () => {
  const { adminInfo } = useSelector((state) => state?.auth);
  const { state } = useLocation();
  const initialValues = {
    instituteid: adminInfo?.instituteid,
    financial_info: state?.financial_info ?? "",
    fname: state?.fname ?? "",
    lname: state?.lname ?? "",
    account_number: state?.account_number ?? "",
    cvv: state?.cvv ?? "",
    exp_date: state?.exp_date ? parseISO(state?.exp_date) : "",
    routing_number: state?.routing_number ?? "",
    account_holder_type: state?.account_holder_type ?? "",
    card_no: state?.card_no ?? "",
    type: "edit",
  };

  return <FinanceForm initialValues={initialValues} type="Edit" />;
};

export default Edit;
