import { useSelector } from "react-redux";
import { getUserInfo, roleCheck } from "../../../utils/RoleCheck";

const PostInteraction = ({ data, children }) => {
  const { profile } = useSelector((state) => state?.auth);
  let willInteract = true;
  if (data?.dmpost) {
    willInteract = true;
  } else if (data?.is_cancelled) {
    let UserInInstituteOfPost = getUserInfo(profile?.roledata, data?.instituteid);
    willInteract = roleCheck(["admin", "teacher"], [UserInInstituteOfPost?.role]);
  } 
  // else {
  //   willInteract = data?.grps?.filter(Boolean).every((group) => !group.archieve);
  // }
  // else {
  //   willInteract = data?.grps?.filter(Boolean).some((group) => !group.archieve);
  // }
  return willInteract ? <div className="all-posts-footer d-flex justify-content-between">{children}</div> : null;
};

export default PostInteraction;
