import React from "react";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import logoform from "../../assets/images/logo-form.png";
import { Link } from "react-router-dom";

const EmailVerify = () => {
  return (
    <div className="wraper-inner bg-box space-top">
      <section className="reset-password d-flex justify-content-center align-items-center">
        <span className="shape-1">
          <img src={shape1} alt="logo" />
        </span>
        <span className="shape-2">
          <img src={shape2} alt="logo" />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-4 m-auto">
              <div className="form-head text-center">
                <figure>
                  <img src={logoform} alt="logo" />
                </figure>
                <h5 className="form-title mb-2">Email address verified successfully.</h5>
                <Link to="/login" className="btn btn-sm hvr-sweep-to-right mt-4">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailVerify;
