import AxiosInterceptor from "../utils/AxiosInterceptor";
import { BASE_API } from "../utils/Constants";

const API_URL = BASE_API;

const Post = {
  postOptions: () => AxiosInterceptor().post(API_URL + "/schoolAdminPost/postOptions", {}),

  addpost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addpost", values),

  updateCalender: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/deleteCloudCalender", values),

  viewAllPost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewAllPost", values),

  viewCalendarPost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewCalendarPost", values),

  syncEvents: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/syncEvents", values),


  likeDislikePost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addLike", values),

  addComment: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addComment", values),

  editComment: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/editComment", values),

  deleteComment: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/deleteComment", values),

  viewAllPostOfGroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewAllPostOfGroup", values),

  viewAllDMReceiver: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/selectAllReceiver", values),

  viewAllDMPost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewAllDMPost", values),

  deletePost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/deletePost", values),

  editPost: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/editPost", values),

  addPostEvent: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addEvents", values),

  editPostEvent: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/editPostEvent", values),

  removeNewTag: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/removeNewTag", values),

  deletePostEvent: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/deletePostEvent", values),
};

export default Post;
