import { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import "./assets/css/style.css";

import "react-datepicker/dist/react-datepicker.css";

import { Provider } from "react-redux";
import { store } from "./app/Store";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Wrapper>
      <Provider store={store}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Provider>
    </Wrapper>
  </BrowserRouter>
);
