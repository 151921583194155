import Pay from "./Pay";
import Renew from "./Renew";
import Buy from "./Buy";
import Rsvp from "./Rsvp";
import RsvpPay from "./RsvpPay";
import RemindMe from "./RemindMe";
import MemberCard from "./MemberCard";
import { useSelector } from "react-redux";
import AddBulk from "../../modalBlocks/AddBulk";

const Events = () => {
  const { rsvp, rsvpAndPay, renew, buy, pay, card, remindMe, bulk } = useSelector((state) => state?.event);
  return (
    <>
      {remindMe && <RemindMe />}
      {rsvp && <Rsvp />}
      {buy && <Buy />}
      {rsvpAndPay && <RsvpPay />}
      {pay && <Pay />}
      {renew && <Renew />}
      {card && <MemberCard />}
      {bulk && <AddBulk />}
    </>
  );
};

export default Events;
