import { Field, Formik } from "formik";
import { Reminder } from "../../utils/Options";
import * as Yup from "yup";
import { MESSAGE } from "../../utils/ValidationMessage";
import Swal from "sweetalert2";
import SchoolAdmin from "../../services/admin.service";
import { useSelector } from "react-redux";
import { setAuth, setToken, setWelcomeScreen,setReminderTime } from "../../features/authSlice";
import { useDispatch } from "react-redux";

const UpdateRemainder = () => {
  const auth = useSelector((state) => state?.auth);

  let initialValues = { time: auth?.reminder_time};
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    time: Yup.string().required(MESSAGE.REQUIRED)
  });


  const onSubmit = async (values, { setSubmitting, resetForm }) => {

  
   

   SchoolAdmin.updateReminderSetting({reminder_time:values?.time})
   .then((res) => {
    dispatch(setReminderTime(values?.time));
     Swal.fire({ text: res?.data?.message, icon: "info" ,timer: 3000});
   })
   .catch((error) => {
     const { message } = error?.response?.data;
     Swal.fire({ text: message, icon: "error" });
   });

  };



  return (
    <>
      <div className="tab-pane fade show active" id="tabs-2" role="tabpanel" aria-labelledby="tabbing-2">
        <div className="pb-5">
          <h3 className="inner-title mb-4">Update Reminder Settings</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Field as="select" name="time" className="form-control text-dark">
                  {Reminder?.map((time, i) => (
                    <option key={i} value={time?.value}>
                      {time?.label}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="mt-5 btn-box ">
                <button className="btn mr-auto big-btn hvr-sweep-to-right " disabled={isSubmitting}>Update</button>
              </div>
            </form>
              )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateRemainder;
