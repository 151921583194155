import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { Spinner } from "react-bootstrap";

const ContactUs = () => {

  const [pageDetail, setPageDetail]= useState(null)

 async function getCMSDetail(){
  const response = await AuthService.getAllCms({slug:"contact_us"});
  setPageDetail(response?.data?.data);
 }

 const handleEmailClick = () => {
  const emailAddress = 'example@example.com'; // Replace with the recipient's email address
  const subject = 'Subject'; // Replace with your desired subject
  const body = 'Message Body'; // Replace with your desired email body

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  window.open(mailtoLink);
};



 useEffect(function(){
  getCMSDetail();
 },[]);

  return (
    <>
        <div className="modal fade common-modal" id="contact-us" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center mb-4">
              <h5 className="modal-title" id="exampleModalLabel">
                {pageDetail?.title}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center content-modal">
              <div dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
                <div className="btn-box mt-4">
                <a className="btn full-btn hvr-sweep-to-right" href="mailto:example@example.com">Send an Email</a>
                  {/* <button className="btn full-btn hvr-sweep-to-right" onClick={handleEmailClick}>Send an Email</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs