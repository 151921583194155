const PostGroups = ({ data }) => {
  let content;
  if (data?.renewal) {
    content = <li>{data?.renewalchild[0] ? data?.renewalchild[0]?.child_name : data?.renewalusers[0]?.fullname}</li>;
  } else if (data?.dmpost) {
    
    if(data?.sender_member){
      let sender = data?.sender_member?.find((x) => x?.userid === data?.sender[0]?.id);
      let status = sender?.is_deleted ? " (deleted)" : "";
      let senderInfo = data?.sender[0]?.fullname + status;
      content = <li>{senderInfo}</li>;
    }else{
    
      let receiver = data?.receiver_member?.find((x) => x?.userid === data?.receiver[0]?.id);
      let status = receiver?.is_deleted ? " (deleted)" : "";
      let receiverInfo = data?.receiver[0]?.fullname + status;
      content = <li>{receiverInfo}</li>;
    }

  } else {
    content = data?.grps?.filter(Boolean).map((group, i) => (
      <li key={i}>
        {group?.group_name} {group?.archieve ? " (archieved)" : ""}
      </li>
    ));
  }
  return (
    <ul className="all-post-cate-list d-flex flex-wrap">
      {content}
      <li>
        <i className="ti ti-building" style={{ color: "#3d4cd2" }}></i> {data?.acronym}
      </li>
    </ul>
  );
};

export default PostGroups;
