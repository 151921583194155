import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

const Verification = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    AuthService.verifyEmail({ email: id })
      .then((response) => {
        const { message } = response?.data;
        Swal.fire({ text: message, icon: "info", timer: 3000 });
        navigate("/emailverified", { replace: true });
      })
      .catch((error) => {
        const { message } = error?.response?.data;
        Swal.fire({ text: message, icon: "error", timer: 3000 });
        navigate("/verified", { replace: true });
      });
  }, []);
  return <></>;
};

export default Verification;
