export const roleCheck = (arr1, arr2) => {
  let access = arr1?.some((element) => {
    return arr2?.includes(element);
  });
  return access;
};

export const userRoles = (roleData) => {
  let arr = [...new Set(roleData?.map((item) => item?.role))];
  return arr;
};

export const getAdminInfo = (roleData) => {
  const info = roleData?.find((data) => data?.role === "admin");
  return info;
};

export const getUserInfo = (roleData, instituteid) => {
  const info = roleData?.find((data) => data?.instituteid === instituteid);
  return info;
};
