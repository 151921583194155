import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import myprofile from "../../../assets/images/DefaultUserIcon.png";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../../features/eventSlice";
import { Entrant } from "../../../utils/Options";
import { ErrorMessage, Field, Formik } from "formik";
import { MESSAGE } from "../../../utils/ValidationMessage";
import * as Yup from "yup";
import { deleteRsvpEvent, editRsvpEvent, rsvpPostEvent } from "../../../features/postSlice";
import Swal from "sweetalert2";
import { Error, Note, Success } from "../../../utils/Alert";
import EntrantWrap from "../../wrapper/EntrantWrap";
import { BASE } from "../../../utils/Constants";
import { PostDescription, PostGroups } from "../postBlocks";
import { addMinutes, isAfter } from "date-fns";
import GoogleCalenderEvent from "../../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import OutlookAuth from "../../modalBlocks/OutlookAuth";
import { downloadICS } from "../../../utils/Ics";

const Rsvp = () => {
  const { data: post, rsvp, error } = useSelector((state) => state?.event);
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(close("rsvp"));


  const [syncShow, setSyncShow] = useState(false);

  const [sync, setSync] = useState(false);
  const [syncToken, setSyncToken] = useState(null);
  const [syncOn, setSyncOn] = useState(null);


  const updateParentState = newState => {
    setSync(true);
    setSyncShow(newState?.popup);
    setSyncToken(newState?.access_token);
    setSyncOn(newState?.sync_on);
  };


  let admin = adminInfo?.instituteid === post?.instituteid;
  let member = profile?.roledata.find((x) => x?.instituteid === post?.instituteid);
  let teacher = member?.role === "teacher";
  let self = profile?.id === post?.sender_id;

  let viewRsvpList;
  if (post?.dmpost) {
    viewRsvpList = admin || self;
  } else viewRsvpList = admin || teacher || self;

  let rsvpdone = post?.eventusers[0] && post?.eventusers?.find((x) => x?.id === profile?.id);
  let rsvpdata = null;
  if (rsvpdone) {
    rsvpdata = post?.postevent?.find((x) => x?.userid === profile?.id);
  }

  const endDate = new Date(post?.end_date);
  const currentDate = new Date();
  const canSubmit = !isAfter(currentDate, endDate);

  const initialValues = {
    event_type: "rsvp",
    instituteid: post?.instituteid,
    postid: post?.postid,
    memberid: member?.memberid,
    response: rsvpdata ? rsvpdata?.response : "",
    adult: rsvpdata ? rsvpdata?.adult : "",
    children: rsvpdata ? rsvpdata?.children : 0,
    sync_id: rsvpdata ? rsvpdata?.sync_id : 0,
  };


  const validationSchema = Yup.object().shape({
    response: Yup.string().required(MESSAGE.REQUIRED),
    adult: Yup.string().when("response", {
      is: (response) => response !== "no",
      then: Yup.string().required(MESSAGE.REQUIRED),
      otherwise: Yup.string(),
    }),
    children: Yup.string().when("response", {
      is: (response) => response !== "no",
      then: Yup.string().required(MESSAGE.REQUIRED),
      otherwise: Yup.string(),
    }),
  });

  const onSubmit = async (values) => {

    values.sync = sync;
    values.access_token = syncToken;
    values.sync_on = syncOn;
    const action = await dispatch(rsvpPostEvent({...values}));


    if (action?.type === rsvpPostEvent?.fulfilled?.type) {
      const { message } = action?.payload;
      Swal.fire({ text: message, ...Success });

      if (syncOn == 'Apple') {
        downloadICS(post);
      }
    }
    else {
      const { message } = action?.payload;
      Swal.fire({ text: message ?? Note.Err, ...Error });
    }
    handleClose();
  };

  const onUpdate = async (values) => {

    values.sync = sync;
    values.access_token = syncToken;
    values.sync_on = syncOn;


    if (values?.response == 'no') {
      values.adult = 0;
      values.children = 0;
    }

    const action = await dispatch(editRsvpEvent({ ...values, id: rsvpdata?.id }));

    if (action?.type === "posts/editRsvpEvent/fulfilled") {
      // const { message } = action?.payload;
      Swal.fire({ text: Note?.RsvpUpdated, ...Success });


      if (syncOn == 'Apple') {
        downloadICS(post);
      }


    } else {
      // const { message } = action?.payload;
      Swal.fire({ text: Note?.Err, ...Error });
    }
    handleClose();
  };

  const onDelete = async () => {

    const action = await dispatch(deleteRsvpEvent({ userid: profile?.id, postEventID: rsvpdata?.id, postid: post?.postid, sync: sync, access_token: syncToken, sync_on: rsvpdata?.sync_on, event_type: 'rsvp', sync_id: rsvpdata?.sync_id }));
    if (action?.type === deleteRsvpEvent?.fulfilled?.type) {
      // const { message } = action?.payload;
      Swal.fire({ text: Note.RsvpDeleted, ...Success });
    } else {
      // const { message } = action?.payload;
      Swal.fire({ text: Note?.Err, ...Error });
    }
    handleClose();

  };

  let content = null;
  if (viewRsvpList) {
    let yes = { adult: 0, children: 0 };
    let maybe = { adult: 0, children: 0 };
    let no = { adult: 0, children: 0 };
    let show_header = false;
    post?.postevent?.forEach((data) => {
      if (data?.response === "yes") {
        yes.adult = yes.adult + data?.adult;
        yes.children = yes.children + data?.children;
        show_header = true;
      } else if (data?.response === "maybe") {
        maybe.adult = maybe.adult + data?.adult;
        maybe.children = maybe.children + data?.children;
        show_header = true;
      } else if (data?.response === "no") {
        no.adult = no.adult + 1;
        no.children = no.children + data?.children;
      }
    });


    const modalRsvpContent = post?.postevent?.filter(Boolean).filter((item) => item?.response != 'no').map((data, index) => {
      const user = post?.eventusers[0] && post?.eventusers?.find((e) => e?.id === data?.userid);
      return (
        <tr key={"modal-rsvp-content-" + index}>
          <td width="300">
            <div className="List-Attendees-user d-flex align-items-center">
              <figure>
                <img 
                    className="avatarimage" 
                    src={user?.profilepic ? BASE + "/" + user?.profilepic : myprofile} 
                    alt="icon" 
                    onError={(e) => {
                      e.target.src = myprofile; 
                    }}  
                />
              </figure>
              <h4>{user?.fullname}</h4>
            </div>
          </td>
          <td>{data?.response == 'no' ? 'NA' : data?.adult}</td>
          <td>{data?.response == 'no' ? 'NA' : data?.children}</td>
        </tr>
      );
    });
    content = (
      <div>
        <div className="all-posts-box similar-shadow-3">
          <h4>
            {post?.title}
            <Link to="#" className="calender-box">
              <i className="ti ti-calendar"></i>
            </Link>
          </h4>
          <PostGroups data={post} />
          <PostDescription data={post} />
        </div>
        <hr className="border-grey w-50 d-flex mx-auto" />
        <div className="row ">
          <div className="col-md-4 text-center">
            <EntrantWrap type="Yes going" adult={yes.adult} children={yes.children} />
          </div>
          <div className="col-md-4 text-center">
            <EntrantWrap type="May Be Going" adult={maybe.adult} children={maybe.children} />
          </div>
          <div className="col-md-4 text-center">
            <EntrantWrap type="Decline" adult={no.adult} />
          </div>
        </div>
        <h4 className="sub-title mb-3 mt-4">List of Attendees</h4>

        {post?.postevent[0] && show_header ? (
          <table width="100%" className="list-attendees">
            <thead>
              <tr>
                <th>Member Name</th>
                <th>Adult(s)</th>
                <th>Child(ren)</th>
              </tr>
            </thead>
            <tbody>{modalRsvpContent}</tbody>
          </table>

        ) : (
          <div className="text-center">No data found</div>
        )}
      </div>
    );
  } else if (rsvpdone) {

    if (canSubmit) {

      content = (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onUpdate}>
          {({ handleSubmit, dirty, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="col">

                <div className="mb-3">
                  <Field as="select" name="response" className="form-control text-dark" onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('response', value);
                    if (value === "no") {
                      setFieldValue('adult', 0);
                    }
                    else {
                      setFieldValue('adult', '')
                    }

                  }}>
                    <option value="yes">Yes</option>
                    <option value="maybe">May Be</option>
                    <option value="no">No</option>
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="response" />
                </div>

                {values?.response !== "no" ? (
                  <>
                    <div className="mb-3">
                      <Field as="select" name="adult" className="form-control text-dark">
                        <option value="">Adult(s)</option>
                        {Entrant.map((e, i) => (
                          <option value={e?.value} key={i}>
                            {e?.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="p" className="text-danger" name="adult" />
                    </div>
                    <div className="mb-3">
                      <Field as="select" name="children" className="form-control text-dark">
                        <option value="">Child(ren)</option>
                        {Entrant.map((e, i) => (
                          <option value={e?.value} key={i}>
                            {e?.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="p" className="text-danger" name="children" />
                    </div>
                  </>
                ) : null}

                <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="sync" onChange={(e) => {
                    if (e.target?.checked) {
                      setFieldValue('sync', true);
                      setSyncShow(true);
                    } else { setFieldValue('sync', false); }
                  }} />
                  <span className="checkmark"></span>
                  <p>Update to external calendar</p>
                </label>

                <div className="d-flex justify-content-center mt-5">
                  <button type="button" className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={onDelete}>
                    Delete RSVP
                  </button>
                  <button type="submit" disabled={!dirty} className="btn min-btn hvr-sweep-to-right">
                    Update
                  </button>
                </div>
              </div>

              <Modal show={syncShow} onHide={() => { setSyncShow(false); setFieldValue('sync', false); }}>
                <Modal.Header closeButton>
                  <Modal.Title>Add to calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <div className="syncButton" style={{ textAlign: "center" }}>
                    <button onClick={() => {
                      setSyncShow(false);
                      setFieldValue('sync', true);
                      setSyncToken("");
                      setSyncOn('Apple');
                    }}><i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendar apps (ICS) </button>
                  </div>

                  <br></br>

                  <div className='syncButton' style={{ textAlign: "center" }}>
                    <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
                      <GoogleCalenderEvent updateParentState={updateParentState}></GoogleCalenderEvent>
                    </GoogleOAuthProvider>
                  </div>
                  <br></br>

                  <OutlookAuth updateParentState={updateParentState} />

                </Modal.Body>

              </Modal>

            </form>

          )}
        </Formik>
      );

    } else {
      content = <p>RSVP is no longer available.</p>;
      setTimeout(() => handleClose(), 3000);
    }


  } else {
    if (canSubmit) {
      content = (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="col">
                <div className="mb-3">
                  <Field as="select" name="response" className="form-control text-dark" onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('response', value);
                    if (value === "no") {
                      setFieldValue('adult', 0);
                    }
                    else {
                      setFieldValue('adult', '')
                    }

                  }}>
                    <option value="">Select Response</option>
                    <option value="yes">Yes</option>
                    <option value="maybe">May Be</option>
                    <option value="no">No</option>
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="response" />
                </div>
                {values?.response !== "no" ? (
                  <>
                    <div className="mb-3">
                      <Field as="select" name="adult" className="form-control text-dark">
                        <option value="">Adult(s)</option>
                        {Entrant.map((e, i) => (
                          <option value={e?.value} key={i}>
                            {e?.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="p" className="text-danger" name="adult" />
                    </div>
                    <div className="mb-3">
                      <Field as="select" name="children" className="form-control text-dark">
                        <option value="">Child(ren)</option>
                        {Entrant.map((e, i) => (
                          <option value={e?.value} key={i}>
                            {e?.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="p" className="text-danger" name="children" />
                    </div>



                  </>
                ) : null}



                <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="sync" onChange={(e) => {
                    if (e.target?.checked) {
                      setFieldValue('sync', true);
                      setSyncShow(true);
                    } else { setFieldValue('sync', false); }
                  }} />
                  <span className="checkmark"></span>
                  <p>Add to external calendar</p>
                </label>



                <button type="submit" className="btn full-btn hvr-sweep-to-right">
                  Submit
                </button>
              </div>

              <Modal show={syncShow} onHide={() => { setSyncShow(false); setFieldValue('sync', false); }}>
                <Modal.Header closeButton>
                  <Modal.Title>Add to calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                  <div className="syncButton" style={{ textAlign: "center" }}>
                    <button onClick={() => {
                      setSyncShow(false);
                      setFieldValue('sync', true);
                      setSyncToken("");
                      setSyncOn('Apple');
                    }}><i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendar apps (ICS) </button>
                  </div>

                  <br></br>

                  <div className='syncButton' style={{ textAlign: "center" }}>
                    <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
                      <GoogleCalenderEvent updateParentState={updateParentState}></GoogleCalenderEvent>
                    </GoogleOAuthProvider>
                  </div>
                  <br></br>

                  <OutlookAuth updateParentState={updateParentState} />

                </Modal.Body>

              </Modal>


            </form>
          )}
        </Formik>
      );

    } else {
      content = <p>RSVP is no longer available.</p>;
      setTimeout(() => handleClose(), 3000);
    }



  }

  return (
    <>
      <Modal size={viewRsvpList ? "xl" : "md"} centered show={rsvp} onHide={handleClose} keyboard={false}>
        <Modal.Header className="p-4 border-0" closeButton>
          <Modal.Title className="ms-auto event-title">RSVP</Modal.Title>
        </Modal.Header>
        <div className="modal-body px-5">{content}</div>
      </Modal>


    </>

  );
};

export default Rsvp;
