import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import banner from "../../assets/images/bg-banner.png";
const PublicNavbar = () => {
  const {pathname} = useLocation();
  return (
    <header className="topHeader" id="fixed-header">
      <div className="container">
        <nav className="navbar navbar-expand-lg ">
          <div className="navbar-inner-box d-flex justify-content-between">
            <div className="nav-L">
              <Link to="/login" className="navbar-brand desktop-view-logo">
                <img src={logo} alt="logo" />
              </Link>
              <Link to="#" className="navbar-brand mob-logo-view " style={{ display: "none" }}>
                <img src={banner} alt="logo" />
              </Link>
            </div>
            <div className="nav-R">
              {pathname !== "/register" && <Link to="/register" className="btn btn-sm hvr-sweep-to-right me-2">
                Register
              </Link>}
              {pathname !== "/login" && pathname !== "/emailverified" && <Link to="/login" className="btn btn-sm hvr-sweep-to-right">
                Sign In
              </Link>}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default PublicNavbar;
