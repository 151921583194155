import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rsvp: false,
  rsvpAndPay: false,
  renew: false,
  buy: false,
  pay: false,
  card: false,
  remindMe: false,
  data: null,
  payment : null,
  bulk : false,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    open: (state, action) => {
      const { event, data, check } = action?.payload;
      state[event] = true;
      state.data = data;
      state.payment = check
    },
    close: (state, action) => {
      state[action?.payload] = false;
    },
  },
});

export const { open, close } = eventSlice?.actions;

export default eventSlice?.reducer;
