import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { MESSAGE } from "../../utils/ValidationMessage";
import SchoolAdmin from "../../services/admin.service";
import { PASSWORDS_REGEX } from "../../utils/Constants";
import { Error, Success } from "../../utils/Alert";

const UpdatePassword = () => {
  let initialValues = { oldpassword: "", newpassword: "", confirmpassword: "" };

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required(MESSAGE.REQUIRED),
    newpassword: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD).notOneOf([Yup.ref("oldpassword"), null], "Cannot reuse old password"),
    confirmpassword: Yup.string().oneOf([Yup.ref("newpassword"), null], "Passwords must match").required(MESSAGE.REQUIRED),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { confirmpassword, ...rest } = values;
      const res = await SchoolAdmin.updatePassword(rest);
      Swal.fire({ text: "Password updated successfully", ...Success });
      resetForm();
    } catch (err) {
      const { message } = err.response.data;
      if (message) Swal.fire({ text: message, ...Error });
      else Swal.fire({ text: "Something went Wrong !!", ...Error });
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="tab-pane fade show active" id="tabs-1" role="tabpanel" aria-labelledby="tabbing-1">
        <div className="pb-5">
          <h3 className="inner-title mb-4">Update Password</h3>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Field type="password" name="oldpassword" placeholder="Old Password" className="form-control" />
                  <ErrorMessage component="p" className="custom-field text-danger" name="oldpassword" />
                </div>
                <div className="mb-3">
                  <Field type="password" name="newpassword" placeholder="New Password" className="form-control" />
                  <ErrorMessage component="p" className="custom-field text-danger" name="newpassword" />
                </div>
                <div className="mb-3">
                  <Field type="password" name="confirmpassword" placeholder="Confirm Password" className="form-control" />
                  <ErrorMessage component="p" className="custom-field text-danger" name="confirmpassword" />
                </div>
                <div className="mt-5 btn-box ">
                  <button type="submit" disabled={isSubmitting} className="btn mr-auto big-btn hvr-sweep-to-right ">
                    Update Password
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
