import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import { MESSAGE } from "../../utils/ValidationMessage";
import AuthService from "../../services/auth.service";
import { PASSWORDS_REGEX } from "../../utils/Constants";
import logoform from "../../assets/images/logo-form.png";
import { Error, Success } from "../../utils/Alert";

const ChangeTempPassword = () => {
  const navigate = useNavigate();

  let initialValues = { newPassword: "", confirmpassword: "", oldPassword: "" };

  const validationSchema = Yup.object().shape({
    // oldPassword: Yup.string().required(MESSAGE.REQUIRED),
    newPassword: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
      .required(MESSAGE.REQUIRED),
  });

  const onSubmit = async (values) => {
    try {
      const response = await AuthService.changePassword(values);
      Swal.fire({ text: `${response?.data?.message}`, ...Success });
      navigate("/onboarding");
    } catch (error) {
      Swal.fire({ text: `${error?.response?.data?.message}`, ...Error });
      // navigate("/home");
    }
  };
  return (
    <>
      <div className="wraper-inner bg-box space-top">
        <section className="reset-password d-flex justify-content-center align-items-center">
          <span className="shape-1">
            <img src={shape1} alt="logo" />
          </span>
          <span className="shape-2">
            <img src={shape2} alt="logo" />
          </span>
          <div className="container">
            <div className="row">
              <div className="col-md-4 m-auto">
                <div className="form-head text-center">
                  <figure>
                    <img src={logoform} alt="logo" />
                  </figure>
                  <h5 className="form-title">Reset Password</h5>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4 pb-2">
                        <label className="custom-field one">
                          <Field name="newPassword" type="password" placeholder="Password" />
                          <i className="ti ti-lock"></i>
                          <span className="text-danger">
                            <ErrorMessage name="newPassword" />
                          </span>
                        </label>
                      </div>
                      <div className="mb-4 v">
                        <label className="custom-field one">
                          <Field name="confirmpassword" type="password" placeholder="Confirm Password" />
                          <i className="ti ti-lock"></i>
                          <span className="text-danger">
                            <ErrorMessage name="confirmpassword" />
                          </span>
                        </label>
                      </div>
                      <div className="mt-5 ">
                        <button type="submit" disabled={isSubmitting} className="btn full-btn hvr-sweep-to-right">
                          Reset
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChangeTempPassword;
