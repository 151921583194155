import axios from "axios";
import AxiosInterceptor from "../utils/AxiosInterceptor";
import { BASE_API } from "../utils/Constants";

const API_URL = BASE_API + "/schoolAdminAccess";
const CMS_API_URL = BASE_API + "/schoolAdminCms";

const AuthService = {
  register: (value) => axios.post(`${API_URL}/addSchoolAdmin`, value),

  login: (credentials) => axios.post(`${API_URL}/login`, credentials),
  fileSize: () => axios.get(`${API_URL}/viewSettings`),


  logout: () => localStorage.removeItem("token"),

  forgotPassword: (value) => axios.post(`${API_URL}/forgotPassword`, value),

  resetPassword: (value) => axios.post(`${API_URL}/resetpassword`, value),

  verifyEmail: (value) => axios.post(`${API_URL}/verifyEmail`, value),

  resendlink: (value) => axios.post(`${API_URL}/resendlink`, value),

  me: () => AxiosInterceptor().post(`/schoolAdminAccess/me`, {}),

  changePassword: (values) => AxiosInterceptor().post(`/schoolAdminAccess/changePassword`, values),

  getAllCms: (value) => axios.post(`${CMS_API_URL}/getAllCms`, value),
  getAllTutorial: (value) => axios.post(`${CMS_API_URL}/getTutorials`, value),
  addContact: (value) => axios.post(`${CMS_API_URL}/addContact`, value),
  getAcronymCms: (value) => axios.post(`${CMS_API_URL}/getAcronymCms`, value),

};

export default AuthService;
