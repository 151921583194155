import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { roleCheck } from "../../../utils/RoleCheck";

const Settings = () => {
  const { pathname } = useLocation();
  const { logintype } = useSelector((state) => state?.auth);
  const sideBar = () => {
    let arr = [
      { item: "Update Password", url: "update-password", icon: "ti ti-lock", style: "nav-item", allowedRoles: ["admin", "teacher", "parent", "member"] },
      { item: "Update Reminder Settings", url: "update-remainder", icon: "ti ti-alarm", style: "nav-item", allowedRoles: ["admin", "teacher", "parent", "member"] },
      { item: "Update Renewal Settings", url: "update-renewal", icon: "ti ti-send", style: "nav-item", allowedRoles: ["admin"] },
      { item: "Manage Admins", url: "manage-admin", icon: "ti ti-user-plus", style: "nav-item", allowedRoles: ["admin"] },
      { item: "Manage Teacher/ \nGroup Leaders", url: "manage-teacher", icon: "ti ti-user-plus", style: "nav-item", allowedRoles: ["admin"] },
      { item: "Manage Class Parents", url: "manage-class-parent", icon: "ti ti-user-plus", style: "nav-item", allowedRoles: ["admin"] },
    ];

    arr = arr?.filter((data) => roleCheck(data?.allowedRoles, logintype));
    return (
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {arr?.map((data, i) => (
            <li className="nav-item" key={i}>
              <Link to={data?.url} className={pathname?.includes(data?.url) ? "nav-link active" : "nav-link"} aria-current="page" style={{ whiteSpace: "pre" }}>
                <i className={data?.icon}></i>
                {data?.item}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="wraper-inner bg-grey  space-top">
      <section className="create-new-post pt-4 pb-5 min-box">
        <div className="container">
          <div className="d-flex vertical-tabs">
            <div className="vertical-tabs-L">
              <div className="vertical-tabs-L-box-head">
                <h3>Settings</h3>
              </div>
              {sideBar()}
            </div>
            <div className=" vertical-tabs-R similar-shadow-box">
              <div className="tab-content" id="myTabContent">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Settings;
