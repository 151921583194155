import * as Yup from "yup";
import { DECIMALPATTERN, NOSPACE_REGEX, SUPPORTED_FORMATS } from "../../utils/Constants";
import { MESSAGE } from "../../utils/ValidationMessage";

const noCommaInArray = (value) => {
  if (value && value?.length > 0) {
    return !value?.some((item) => item?.includes(','));
  }
  return true; // Valid if the array is empty
}

const checkIsDecimal = (val) => {
  if (val !== undefined) {
    return DECIMALPATTERN.test(val);
  }
  return true;
};

export const validationSchema = Yup.object().shape({
  receiver_id: Yup.string().required(MESSAGE.REQUIRED),
  title: Yup.string().strict(true).max(100, MESSAGE.TITLE).required(MESSAGE.REQUIRED),
  description: Yup.string().required(MESSAGE.REQUIRED),
  start_date: Yup.date().when("event_type", { is: (value) => value === "calendar_event", then: Yup.date().required(MESSAGE.REQUIRED) }),
  end_date: Yup.date().when("event_type", { is: (value) => value === "calendar_event", then: Yup.date().min(Yup.ref("start_date"), MESSAGE.DATETIMESELECTED).required(MESSAGE.REQUIRED) }),
  location: Yup.string().when("event_type", { is: (value) => value === "calendar_event", then: Yup.string().required(MESSAGE.REQUIRED).max(250, MESSAGE.LOCATION).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE) }),
  due_date: Yup.date().when(["paid_events", "is_cancelled"], {  
    is: (paidEvents, isCancelled) => paidEvents && !isCancelled, 
    then: Yup.date().max(Yup.ref("start_date"), "Due date cannot be after event date")
  }),
  paid_event_amount: Yup.number().when("paid_events", {
    is: true,
    then: Yup.number()
      .typeError(MESSAGE.PAIDEVENTAMOUNT)
      .required(MESSAGE.REQUIRED)
      .positive(MESSAGE.PAIDEVENTAMOUNT)
      .test("is-decimal", MESSAGE.PAIDEVENTAMOUNT, (val) => checkIsDecimal(val))
      .max(999999999, MESSAGE.PAIDEVENTAMOUNT),
  }),
  price: Yup.number().when("event_type", {
    is: (value) => value === "merchandise_sale",
    then: Yup.number()
      .typeError(MESSAGE.PRICE)
      .required(MESSAGE.REQUIRED)
      .positive(MESSAGE.PRICE)
      .test("is-decimal", MESSAGE.PRICE, (val) => checkIsDecimal(val))
      .max(999999999, MESSAGE.PRICE),
  }),
  sales_tax: Yup.number().when("event_type", {
    is: (value) => value === "merchandise_sale",
    then: Yup.number()
      .typeError(MESSAGE.TAX)
      .positive(MESSAGE.TAX)
      .test("is-decimal", MESSAGE.TAX, (val) => checkIsDecimal(val))
      .max(999999999, MESSAGE.TAX),
  }),
  colours: Yup.array().test('no-comma', 'Colours should not include a comma', noCommaInArray),
  size: Yup.array().test('no-comma', 'Size should not include a comma', noCommaInArray),
  fileValidation: Yup.mixed()
  .test("fileFormat", "Unsupported Format", (value) => {
    if (typeof value === "string") {
      return true;
    } else {
      return !value || (value && SUPPORTED_FORMATS.includes(value?.type));
    }
  })
  .test("fileSize", "File too large (max size 20MB)", (value) => {
    if (typeof value === "string" || typeof value === "undefined") {
      return true;
    } else {
      return value && value?.size <= 20000000;
    }
  }),

  thumbnailValidation: Yup.mixed().when(['fileuploaded[0].mimetype', 'fileuploaded[0].thumbnail'], {
    is: (mimetype, thumbnail) => mimetype && mimetype.startsWith('video/') && !thumbnail,
    then: Yup.mixed()
      .required('Please upload a thumbnail')
      .test("fileSize", "File too large. Max size is 1MB.", (value) => {
        if (typeof value === "string" || typeof value === "undefined") {
          return true;
        } else {
          return value && value.size <= 1000000;
        }
      }),
    otherwise: Yup.mixed().notRequired(),
  }),
  

});
