import React, { useEffect } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import logoform from "../../assets/images/logo-form.png";
import Swal from "sweetalert2";
import * as Yup from "yup";
import AuthService from "../../services/auth.service";
import { MESSAGE } from "../../utils/ValidationMessage";
import { PASSWORDS_REGEX } from "../../utils/Constants";

const ResetPassword = () => {
  const navigate = useNavigate();

  const { token_id } = useParams();

  let initialValues = { password: "", confirmpassword: "", token_id: token_id };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required(MESSAGE.REQUIRED),
  });

  useEffect(() => {
    AuthService.resetPassword({ token_id: token_id, type: "check" }).then(()=>{}).catch((error) =>{
      Swal.fire({ text: `${error?.response?.data?.message}`, icon: "error", timer: 3000 });
      setTimeout(function () {
        localStorage.clear();
        navigate("/login");
      }, 2000);
    })
  }, []);

  const onSubmit = async (values) => {
    try {
      const response = await AuthService.resetPassword({ token_id: token_id, password: values?.password });
      Swal.fire({ text: `${response?.data?.message}`, icon: "success", timer: 3000 });
      setTimeout(function () {
        localStorage.clear();
        navigate("/login");
      }, 2000);
    } catch (error) {
      Swal.fire({ text: `${error?.response?.data?.message}`, icon: "error", timer: 3000 });
      setTimeout(function () {
        localStorage.clear();
        navigate("/login");
      }, 2000);
    }
  };

  return (
    <>
      <div className="wraper-inner bg-box space-top">
        <section className="reset-password d-flex justify-content-center align-items-center">
          <span className="shape-1">
            <img src={shape1} alt="logo" />
          </span>
          <span className="shape-2">
            <img src={shape2} alt="logo" />
          </span>
          <div className="container">
            <div className="row">
              <div className="col-md-4 m-auto">
                <div className="form-head text-center">
                  <figure>
                    <img src={logoform} alt="logo" />
                  </figure>
                  <h5 className="form-title">Reset Password</h5>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4 pb-2">
                        <label className="custom-field one">
                          <Field name="password" type="password" placeholder="Password" />
                          {/* <input type="password" placeholder="Password" name="password" onChange={handleChange} value={values.password} /> */}
                          <i className="ti ti-lock"></i>
                          {/* <i className="ti ti-eye"></i> */}
                          <span className="text-danger">
                            <ErrorMessage name="password" />
                          </span>
                        </label>
                      </div>
                      <div className="mb-4 v">
                        <label className="custom-field one">
                          <Field name="confirmpassword" type="password" placeholder="Confirm Password" />
                          {/* <input type="password" placeholder="Confirm Password" name="confirmpassword" onChange={handleChange} value={values.confirmpassword} /> */}
                          <i className="ti ti-lock"></i>
                          {/* <i className="ti ti-eye"></i> */}
                          <span className="text-danger">
                            <ErrorMessage name="confirmpassword" />
                          </span>
                        </label>
                      </div>
                      <div className="mt-5 ">
                        <button type="submit" className="btn full-btn hvr-sweep-to-right">
                          Reset
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ResetPassword;
