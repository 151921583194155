import { Link } from "react-router-dom";

const Fees = () => (
  <p className="text-center align-items-center justify-content-center pt-3">
    Additional processing fee will be charged for all payment transactions. Details can be found
    <Link to="/termsandcondition" className="blue-link ms-1" target="_blank">
      here.
    </Link>
  </p>
);

export default Fees;
