import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { MULTIPLESPACE, NOSPACE, NOSPACE_REGEX } from "../../../utils/Constants";
import SchoolAdmin from "../../../services/admin.service";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { useSelector } from "react-redux";
import { Error, Note, Success } from "../../../utils/Alert";
import CardW from "../../../components/wrapper/CardW";
import { TrimehandleChange } from "../../../utils/FileSize";

const EditGroup = () => {
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [data, setData] = useState();

  useEffect(() => {
    SchoolAdmin.viewOnegroup({ group_id: state?.group_id })
      .then((res) => setData(res?.data?.data))
      .catch((err) => console.log(err));
  }, []);

  const initialValues = {
    instituteid: auth?.adminInfo?.instituteid,
    group_id: data?.group_id,
    group_name: data?.group_name,
    group_description: data?.group_description,
  };

  const validationSchema = Yup.object().shape({
    group_name: Yup.string().max(255, "Group name should be less than 255 chars").required("Group name is required").matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    // .trim(NOSPACE).test("multiple-space", "Multiple spaces not allowed", (val) => !MULTIPLESPACE.test(val)),
  });

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    Object.keys(values)?.forEach((k) => (typeof values[k] === "string" ? (values[k] = values[k]?.trim()) : values[k]));
    SchoolAdmin.editGroup(values)
      .then((res) => {
        Swal.fire({ text: Note?.GroupUpdated, ...Success });
        navigate("/manage-groups");
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        if (message) {
          Swal.fire({ text: message, ...Error });
          setFieldError("group_name", message);
        } else {
          Swal.fire({ text: Note?.Err, ...Error });
        }
        setSubmitting(false);
      });
  };


  useEffect(() => {
    if (!state) {
      navigate("/home");
    }
  }, [state, navigate]);

  if (!state) {
    return
  }

  return (
    <CardW>
      <h3 className="inner-title mb-4">Edit Group</h3>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting ,setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="custom-field one without-icon">
              <Field 
                  className={`form-control ${touched?.group_name && errors?.group_name ? "error-input" : ""}`} 
                  type="text" 
                  placeholder="Group Name" 
                  name="group_name" 
                  onChange={TrimehandleChange (setFieldValue)('group_name')} 
              />
                <p className="text-danger">{errors?.group_name && touched?.group_name}</p>
              </label>
            </div>
            <div className="mb-4 textarea-box">
              <label className="custom-field one without-icon">
                <textarea placeholder="Description" rows="5" name="group_description" onChange={handleChange} onBlur={handleBlur} value={values?.group_description} />
                {/* <p className="text-danger">{errors.group_description && touched.group_description && errors.group_description}</p> */}
              </label>
            </div>
            <div className="mt-5 btn-box d-flex justify-content-center">
              <button className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={() => navigate("/manage-groups")}>
                Cancel
              </button>
              <button type="submit" className="btn min-btn hvr-sweep-to-right ms-2" disabled={isSubmitting}>
                Edit Group
              </button>
            </div>
          </form>
        )}
      </Formik>
    </CardW>
  );
};

export default EditGroup;
