import { format, formatDistanceToNow, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";

export default function DateFormat(date, post = false) {
  if (post) {
    return date ? format(new Date(date), "MM/dd/yyyy hh:mm aa") : "-";
  }
  return date ? format(new Date(date), "MM-dd-yyyy") : "-";
}

export function TimeAgo(date) {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}

export const dateTimeFormat = "MM-dd-yyyy hh:mm aa";

export const filterPassedTime = (time, currentDate = new Date()) => {
  const selectedDate = new Date(time);
  return currentDate?.getTime() < selectedDate?.getTime();
};

export const renewDateFormat = (date) => {
  const formattedDate = format(parseISO(date), 'MM-dd-yyyy', { locale: enUS });
  return formattedDate;
};
