import React, { useEffect } from 'react';
import MicrosoftLogin from "react-microsoft-login";

import { Domain } from '../../utils/Constants';

function OutlookAuth({ updateParentState }) {

  const authHandler = (err, data) => {
    if(data && data?.accessToken){
      updateParentState({popup:false, access_token: data?.accessToken , sync_on: "outlook" });
    }else{
      updateParentState({popup:false, access_token: "" , sync_on: "undefined" });
    }
    
  };


  return (
    <div style={{textAlign:"center"}}>
      <MicrosoftLogin clientId="77479769-729a-4ae2-88c5-55096e1fb5d4"  redirectUri={Domain}  graphScopes={["user.read","Calendars.ReadWrite"]} authCallback={authHandler} />
    </div>
  );
}

export default OutlookAuth;
