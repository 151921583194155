import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deletePosts } from "../../../features/postSlice";
import { Delete, Info, Note } from "../../../utils/Alert";
import Swal from "sweetalert2";

const PostOps = ({ data, showEdit, showDelete }) => {



  const dispatch = useDispatch();
  const show = showEdit || showDelete;
  const handleDelete = () => {
    Swal.fire({ title: "Are you sure you want to delete the post?", ...Delete }).then((result) => {
      if (result?.isConfirmed) {
        dispatch(deletePosts({ postid: data?.postid }));
        Swal.fire({ text: Note.PostDeleted, ...Info });
      }
    });
  };
  return (
    <div className="dropdown all-post-toggle">
      {show && (
        <button className=" dropdown-toggle all-post" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="ti ti-dots"></i>
        </button>
      )}
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {(showEdit && data?.is_cancelled==false) && (
          <li>
            <Link to={data?.dmpost ? "/editmessage" : "/edit-post"} state={{ selectedPost: data }} className="dropdown-item">
              <i className="ti ti-pencil"></i>Edit Post
            </Link>
          </li>
        )}
        {showDelete && (
          <li>
            <button className="dropdown-item" onClick={() => handleDelete()}>
              <i className="ti ti-trash"></i> Delete Post
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default PostOps;
