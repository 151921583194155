import React from "react";
import { Link } from "react-router-dom";
import paymentImgI from "../../../assets/images/payment-img-1.png";
import paymentImgII from "../../../assets/images/payment-img-2.png";
import paymentImgIII from "../../../assets/images/payment-img-3.png";
import paymentImgIV from "../../../assets/images/payment-img-4.png";

const MemberPayment = () => {
  return (
    <>
      <div className="wraper-inner bg-grey  space-top">
        <section className="create-new-post pt-4 pb-5">
          <div className="container">
            <div className="similar-shadow-box pb-5">
              <h3 className="inner-title mb-4">Payment </h3>
              <form>
                <div className="msge-sucess text-center mb-4 full-box">
                  <p>
                    Total Amount Due: <b className="blue-link"> $100</b>
                  </p>
                </div>

                <h3 className="inner-title mb-4">Payment Method</h3>

                <ul className="payment-list d-flex mb-4">
                  <li>
                    <img alt="img" src={paymentImgI} className="me-3" />
                  </li>
                  <li>
                    <img alt="img" src={paymentImgII} className="me-3" />
                  </li>
                  <li>
                    <img alt="img" src={paymentImgIII} className="me-3" />
                  </li>
                  <li>
                    <img alt="img" src={paymentImgIV} />
                  </li>
                </ul>
                <div className="row">
                  <h3 className="inner-title mb-4">Card Details </h3>
                  <div className="mb-4 col-md-6">
                    <input
                      type="text"
                      name=""
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <input
                      type="text"
                      name=""
                      placeholder="Last  Name"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-12">
                    <input
                      type="text"
                      name=""
                      placeholder="Billing Address"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-4">
                    <input
                      type="text"
                      name=""
                      placeholder="City"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-4">
                    <select className="form-control">
                      <option>State</option>
                    </select>
                  </div>
                  <div className="mb-4 col-md-4">
                    <input
                      type="text"
                      name=""
                      placeholder="Zip Code"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-12">
                    <input
                      type="text"
                      name=""
                      placeholder="Card Number"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <input
                      type="text"
                      name=""
                      placeholder="CVV"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-6">
                    <input
                      type="text"
                      name=""
                      placeholder="Expiration Date MM/YYYY"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-4 col-md-12 d-flex">
                    <label className="coustom-checkbox s-check-box mb-0 ">
                      <input type="checkbox" checked="checked" />
                      <span className="checkmark"></span>
                      <p className="d-flex">
                        Accept{" "}
                        <Link to="#" className="blue-link">
                          {" "}
                          &nbsp;Terms & Conditions &nbsp;
                        </Link>
                        &{" "}
                        <Link to="#" className="blue-link">
                          {" "}
                          &nbsp;Privacy Policy
                        </Link>
                      </p>
                    </label>
                  </div>
                  <div className="mt-4 btn-box">
                    <button className="btn big-btn hvr-sweep-to-right bg-">
                      Pay Now
                    </button>
                  </div>
                  <p className="text-center mt-3 note-text">
                    Please note all payments are final. Please reach out to your
                    Institution for refund or cancellation.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MemberPayment;