import AxiosInterceptor from "../utils/AxiosInterceptor";
import { BASE_API } from "../utils/Constants";

const API_URL = BASE_API;

const Finance = {
  List: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/instituteFinancialInfo", values),
  viewSettings: () => AxiosInterceptor().get(API_URL + "/superAdminInstitute/viewSettings"),
  addCampaign: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addCampaignPost", values),
  report: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewAllCampaignPost", values),
  payment: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/addEvents", values),
  viewBillingSummary: () => AxiosInterceptor().post(API_URL + "/schoolAdminPost/viewBillingSummary"),
  countNumberOfClicks: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminPost/countNumberOfClicks", values)
};

export default Finance;