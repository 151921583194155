import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SchoolAdmin from "../../../services/admin.service";
import Post from "../../../services/post.service";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import DateFormat, { renewDateFormat } from "../../../utils/DateFormat";
import { Delete, Error, Info, Note, Reminder } from "../../../utils/Alert";
import DatePicker from "react-datepicker";
import CardW from "../../../components/wrapper/CardW";
import { addDays } from "date-fns";
import {useDispatch, useSelector } from "react-redux";
import { open } from "../../../features/eventSlice";
import Events from "../../../../src/components/post/postEvents";

const customStyles = {
  rows: {
    style: { minHeight: "72px" },
  },
  headCells: {
    style: {
      overflow: "visible !important",
      whiteSpace: "normal !important",
      textOverflow: "initial",
      background: "#e9e9eb",
      fontSize: "15px",
      fontWeight: "500",
      color: "#000",
      border: "1px solid #e4e4e4",
      width: "132px",
    },
  },
  cells: {
    style: { border: "1px solid #e4e4e4", fontSize: "14px", color: "#000", fontWeight: "400", width: "132px" },
  },
};

const ManageMembers = () => {
  const { adminInfo, profile } = useSelector((state) => state?.auth);

  const navigate = useNavigate();
  const [filterdate, setFilterDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [show, setShow] = useState(false);
  // const [label, setLabel] = useState("Select all");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);

  const handleBulk=()=>{
    let event = 'bulk';
    let data={groupid:undefined};
    let member='member';
    dispatch(open({ event, data, member }));
  }


  const handleDelete = (e, row) => {
    e?.preventDefault();
    console.log("row",row);
    const hasChildren = row?.childCount > 1;  
    const title = hasChildren ? "Are you sure you want to delete the child details?" 
    : row?.Role == "parent" ? "Are you sure you want to delete the parent?" : "Are you sure you want to delete the member?";
    const text = hasChildren 
      ? "Deleting this child will remove them from the Institute. Past billing information (if any) will remain."
      : "Deleting the member will remove them from the Institute. Past billing information (if any) will remain.";
  
    if (hasChildren) {
      Swal.fire({ title: title, text: text, ...Delete }).then((result) => {
        if (result?.isConfirmed) {
          SchoolAdmin.deleteMember({ 
            userid: row.UserID, 
            instituteid: row.instituteid, 
            childid: row.childid, 
            memberid: row.MemberID, 
            role: row.Role, 
            grpid: row.Group_Name?.map((grp) => grp?.group_id),
            childCount: row.childCount 
          })
          .then((res) => {
            Swal.fire({ text: res?.data?.message, ...Info });
            setRefresh(!refresh);
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            Swal.fire({ text: message || Note.Err, ...Error });
          });
        }
      });
    } else {
      Swal.fire({ title: title, text: text, ...Delete }).then((result) => {
        if (result?.isConfirmed) {
          SchoolAdmin.deleteMember({ 
            userid: row.UserID, 
            instituteid: row.instituteid, 
            memberid: row.MemberID, 
            role: row.Role, 
            grpid: row.Group_Name?.map((grp) => grp?.group_id),
            childCount: 0 
          })
          .then((res) => {
            Swal.fire({ text: res?.data?.message, ...Info });
            setRefresh(!refresh);
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            Swal.fire({ text: message || Note.Err, ...Error });
          });
        }
      });
    }
  };

  const transformData = (oldData) => {
    const newData = [];

    let count = 0;
    for (let i = 0; i < oldData?.length; i++) {
      if (oldData[i]?.child[0]) {
        for (let j = 0; j < oldData[i]?.child?.length; j++) {
          count = count + 1;
          newData.push({
            ID: count,
            UserID: oldData[i]?.userid,
            MemberID: oldData[i]?.memberid,
            Member_Name: oldData[i]?.firstname + " " + oldData[i]?.lastname,
            Group_Name: oldData[i]?.grps,
            Child_Name: oldData[i]?.child[j]?.child_name,
            Renewal_Amount: oldData[i]?.child[j]?.renewal_amount?.toFixed(2),
            Renewal_Date: oldData[i]?.child[j]?.renewal_date,
            Payment_Status: oldData[i]?.child[j]?.payment_status ? oldData[i]?.child[j]?.payment_status : "NA",
            Last_Payment: oldData[i]?.child[j]?.last_payment,
            Notes: oldData[i]?.child[j]?.renewal_notes ? oldData[i]?.child[j]?.renewal_notes : "NA",
            Membership_Status: oldData[i]?.membership_status ? "Active" : "Inactive",
            child_exists: true,
            instituteid: oldData[i]?.instituteid,
            childid: oldData[i]?.child[j]?.childid,
            childCount: oldData[i]?.child?.length,
            Reg_details: oldData[i]?.child[j]?.registration_detail,
            isChecked: false,
            Role: oldData[i]?.role,
          });
        }
      } else {
        count = count + 1;
        newData.push({
          ID: count,
          UserID: oldData[i]?.userid,
          MemberID: oldData[i]?.memberid,
          Member_Name: oldData[i]?.firstname + " " + oldData[i]?.lastname,
          Group_Name: oldData[i]?.grps,
          Child_Name: "NA",
          Renewal_Amount: oldData[i]?.renewal_amount?.toFixed(2),
          Renewal_Date: oldData[i]?.renewal_date,
          Payment_Status: oldData[i]?.payment_status ? oldData[i]?.payment_status : "NA",
          Last_Payment: oldData[i]?.last_payment,
          Notes: oldData[i]?.renewal_notes ? oldData[i]?.renewal_notes : "NA",
          Membership_Status: oldData[i]?.membership_status ? "Active" : "Inactive",
          child_exists: false,
          instituteid: oldData[i]?.instituteid,
          childCount: 0,
          Reg_details: oldData[i]?.registration_detail,
          isChecked: false,
          Role: oldData[i]?.role,
        });
      }
    }
    return newData;
  };

  useEffect(() => {
    SchoolAdmin.viewAllMembers()
      .then((res) => {
        const transformedData = transformData(res?.data?.members);
        setData(transformedData);
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const sortingRenewalDate = (rowA, rowB) => {
    const date1 = new Date(rowA?.Renewal_Date);
    const date2 = new Date(rowB?.Renewal_Date);

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA)?.toString()?.toLowerCase();
      const bField = selector(rowB)?.toString()?.toLowerCase();

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const sortingLastPaymentDate = (rowA, rowB) => {
    const date1 = new Date(rowA?.Last_Payment);
    const date2 = new Date(rowB?.Last_Payment);

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  const cxhandleChange = (e) => {
    const { id, checked, name } = e?.target;
    let temp;
    if (name === "allSelect") {
      temp = data?.map((row) => {
        if (!(row?.Membership_Status === "Active" && row?.Reg_details && row?.Group_Name?.find((group) => !group?.archieve))) {
          return { ...row, isChecked: false };
        } else {
          return { ...row, isChecked: checked };
        }
      });
      setData(temp);
      // setLabel(checked ? "Unselect All" : "Select All");
    } else {
      temp = data?.map((row) => (row?.ID == id ? { ...row, isChecked: checked } : row));
      setData(temp);
    }
  };

  const columns = [
    {
      name: "Member Name",
      sortable: true,
      selector: (row) => row?.Member_Name,
      cell: (row) => {
        return (
          <p>
            <input
              type="checkbox"
              name={row?.ID}
              id={row?.ID}
              checked={row?.isChecked || false}
              onChange={(e) => cxhandleChange(e)}
              disabled={!(row?.Membership_Status === "Active" && row?.Reg_details && row?.Group_Name?.find((group) => !group?.archieve))}
            />
            <span className="ms-1">{row?.Member_Name}</span>
          </p>
        );
      },
    },
    {
      name: "Group Name",
      sortable: false,
      selector: (row) => row?.Group_Name,
      cell: (row) => (row?.Group_Name[0] ? `${row?.Group_Name?.map((grp, idx) => grp?.group_name + (grp?.archieve ? " (archived)" : ""))}`?.split(",")?.join(", ") : "NA"),
    },
    { name: "Child Name", sortable: true, selector: (row) => row.Child_Name, cell: (row) => <p>{row?.Child_Name}</p>} ,
    { name: "Renewal Date", sortable: false, sortFunction: sortingRenewalDate, selector: (row) => row?.Renewal_Date, cell: (row) => ((row?.Renewal_Date && row?.Reg_details) ? renewDateFormat(row?.Renewal_Date) : "NA") },
    { name: "Renewal Amount", sortable: false, selector: (row) => row.Renewal_Amount, cell: (row) => ((row?.Renewal_Amount && row?.Reg_details) ? `$${row?.Renewal_Amount}` : "NA") },
    { name: "Last Payment Date", sortable: true, sortFunction: sortingLastPaymentDate, selector: (row) => row?.Last_Payment, cell: (row) => ((row?.Last_Payment && row?.Reg_details) ? DateFormat(row?.Last_Payment) : "NA") },
    { name: "Payment Status", sortable: true, selector: (row) => row.Payment_Status, cell: (row) => (row?.Payment_Status && row?.Reg_details) ? row?.Payment_Status : "NA" },
    { name: "Membership Status", minWidth: 250, sortable: true, selector: (row) => row?.Membership_Status, cell: (row) => row?.Membership_Status },
    { name: "Notes", sortable: true, selector: (row) => row?.Notes, cell: (row) =>( row?.Notes && row?.Reg_details) ? row?.Notes : "NA"},
    {
      name: "Action",
      minWidth: 200,
      cell: (row) => (
        <div className="action-btn d-flex">
          <Link to={"/member-profile"} state={{ id: row?.UserID, memberid: row?.MemberID, renewal_dates: row?.Renewal_Date, childid: row?.child_exists ? row?.childid : '' }} className="edit-post">
            <i className="ti ti-pencil"></i>
          </Link>
          <Link to="#" className="delete-post ms-2" onClick={(e) => handleDelete(e, row)}>
            <i className="ti ti-trash"></i>
          </Link>
        </div>
      ),
    },
  ];

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempMemberName = allData?.filter((item) => {
        let includes = search
          ?.trim()
          ?.replace("/  /+", "")
          ?.split(" ")
          ?.filter((x) => x)
          ?.every((key) => item?.Member_Name?.split(" ")?.some((x) => x?.toLowerCase()?.startsWith(key?.toLowerCase())));
        if (includes) {
          return includes;
        } else return null;
      });
      let tempChildName = allData?.filter((item) => {
        let includes = item?.Child_Name && item?.Child_Name?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempRenewalDate = allData?.filter((item) => {
        let includes = item?.Renewal_Date && item?.Renewal_Date?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempRenewalAmount = allData?.filter((item) => {
        let includes = item?.Renewal_Amount && item?.Renewal_Amount?.toString()?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempPaymentStatus = allData?.filter((item) => {
        let includes = item?.Payment_Status && item?.Payment_Status?.toString()?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempLastPayment = allData?.filter((item) => {
        let includes = item?.Last_Payment && item?.Last_Payment?.toString()?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempMemberStatus = allData?.filter((item) => {
        let includes = item?.Membership_Status && item?.Membership_Status?.toLowerCase()?.startsWith(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempNotes = allData?.filter((item) => {
        let includes = item?.Notes && item?.Notes?.toLowerCase()?.includes(search?.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempMemberName, ...tempChildName, ...tempNotes, ...tempMemberStatus, ...tempRenewalAmount, ...tempRenewalDate, ...tempLastPayment, ...tempPaymentStatus];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else if (selectedDate) {
      let tempRenewalDate = allData?.filter((item) => {
        let includes = item?.Renewal_Date && new Date(item?.Renewal_Date) < addDays(new Date(selectedDate), 1);
        if (includes) {
          return includes;
        } else return null;
      });
      updatedData = [...tempRenewalDate];
    } else {
      updatedData = allData;
    }

    if (search?.length || selectedDate) {
      return updatedData;
    } else {
      return allData;
    }
  };

  const handleRenewal = (event) => {
  
    event?.preventDefault();

  
    if(adminInfo?.stripe_account_id==null || adminInfo?.stripe_account_id==""){

      Swal.fire({ text: Note?.EnterFinancialInfo, ...Info });
      navigate("/finance");
      return false;
    }

    if(adminInfo?.accept_payment){
      const selectedRows = dataToRender()?.filter((row) => row?.isChecked);



      if (selectedRows?.length) {

      
        event.target.disabled = true;        
        Post.addpost({ userid: profile?.id, receivers: selectedRows, renewal: true, ...adminInfo })
          .then(() => {
            Swal.fire({ text: "Renewal Reminder Sent", ...Reminder });
            handleClear();
            setShow(false);
            setRefresh(!refresh);
          })
          .catch((error) => {
            const { message } = error?.response?.data;
            Swal.fire({ text: message, ...Error });
          });
      } else {
        Swal.fire({ text: Note.NoMember, ...Info });
      }
    }else{
      Swal.fire({ text: "Financial transactions are not verified. Renewal Reminder cannot be sent yet.", ...Info });
      navigate("/finance");
    }
    
  };

  const handleClear = () => {
    setFilterDate("");
    setSelectedDate("");
  };

  const everyOne = data?.filter((row) => row?.Membership_Status === "Active" && row?.Reg_details && row?.Group_Name?.find((group) => !group?.archieve));
  const everyOneChecked = everyOne?.length ? everyOne?.every((user) => user?.isChecked === true) : false;
  return (
    <>
      <CardW>
        <h3 className="inner-title mb-4">Manage Members</h3>
        <div className="mt-5 btn-box d-flex justify-content-center">
          <button className="btn  min-btn hvr-sweep-to-right me-2 trans-btn" data-bs-toggle="modal" data-bs-target="#upcoming-renewals" >
            Upcoming Renewals
          </button>
          {/* <button className="btn  min-btn hvr-sweep-to-right ms-2" onClick={() => navigate("/add-members")}>
            Add Member
          </button> */}

           <button className="btn  min-btn hvr-sweep-to-right ms-2"  onClick={handleBulk}>
            Add Member
          </button>

        

      

        </div>
        <div className="search-box mt-4 mb-4">
          <input type="text" className="form-control" placeholder="Search here.." onChange={(e) => setSearch(e?.target?.value)} />
          <button className="serch-btn">
            <i className="ti ti-search"></i>
          </button>
        </div>
        {dataToRender()?.length ? (
          <div className="blue-text fw-bold m-1 p-1">
            <input name="allSelect" type="checkbox" checked={everyOneChecked} onChange={cxhandleChange} />
            <label className="ms-3">{!everyOneChecked ? "Select all" : "Unselect All"}</label>
          </div>
        ) : null}
        <div className="table-box">
          <DataTable customStyles={customStyles} data={dataToRender()} columns={columns} pagination defaultSortFieldId={1} sortFunction={customSort} />
        </div>

        {dataToRender()?.length && (show || dataToRender()?.filter((row) => row?.isChecked)?.length) ? (
          <div className="mt-5 btn-box d-flex justify-content-center">
            <button  className="btn big-btn black-btn hvr-sweep-to-right" onClick={handleRenewal}>
              Send Renewal Reminder
            </button>
          </div>
        ) : null}
        <p className="text-center align-items-center justify-content-center pt-3">
          Additional processing fee will be charged for all payment transactions. Details can be found&nbsp;
          <Link to="/termsandcondition" className="blue-link" target="_blank">
            here.
          </Link>
        </p>
      </CardW>

      {/* <!-- upcoming-renewals --> */}
      <div className="modal fade common-modal" id="upcoming-renewals" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center">
              <h5 className="modal-title" id="exampleModalLabel">
                Upcoming Renewals
              </h5>
              <p className="p-3">{"Pick a date to filter all renewals with renewal dates <= chosen date"}</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="calender-box">
                <DatePicker
                  placeholderText="Start Date"
                  selected={filterdate}
                  className="form-control"
                  dayClassName={() => "calenderday"}
                  onChange={(date) => setFilterDate(date)}
                  // minDate={new Date()}
                  onChangeRaw={(e) => e?.preventDefault()}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex justify-content-center mt-5">
                <button type="button" className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={handleClear}>
                  Clear
                </button>
                <button className="btn min-btn hvr-sweep-to-right" data-bs-dismiss="modal" onClick={() => setSelectedDate(filterdate)}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Events/>
    
    </>
  );
};

export default ManageMembers;