import { Navigate, useRoutes } from "react-router";

import { PublicNavbar, Footer } from "../components/layout";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import Verification from "../pages/auth/Verification";
import Verified from "../pages/auth/Verified";
import EmailVerify from "../pages/auth/EmailVerify";

import Privacy from "../pages/cms/Privacy";
import TermsCondition from "../pages/cms/TermCondition";
import Contact from "../pages/cms/Contact";




export const cmsRoutes = [
  { path: "/termsandcondition", element: <TermsCondition /> },
  { path: "/privacypolicy", element: <Privacy /> },
  { path: "/contact", element: <Contact /> },
];

const Auth = () => {





  let element = useRoutes([
    ...cmsRoutes,
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/reset-password/:token_id", element: <ResetPassword /> },
    { path: "/verification-complete/:id", element: <Verification /> },
    { path: "/verified", element: <Verified /> },
    { path: "/emailverified", element: <EmailVerify /> },
    { path: "/*", element: <Navigate to="/login" replace /> },
  ]);





  return (

    <>
      <PublicNavbar /> 
      {element}
      <Footer />
    </>


  );


};

export default Auth;
