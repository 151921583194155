import React from "react";
import logoForm from "../../assets/images/logo-form.png";

const PasswordLink = () => {
  return (
    <div className="modal fade common-modal" id="password-link-sent" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-head text-center">
            <figure>
              <img src={logoForm} alt="logo" />
            </figure>
            <h5 className="modal-title" id="exampleModalLabel">
              Password Link Sent
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i className="ti ti-x"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="" >
              <button className="btn full-btn hvr-sweep-to-right" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#forget-password">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordLink;
