import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import * as Yup from "yup";
import ForgotPassword from "./ForgotPassword";
import PasswordLink from "./password-link";
import Swal from "sweetalert2";
import { MESSAGE } from "../../utils/ValidationMessage";
import { NOSPACE_REGEX } from "../../utils/Constants";
import { complexEmailRegex } from "../../utils/EmailCheck";
import { useDispatch } from "react-redux";
import { setAuth, setToken, setWelcomeScreen } from "../../features/authSlice";
import { getAdminInfo } from "../../utils/RoleCheck";
import { Error, Info, Success } from "../../utils/Alert";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = { email: "", password: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .strict()
      .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
      .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
      .max(255)
      .required(MESSAGE.REQUIRED),
    password: Yup.string().required(MESSAGE.REQUIRED),
  });

  const postLoginNavigate = (token, path, value) => {
    let user = { token, welcomeScreen: value };
    localStorage.setItem("user", JSON.stringify(user));
    dispatch(setToken(token));
    dispatch(setWelcomeScreen(value));
    navigate(path, { replace: true });
  };

  const onSubmit = async (values, { setFieldError }) => {
    Object.keys(values)?.forEach((k) => (typeof values[k] === "string" ? (values[k] = values[k]?.trim().replace(/  +/g, " ")) : values[k]));
    try {

      const fcm_token=localStorage.getItem('fcm-token') ?? "";
      values.fcm_token=fcm_token;

      const response = await AuthService.login(values);
      const { status, message, data, addedby, token } = response?.data;
      dispatch(setAuth(data));
      if (status) {
        const adminInfo = getAdminInfo(data?.roledata);
        if (adminInfo) {

          if (!adminInfo?.is_deleted && adminInfo?.membership_status) {
            if (addedby === "self") {

              if (data?.welcomeScreen === 2 || data?.welcomeScreen === 3) {
                postLoginNavigate(token, "/onboarding", false);
              }
              else  if (data?.acronym === null) {
                postLoginNavigate(token, "/acronym", false);
              }
              else {
                if (data?.welcomeScreen === 1) {
                  postLoginNavigate(token, "/home", true);
                }
              }
            }else{
              

              if (data?.welcomeScreen === 1) {
                if(data?.acronym === null){
                  postLoginNavigate(token, "/acronym", false);
                }
                else{
                  postLoginNavigate(token, "/home", true);
                }
              }
              if (data?.welcomeScreen === 2) {
                postLoginNavigate(token, "/onboarding", false);
              }
              if (data?.welcomeScreen === 3) {
                postLoginNavigate(token, "/changepassword", false);
              }

            }




            // if (data.acronym) {
            //   if (addedby === "self") {
            
            //     if (data.welcomeScreen === 1) {
            //       postLoginNavigate(token, "/home", true);
            //     }
            //     if (data.welcomeScreen === 2) {
            //       postLoginNavigate(token, "/onboarding", false);
            //     }

            //   } else {
              
            //     if (data.welcomeScreen === 1) {
            //       postLoginNavigate(token, "/home", true);
            //     }
            //     if (data.welcomeScreen === 2) {
            //       postLoginNavigate(token, "/onboarding", false);
            //     }
            //     if (data.welcomeScreen === 3) {
            //       postLoginNavigate(token, "/changepassword", false);
            //     }
            //   }
            // } else {

            //   if (data.welcomeScreen === 1) {
            //     postLoginNavigate(token, "/home", true);
            //   }
            //   else if (data.welcomeScreen === 2) {
            //     postLoginNavigate(token, "/onboarding", false);
            //   }
            //   else{
            //     postLoginNavigate(token, "/acronym", false);
            //   }

              
            // }


          }
          else {

        

            if (data?.welcomeScreen === 1) {
  
              postLoginNavigate(token, "/home", true);
            }
            if (data?.welcomeScreen === 2) {
              postLoginNavigate(token, "/onboarding", false);
            }
            if (data?.welcomeScreen === 3) {
              postLoginNavigate(token, "/changepassword", false);
            }
          }
          
        } else {

          if (data?.welcomeScreen === 1) {
            postLoginNavigate(token, "/home", true);
          }
          if (data?.welcomeScreen === 2) {
            postLoginNavigate(token, "/onboarding", false);
          }
          if (data?.welcomeScreen === 3) {
            postLoginNavigate(token, "/changepassword", false);
          }
        }
        Swal.fire({ text: "Login successful", ...Success });
      } else {
        Swal.fire({ text: message, ...Info });
      }
    } catch (error) {
      const { message, verification, key } = error?.response?.data;
      if (`${verification}` === "false") {
        navigate("/verified", { state: { email: values?.email } });
      }
      Swal.fire({ text: message, ...Error });
      if (key === "membership_status") {
      } else if (key === "email") {
        setFieldError("email", message);
      } else {
        setFieldError("password", message);
      }
    }
  };

  return (
    <>
      <div className="wraper-inner bg-banner space-top">
        <section className="home-sign-in">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-md-7 home-sign-in-L">
                <h1>Your one-stop communication hub</h1>
                  <p> - Foster engaging relationships</p>
                  <p> - Manage sign-ups, memberships, sell merchandise & more</p>
                  <p> - Reach your target audience and grow clientele</p>
                <hr className="white-line" />
                <p>Modernize and streamline with us </p>
              </div>
              <div className="col-md-5 home-sign-in-R ">
                <div className="similar-shadow-box">
                  <div className="form-head">
                    <h2>Sign In</h2>
                  </div>
                  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ values, errors, touched, submitCount, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <Form>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <Field className={submitCount > 0 ? (errors?.email && touched?.email ? "error-input" : "") : ""} placeholder="Email Address" type="text" name="email" />
                            <i className="ti ti-mail"></i>
                            <ErrorMessage component="p" className="custom-field text-danger" name="email" />
                          </label>
                        </div>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <Field className={submitCount > 0 ? (errors?.password && touched?.password ? "error-input" : "") : ""} placeholder="Password" type="password" name="password" />
                            <i className="ti ti-lock"></i>
                            <ErrorMessage component="p" className="custom-field text-danger" name="password" />
                          </label>
                          <Link to="#" className="forget-password text-right blue-link text-end pt-2 justify-content-end" data-bs-toggle="modal" data-bs-target="#forget-password">
                            Forgot Password
                          </Link>
                        </div>
                        <div className="mb-3 ">
                          <button type="submit" className="btn full-btn btn-primary hvr-sweep-to-right" disabled={isSubmitting}>
                            Sign In
                          </button>
                        </div>
                        <span className="bottom-text text-center d-flex justify-content-center  align-items-center mt-4">
                          Are you new here?
                          <Link to="/register" className="blue-link ms-1" disabled={isSubmitting}>
                            Register
                          </Link>
                        </span>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- forget-password --> */}
      <ForgotPassword />

      {/* <!-- password-link-sent --> */}
      <PasswordLink />
    </>
  );
};
export default Login;
