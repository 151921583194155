const EntrantWrap = ({ type, adult, children }) => {
  if (type === "Yes going") {
    return (
      <div className="adult-info purple-bg">
        <h4 className="text-white">Yes Going</h4>
        <div className="adult-info-num d-flex justify-content-between">
          <span className="text-white">
            Adult(s):<b> {adult}</b>
          </span>
          <span className="text-white">
            Child(ren):<b> {children}</b>
          </span>
        </div>
      </div>
    );
  }
  if (type === "May Be Going") {
    return (
      <div className="adult-info grey-bg">
        <h4 className="">May Be Going</h4>
        <div className="adult-info-num d-flex justify-content-between">
          <span>
            Adult(s):<b> {adult}</b>
          </span>
          <span>
            Child(ren):<b> {children}</b>
          </span>
        </div>
      </div>
    );
  }
  if (type === "Decline") {
    return (
      <div className="adult-info decline-bg">
        <h4>Decline</h4>
        <div className="adult-info-num d-flex justify-content-center">
          <span>
            Adult(s):<b> {adult}</b>
          </span>
        </div>
      </div>
    );
  }
};

export default EntrantWrap;
