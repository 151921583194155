import AxiosInterceptor from "../utils/AxiosInterceptor";
import { BASE_API } from "../utils/Constants";

const API_URL = BASE_API;

const SchoolAdmin = {
  welcomeScreen: () => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/welcomeScreenUpdate", {}),

  getAllGroups: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/getAllGroups", values),

  delGroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/delGroup", values),

  createGroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/createGroup", values),

  viewOnegroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/viewOnegroup", values),

  editGroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/editGroup", values),

  addRemoveArchieve: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/addRemoveArchieve", values),

  viewGroupDropDown: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/viewGroupDropDown", values),

  viewAllMembers: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/viewMemberByInstituteid", values),

  viewMember: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/viewMember", values),

  addMember: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/addmember", values),

  verifyBulkMember : (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/verifyBulkMember", values),

  uploadBulkMember : (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/uploadBulkMember", values),

  deleteMember: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/delMember", values),

  updateMember: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/updateMember", values),

  viewInstitute: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/viewProfile",values),

  updateInstituteProfile: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/updateProfile", values),

  setUpAchronym: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/setUpAchronym", values),

  getAllParticipatedGroups: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroup/getAllParticipatedGroups", values),

  getAllMemberOfGroups: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroupMember/getAllMemberInGroup", values),

  getAllMemberOfSelectedGroup: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminGroupMember/getAllMemberInSelectedGroup", values),

  updatePassword: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/updatePassword", values),

  viewMemberByRoles: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminMember/viewMemberByRoles", values),

  countUserData: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/countUserData", values),

  updateReminder: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/updateRenewalSetting", values),

  updateReminderSetting : (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/updateReminderSetting", values),

  viewNotifications: () => AxiosInterceptor().get(API_URL + "/schoolAdminEvent/viewNotifications"),

  markNotificationSeen : (values) => AxiosInterceptor().post(API_URL + "/schoolAdminEvent/markNotificationSeen", values),

  notificationCount: () => AxiosInterceptor().get(API_URL + "/schoolAdminEvent/notificationCount"),
  
  createNotifications : (values) => AxiosInterceptor().post(API_URL + "/schoolAdminEvent/createNotifications", values),

  generatePresignedUrl: (values) => AxiosInterceptor().post(API_URL + "/schoolAdminAccess/generatePresignedUrl", values),

};

export default SchoolAdmin;
