import axios from "axios";
import { BASE_API } from "./Constants";
import Swal from "sweetalert2";
import { Error } from "./Alert";

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return { Authorization: "Bearer " + user.token };
  }
  return {};
};

export default (history = null) => {
  const axiosInstance = axios.create({ baseURL: BASE_API, headers: authHeader() });

  axiosInstance.interceptors.response.use(
    (response) => {
      // console.log("got response");
      return response;
    },
    (error) => {
      console.log(error.response);
      if (error.response.status === 401) {
        // do something
        const { message } = error?.response?.data;
        if (message) {
          Swal.fire({ text: message, ...Error });
        } else {
          Swal.fire({ text: "Logging Out!!", ...Error });
        }
        console.log("NOT FOUND");
        setTimeout(function () {
          localStorage.clear();
          if (history) {
            history.push("/login");
          } else {
            window.location = "/login";
          }
        }, 2000);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
