import { useEffect, useState } from "react";
import userProfile from "../../../assets/images/DefaultUserIcon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SchoolAdmin from "../../../services/admin.service";
import { BASE } from "../../../utils/Constants";
import Swal from "sweetalert2";
import { Error } from "../../../utils/Alert";
import BreadCrumbs from "../../../components/misc/BreadCrumbs";

const MemberProfile = () => {
  const {state} = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState();
  const[id , setId]= useState( state?.id)  
  const[memberid , setMemberid]= useState( state?.memberid)
  const[childid , setChildid]= useState( state?.childid)
  const[renewal_dates , setRenewal_dates]= useState( state?.renewal_dates)

  useEffect(() => {
    SchoolAdmin.viewMember({ id, memberid, renewal_dates, childid })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err?.response?.data);
        const { status, message } = err?.response?.data;
        Swal.fire({ text: message, ...Error });
        if (!status) {
          navigate("/manage-members", { replace: true });
        }
      });
  }, []);

  useEffect(() => {
    if (!state?.id) {
      navigate("/home");
    }
  }, [state?.id, navigate]);
  if (!state?.id) {
    return
  }


  return (
    <>
      <div className="wraper-inner bg-grey space-top">
        <section className="profile pt-4 pb-5 min-box">
          <div className="container">
            <BreadCrumbs />
            <div className="row">
              <div className="col-md-5 m-auto">
                <div className="similar-shadow-box pb-5">
                  <h3 className="inner-title mb-4 text-center">Profile</h3>
                  <form>
                    <div className="text-center profile-head">
                      <figure>
                        <img 
                            src={data?.profilepic ? `${BASE}/${data?.profilepic}` : userProfile}
                            alt="user-profile" 
                            onError={(e) => {
                              e.target.src = userProfile; 
                            }}
                        />
                      </figure>
                      <figcaption>
                        <h4>
                          {data?.firstname} {data?.lastname}
                        </h4>
                        <span className="d-flex align-items-center justify-content-center">
                          <i className="ti ti-users"></i> {data?.group?.length > 0 ? data?.group?.length : "0"} Group(s)
                        </span>
                      </figcaption>
                    </div>
                    <ul className="profile-list mt-4">
                      <li>
                        <i className="ti ti-building"></i>
                        {data?.institutename ? data?.institutename : "NA"}
                      </li>
                      <li>
                        <i className="ti ti-mail"></i>
                        {data?.email ? data?.email : "NA"}
                      </li>
                      <li>
                        <i className="ti ti-phone"></i>
                        {data?.phoneno ? data?.phoneno : "NA"}
                      </li>
                      <li>
                        <i className="ti ti-user"></i>
                        {data?.child?.length > 0 ? `${data?.child?.map((x) => `${x?.child_name}`)}`?.split(",")?.join(", ") : "NA"}
                      </li>
                    </ul>

                    <div className="btn-box mt-5">
                      <Link to={"/edit-member-profile"} state={{ id, memberid,renewal_dates, childid, sendToMembers : true }} className="btn full-btn hvr-sweep-to-right">
                        Edit Profile
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MemberProfile;