import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";
import { MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX } from "../../utils/Constants";
import { MESSAGE } from "../../utils/ValidationMessage";
import { States } from "../../utils/Options";
import { complexEmailRegex } from "../../utils/EmailCheck";
import { Error, Note, Success } from "../../utils/Alert";
import { Spinner } from "react-bootstrap";

const Contact = () => {
  const navigate = useNavigate();

  const [pageDetail, setPageDetail]= useState(null)

  async function getCMSDetail(){
   const response = await AuthService.getAllCms({slug:"contact_us"});
   setPageDetail(response?.data?.data);
  }

  
  useEffect(function(){
    getCMSDetail();
   },[]);


  let initialValues = {
    name: "",
    email: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .strict(true)
      .max(30, "First name should be less than 30 chars")
      .required(MESSAGE.REQUIRED),
      // .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    email: Yup.string()
      .required(MESSAGE.REQUIRED)
      .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
      .max(255),
    description: Yup.string()
      .strict(true)
      .max(2000, "Description should be less than 2000 chars")
      .required(MESSAGE.REQUIRED),
      // .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
  });

  const onSubmit = async (values, { setSubmitting ,resetForm }) => {
  
    try {
      const response = await AuthService.addContact(values);
      resetForm();
      Swal.fire({ text: "Thank you for contacting us. Someone will reach out to you shortly.", ...Success });
    } catch (err) {
      const { message } = err?.response?.data;
      if (message) {
        Swal.fire({ text: message, ...Error });
      } else {
        Swal.fire({ text: Note.Err, ...Error });
      }
      setSubmitting(false);
    }
  };

  const handleTrimChange = (fieldName, setFieldValue) => (event) => {
    let { value } = event.target;
    value = value.replace(/^\s+/, '').replace(/\s\s+/g, ' ');
    setFieldValue(fieldName, value);
  };
  return (
    <>
      <div className="wraper-inner bg-banner non-height space-top">
        <section className="home-registration">
          <div className="container similar-shadow-box">
            <div className="row  justify-content-center">
            <div className="col-md-6 m-auto">
            <div className="similar-shadow-box">
              <div className="form-head">
                <h2>Contact us </h2>
              </div>
              {pageDetail?.title ? 
                <div dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
                :  
                  <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" variant="success" /> </div>
                } 
            </div>
            
              </div>
              
              <div className="col-md-6 m-auto">
                <div className="similar-shadow-box">
                
                  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting ,setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input 
                              type="text" 
                              name="name" 
                              placeholder="Name" 
                              onChange={handleTrimChange("name", setFieldValue)}
                              onBlur={handleBlur} 
                              value={values?.name} 
                            />
                            <i className="ti ti-user"></i>
                            <span className="text-danger">{touched?.name && errors?.name}</span>
                          </label>
                        </div>
                    
                        <div className="mb-4">
                          <label className="custom-field one">
                            <input 
                              type="email" 
                              name="email" 
                              placeholder="Email Address" 
                              onChange={handleChange} 
                              onBlur={handleBlur} 
                              value={values?.email} 
                            />
                            <i className="ti ti-mail"></i>
                            <span className="text-danger">{touched?.email && errors?.email}</span>
                          </label>
                        </div>

                        <div className="mb-4">
                          <label className="custom-field one">
                           <textarea id="w3review" name="description" placeholder="Description" onChange={handleChange} onBlur={handleBlur} value={values?.description}></textarea>
                    
                            <span className="text-danger">{touched?.description && errors?.description}</span>
                          </label>
                        </div>
                      
                        <div className="mb-3 ">
                          <button type="submit" className="btn full-btn hvr-sweep-to-right" disabled={isSubmitting}>
                            Submit
                          </button>
                        </div>
                      
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
