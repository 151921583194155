const BoxW = ({ title, icon, text, info }) =>
  title ? (
    <>
      <i className={icon}></i>
      {text}
    </>
  ) : (
    <li>
      <span>{text}</span>
      <p>{info}</p>
    </li>
  );

export default BoxW;
