import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { CustomInputField } from "../../components/misc/CustomInputField";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { CARD_REGEX, NOSPACE } from "../../utils/Constants";
import { MESSAGE } from "../../utils/ValidationMessage";
import CardW from "../../components/wrapper/CardW";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import Finance from "../../services/finance.service";
import Swal from "sweetalert2";
import { Error, Note, Success } from "../../utils/Alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  fname: Yup.string().strict(true).trim(NOSPACE).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
  lname: Yup.string().strict(true).trim(NOSPACE).max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
  financial_info: Yup.string(),
  account_holder_type: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  routing_number: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string()
      .required(MESSAGE.REQUIRED)
      .matches(/^[0-9]{9}$/, "Invalid routing number"),
    otherwise: Yup.string().strip(),
  }),
  account_number: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  cnfrm_account_number: Yup.string().when(["financial_info", "account_number"], {
    is: (financial_info, account_number) => financial_info === "bank" && account_number,
    then: Yup.string()
      .oneOf([Yup.ref("account_number")], "Account numbers must match")
      .required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  card_no: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().matches(CARD_REGEX, "Invalid card number").required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  cvv: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().min(3, "Invalid cvv").max(4, "Invalid cvv").required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  exp_date: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
});

const FinanceForm = ({ initialValues, type }) => {
  const { adminInfo } = useSelector((state) => state?.auth);
  const [key, setKey] = useState(initialValues?.financial_info ?? "bank");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      Finance.List({ ...values, stripe_customer_id: adminInfo?.stripe_customer_id })
        .then((res) => {
          Swal.fire({ text: res?.data?.message, ...Success });
          navigate(-1);
        })
        .catch((err) => {
          const { message } = err?.response?.data;
          Swal.fire({ text: message ? message : Note?.Err, ...Error });
          setSubmitting(false);
        });
    },
  });
  const fxTab = (k) => {
    setKey(k);
    formik?.resetForm();
    formik?.setFieldValue("financial_info", k);
  };

  const today = new Date();
  const minDate = new Date(today?.getFullYear(), today?.getMonth());

  return (
    <CardW>
      <h3 className="inner-title mb-4">{type} Financial Information</h3>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <Tabs className="similer-tabs" activeKey={key} onSelect={fxTab}>
            {type === "Add" || (type === "Edit" && initialValues.financial_info === "bank") ? (
              <Tab eventKey="bank" title="Bank Account">
                <div>
                  <div className="row">
                    <div className="mb-4 col-md-12">
                      <Field as="select" name="account_holder_type" className="form-control text-dark">
                        <option value="">Account Holder Type</option>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                      </Field>
                      <ErrorMessage component="p" className="text-danger" name="account_holder_type" />
                    </div>
                    <div className="mb-4 col-md-6">
                      <CustomInputField name="fname" placeholder=" " label="Account Holder First Name" />
                    </div>
                    <div className="mb-4 col-md-6">
                      <CustomInputField name="lname" placeholder=" " label="Account Holder Last Name" />
                    </div>
                    <div className="mb-4 col-md-12">
                      <CustomInputField name="routing_number" placeholder=" " label="Routing Number" />
                    </div>
                    <div className="mb-4 col-md-12">
                      <CustomInputField name="account_number" placeholder=" " label="Account Number" />
                    </div>
                    <div className="mb-4 col-md-12">
                      <CustomInputField name="cnfrm_account_number" placeholder=" " label="Confirm Account Number" />
                    </div>
                  </div>
                  <div className="mt-4 btn-box">
                    <button className="btn big-btn hvr-sweep-to-right" type="submit" disabled={formik?.isSubmitting}>
                      Add
                    </button>
                  </div>
                </div>
              </Tab>
            ) : null}

            {type === "Add" || (type === "Edit" && initialValues.financial_info === "card") ? (
              <Tab eventKey="card" title="Debit Card">
                <div>
                  <div className="row">
                    <div className="mb-4 col-md-6">
                      <CustomInputField name="fname" placeholder=" " label="First Name" />
                    </div>
                    <div className="mb-4 col-md-6">
                      <CustomInputField name="lname" placeholder=" " label="Last Name" />
                    </div>
                    <div className="mb-4 col-md-12">
                      <CustomInputField name="card_no" placeholder=" " label="Card Number" />
                    </div>
                    <div className="mb-4 col-md-6">
                      <CustomInputField name="cvv" placeholder=" " label="CVV" />
                    </div>
                    <div className="mb-4 col-md-6">
                      <DatePicker
                        placeholderText="Expiration Date MM/YYYY"
                        selected={formik?.values?.exp_date}
                        dateFormat={"MM/yyyy"}
                        showMonthYearPicker
                        className="form-control"
                        name="exp_date"
                        onChange={(date) => formik.setFieldValue(`exp_date`, date)}
                        minDate={minDate}
                        onChangeRaw={(e) => e.preventDefault()}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="mt-4 btn-box">
                    <button className="btn big-btn hvr-sweep-to-right" type="submit" disabled={formik.isSubmitting}>
                      {type}
                    </button>
                  </div>
                </div>
              </Tab>
            ) : null}
          </Tabs>
        </Form>
      </FormikProvider>
    </CardW>
  );
};

export default FinanceForm;
