import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import myprofile from "../../../assets/images/DefaultUserIcon.png";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../../features/eventSlice";
import { PostDescription, PostGroups } from "../postBlocks";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { Entrant } from "../../../utils/Options";
import { BASE } from "../../../utils/Constants";
import { deleteRsvpEvent, editRsvpEvent, rsvpPostEvent } from "../../../features/postSlice";
import Swal from "sweetalert2";
import { Error, Note, Success } from "../../../utils/Alert";
import { addMinutes, isAfter } from "date-fns";
import { MESSAGE } from "../../../utils/ValidationMessage";
import * as Yup from "yup";
import GoogleCalenderEvent from "../../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import OutlookAuth from "../../modalBlocks/OutlookAuth";




const RsvpPay = () => {

  const { data: post, rsvpAndPay, payment } = useSelector((state) => state?.event);
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(close("rsvpAndPay"));
  let admin = adminInfo?.instituteid === post?.instituteid;
  let member = profile?.roledata.find((x) => x?.instituteid === post?.instituteid);
  let teacher = member?.role === "teacher";

  const [syncShow, setSyncShow] = useState(false);
  const [syncToken, setSyncToken] = useState(null);
  const [syncOn, setSyncOn] = useState(null);


  const updateParentState = newState => {
    setSyncShow(newState.popup);
    setSyncToken(newState.access_token);
    setSyncOn(newState.sync_on);
  };


  let rsvpdone = post?.eventusers[0] && post?.eventusers?.find((x) => x?.id === profile?.id);
  let rsvpdata = null;
  if (rsvpdone) {
    rsvpdata = post?.postevent?.find((x) => x?.userid === profile?.id);
  }



  const onDelete = async () => {
    const action = await dispatch(deleteRsvpEvent({ userid: profile?.id, postEventID: rsvpdata?.id, postid: post?.postid }));
    if (action.type === deleteRsvpEvent?.fulfilled?.type) {
      const { message } = action?.payload;
      Swal.fire({ text: message ?? Note?.RsvpUpdated, ...Success });
    } else {
      const { message } = action?.payload;
      Swal.fire({ text: message ?? Note?.Err, ...Error });
    }
    handleClose();
  };


  let quantity = "";
  if (rsvpdata?.quantity) {
    quantity = rsvpdata?.quantity;
  }
  let adult = "";
  if (rsvpdata?.adult) {
    adult = rsvpdata?.adult;
  }

  const initialValues = {
    accept_payment: payment?.accept_payment,
    stripe_account_id: payment?.stripe_account_id,
    event_type: "rsvp_and_pay",
    instituteid: post?.instituteid,
    postid: post?.postid,
    memberid: member?.memberid,
    // group_name: post?.grps[0] ? post.grps.filter(Boolean).map((item) => item.group_name) : [],
    group_name: post?.grps ? post?.grps?.filter(Boolean).map((item) => item?.group_id) : [],
    price: post?.paid_event_amount,
    description: post?.description,
    cardData: true,
    quantity: quantity,
    comment: "",
    stripe_account_id: member?.stripe_account_id,
    response: rsvpdata ? rsvpdata?.response : "",
    adult: rsvpdata ? rsvpdata?.adult : "",
    children: rsvpdata ? rsvpdata?.children : 0,
    sync: false,
  };

  const validationSchema = Yup.object().shape({
    response: Yup.string().required(MESSAGE.REQUIRED),

    adult: Yup.string().when("response", {
      is: (response) => response !== "no",
      then: Yup.string().required(MESSAGE.REQUIRED),
      otherwise: Yup.string(),
    }),

    quantity: Yup.string().when("response", {
      is: (response) => response !== "no",
      then: Yup.string().required(MESSAGE.REQUIRED),
      otherwise: Yup.string(),
    }),
  });


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      if (values?.response === "no") {
        const action = await dispatch(rsvpPostEvent({ ...values, cardData: false, price: 0 }));
        if (action?.type === rsvpPostEvent?.fulfilled?.type) {
          const { message } = action?.payload;
          Swal.fire({ text: message, ...Success });
        } else {
          const { message } = action?.payload;
          Swal.fire({ text: message ?? Note?.Err, ...Error });
        }
        handleClose();
      } else {
        handleClose();
        navigate("/payment", { state: { details: { ...values, post: post, price: values?.price * values?.quantity, access_token: syncToken, sync_on: syncOn } } });
      }
    },
  });




  let content = null;
  if (admin || teacher) {
    const modalRsvpPayContent = post?.postevent[0]
      ? post?.postevent?.filter(Boolean).filter((item) => item?.response != 'no').map((data, index) => {
        const user = post?.eventusers[0] && post?.eventusers?.find((e) => e?.id === data?.userid);
        const member = post?.eventmembers[0] && post?.eventmembers?.find((e) => e?.userid === data?.userid);
        return (
          <tr key={"modal-rsvpay-content-" + index}>
            <td>
              <div className="List-Attendees-user d-flex align-items-center">
                <figure>
                  <img 
                      className="avatarimage" 
                      src={user?.profilepic ? BASE + "/" + user?.profilepic : myprofile} 
                      alt="icon" 
                      onError={(e) => {
                        e.target.src = myprofile; 
                      }}  
                  />
                </figure>
                <h4>{user ? user?.fullname : "NA"} {member?.is_deleted && " (Deleted)"}</h4>
              </div>
            </td>
            <td>{data?.adult ?? 0}</td>
            <td>{data?.children ?? 0}</td>
            <td>{data?.price ? `$${data.price.toFixed(2)}` : "$0.00"}</td>
            <td>{data?.confirmPaymentId}</td>
            <td>{data?.comment}</td>
          </tr>
        );
      })
      : null;


    let totalprice = post?.postevent[0]
      ? parseFloat(post?.postevent.reduce((accumulator, currentObject) => {
        return accumulator + currentObject?.price;
      }, 0).toFixed(2))
      : 0.00;

    totalprice = totalprice.toFixed(2);

    let yes = { adult: 0, children: 0 };
    let maybe = { adult: 0, children: 0 };
    let no = { adult: 0, children: 0 };
    let show_header = false;
    if (post.postevent[0]) {
      post?.postevent.forEach((data) => {
        if (data?.response === "yes") {
          yes.adult = yes.adult + data?.adult;
          yes.children = yes.children + data?.children;
          show_header = true;
        } else if (data?.response === "maybe") {
          maybe.adult = maybe.adult + data?.adult;
          maybe.children = maybe.children + data?.children;
          show_header = true;
        } else if (data?.response === "no") {
          no.adult = no.adult + 1;
          no.children = no.children + data?.children;
        }
      });
    }

    content = (
      <div>
        <div className="all-posts-box similar-shadow-3">
          <h4>
            {post?.title}
            <Link to="#" className="calender-box">
              <i className="ti ti-calendar"></i>
            </Link>
          </h4>
          <PostGroups data={post} />
          <PostDescription data={post} />
        </div>
        <hr className="border-grey" />
        <div className="row ">
          <div className="col-md-4 text-center">
            <div className="adult-info purple-bg">
              <h4 className="text-white">Yes Going</h4>
              <div className="adult-info-num d-flex justify-content-between">
                <span className="text-white">
                  Adult(s):<b> {yes.adult}</b>
                </span>
                <span className="text-white">
                  Child(ren):<b> {yes.children}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="adult-info decline-bg">
              <h4>Decline</h4>
              <div className="adult-info-num d-flex justify-content-center">
                <span>
                  Adult(s):<b> {no.adult}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="adult-info grey-bg">
              <h4 className=""> Total Transaction Amount</h4>
              <div className="adult-info-num d-flex justify-content-center">
                <span>${totalprice}</span>
              </div>
            </div>
          </div>
        </div>
        <h4 className="sub-title mb-3 mt-4">List of Attendees & Transactions</h4>
        <div className="over-table">
          {post?.postevent[0] && show_header ? (
            <table width="100%" className="list-attendees">
              <thead>
                <tr>
                  <th>Member Name</th>
                  <th>Adult(s)</th>
                  <th>Child(ren)</th>
                  <th>Paid</th>
                  <th>Confirmation#</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>{modalRsvpPayContent}</tbody>
            </table>
          ) : (
            <div className="text-center">No data found</div>
          )}
        </div>
      </div>
    );
  }
  else if (rsvpdone) {

    const dueDate = new Date(post?.due_date);
    const startDate = new Date(post?.start_date);
    const currentDate = new Date();
    const thirtyMinutesAfterStart = addMinutes(startDate, 30);
    dueDate.setDate(dueDate?.getDate() + 1);
    const canPay = !isAfter(currentDate, dueDate) && !isAfter(currentDate, thirtyMinutesAfterStart);
    if (canPay) {

      content = (

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            {/* <div className="pay-box-content mb-4">
              <h4>Description</h4>
              <p>{post?.description}</p>
            </div> */}
              <h4>Description</h4>
            <PostDescription data={post} />
            <hr className="grey-line" />
            <div className="mb-4">
              <Field as="select" className="form-control text-dark" name="response" onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue('response', value);
                if (value === "no") {
                  formik.setFieldValue('adult', 0);
                } else {
                  formik.setFieldValue('adult', '');
                }
              }}>
                <option value="">Select Response</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Field>
              <ErrorMessage component="p" className="text-danger" name="response" />
            </div>
            {formik?.values?.response !== "no" ? (
              <>
                <div className="mb-4">
                  <Field as="select" name="adult" className="form-control text-dark">
                    <option value="">Select Adult</option>
                    {Entrant?.map((e, i) => (
                      <option value={e?.value} key={i}>
                        {e?.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="adult" />
                </div>
                <div className="mb-4">
                  <Field as="select" className="form-control text-dark" name="children">
                    <option value="">Select Children</option>
                    {Entrant?.map((e, i) => (
                      <option value={e?.value} key={i}>
                        {e?.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="children" />
                </div>
                <div className="mb-4">
                  <Field as="select" className="form-control text-dark" name="quantity">
                    <option value="">Quantity</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="quantity" />
                </div>
                <div className="mb-4 textarea-box">
                  <Field as="textarea" className="form-control" name="comment" placeholder="Add a comment" />
                  <ErrorMessage component="p" className="text-danger" name="comment" />
                </div>

                {formik.values.quantity &&
                  <div className="pay-box-content mb-4 d-flex justify-content-between">
                    <h4>Total Amount</h4>
                    <p>
                      <b className="blue-text">${(post?.paid_event_amount * formik?.values?.quantity).toFixed(2)} </b>
                    </p>
                  </div>
                }

              </>
            ) : null}
            <div className="btn-group-modal mt-4">
              <button type="submit" className="btn full-btn hvr-sweep-to-right">{formik?.values?.response !== "no" ? "Pay" : "Submit"}</button>
            </div>
          </Form>
        </FormikProvider>
      );

    } else {
      content = <p>RSVP PAY is no longer available.</p>;
      setTimeout(() => handleClose(), 3000);
    }

  }
  else {

    const dueDate = new Date(post?.due_date);
    const startDate = new Date(post?.start_date);
    const currentDate = new Date();
    const thirtyMinutesAfterStart = addMinutes(startDate, 30);
    dueDate?.setDate(dueDate?.getDate() + 1);
    const canPay = !isAfter(currentDate, dueDate) && !isAfter(currentDate, thirtyMinutesAfterStart);
    if (canPay) {
      content = (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            {/* <div className="pay-box-content mb-4">
              <h4>Description</h4>
              <p>{post?.description}</p>
            </div> */}
              <h4>Description</h4>
              <PostDescription data={post} />
            <hr className="grey-line" />
            <div className="mb-4">
              <Field as="select" className="form-control text-dark" name="response" onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue('response', value);
                if (value === "no") {
                  formik.setFieldValue('adult', 0);
                } else {
                  formik.setFieldValue('adult', '');
                }
              }}>
                <option value="">Select Response</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Field>
              <ErrorMessage component="p" className="text-danger" name="response" />
            </div>
            {formik?.values?.response !== "no" ? (
              <>
                <div className="mb-4">
                  <Field as="select" name="adult" className="form-control text-dark">
                    <option value="">Select Adult</option>
                    {Entrant?.map((e, i) => (
                      <option value={e?.value} key={i}>
                        {e?.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="adult" />
                </div>
                <div className="mb-4">
                  <Field as="select" className="form-control text-dark" name="children">
                    <option value="">Select Children</option>
                    {Entrant?.map((e, i) => (
                      <option value={e?.value} key={i}>
                        {e?.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="children" />
                </div>
                <div className="mb-4">
                  <Field as="select" className="form-control text-dark" name="quantity">
                    <option value="">Quantity</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Field>
                  <ErrorMessage component="p" className="text-danger" name="quantity" />
                </div>
                <div className="mb-4 textarea-box">
                  <Field as="textarea" className="form-control" name="comment" placeholder="Add a comment" />
                  <ErrorMessage component="p" className="text-danger" name="comment" />
                </div>

                <label className="coustom-checkbox s-check-box me-4">
                  <Field type="checkbox" name="sync" onChange={(e) => {
                    if (e.target?.checked) {
                      formik.setFieldValue('sync', true);
                      setSyncShow(true);
                    } else { formik.setFieldValue('sync', false); }
                  }} />
                  <span className="checkmark"></span>
                  <p>Add to external calendar</p>
                </label>


                {formik.values.quantity &&
                  <div className="pay-box-content mb-4 d-flex justify-content-between">
                    <h4>Total Amount</h4>
                    <p>
                      <b className="blue-text">${(post?.paid_event_amount * formik?.values?.quantity).toFixed(2)} </b>
                    </p>
                  </div>
                }
              </>
            ) : null}



            <div className="btn-group-modal mt-4">
              <button type="submit" className="btn full-btn hvr-sweep-to-right">{formik?.values?.response !== "no" ? "Pay" : "Submit"}</button>
            </div>
          </Form>
        </FormikProvider>
      );
    } else {
      content = <p>Payment is no longer available.</p>;
      setTimeout(() => handleClose(), 3000);
    }
  }





  return (
    <>
      <Modal size={admin || teacher ? "lg" : "md"} centered show={rsvpAndPay} onHide={handleClose} keyboard={false}>
        <Modal.Header className="px-4" closeButton>
          <Modal.Title className="ms-auto event-title">RSVP & PAY</Modal.Title>
        </Modal.Header>
        <div className="modal-body">{content}</div>
      </Modal>

      <Modal show={syncShow} onHide={() => { setSyncShow(false); formik.setFieldValue('sync', false); }}>
        <Modal.Header closeButton>
          <Modal.Title>Add to calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='syncButton' style={{ textAlign: "center" }}>

            <button onClick={() => {
              setSyncShow(false);

              setSyncToken("");
              setSyncOn('Apple');
            }}><i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendar apps (ICS) </button>

            <br></br>
            <br></br>

            <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
              <GoogleCalenderEvent updateParentState={updateParentState}></GoogleCalenderEvent>
            </GoogleOAuthProvider>
          </div>
          <br></br>
          <OutlookAuth updateParentState={updateParentState} />
        </Modal.Body>

      </Modal>
    </>

  );
};

export default RsvpPay;
