import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SchoolAdmin from "../../services/admin.service";
import { useSelector } from "react-redux";
import { Error, Info, Note, Success } from "../../utils/Alert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";



const UpdateRenewal = () => {
  const auth = useSelector((state) => state?.auth);
  const [sendRenewal, setSendRenewal] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await AuthService.me();
        const admininfo = response?.data?.data?.roledata?.find((data) => data?.role === "admin");
        setSendRenewal(admininfo?.auto_renewal);
        setLoader(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoader(false);
      }
    };
  
    fetchData();
  }, []);


  const handleCheckboxChange = (event) => {
    setSendRenewal(event?.target?.checked);
  };
  const navigate = useNavigate();


  const handleUpdate = (e, value) => {
    e.preventDefault();
    const instituteid=auth?.adminInfo?.instituteid;  
    const accept_payment=auth?.adminInfo?.accept_payment; 
    const stripe_account_id=auth?.adminInfo?.stripe_account_id; 


    if(stripe_account_id==null || stripe_account_id==""){

      Swal.fire({ text: Note.EnterFinancialInfo, ...Error });
      setSendRenewal(false);
        setTimeout(() => {
          navigate("/finance");
        }, 2000);
    
      return false;
    }


    if(accept_payment==false){
      Swal.fire({ text: "Financial information has not been verified. Renewal Settings cannot be updated yet.", ...Error });
      setSendRenewal(false);
      return false;
    }


  
    SchoolAdmin.updateReminder({instituteid:instituteid, auto_renewal:sendRenewal  })
      .then((res) => {
        setSendRenewal(sendRenewal);
        Swal.fire({ text: res?.data?.message, icon: "info" , timer: 3000});
      })
      .catch((error) => {
        const { message } = error?.response?.data;
        Swal.fire({ text: message, icon: "error" });
      });
  };

  return (
    <>
    {loader ? "Loading.. " : 
    
    <div className="tab-pane fade show active" id="tabs-3" role="tabpanel" aria-labelledby="tabbing-3">
    <div className="pb-5">
      <h3 className="inner-title mb-4">Update Renewal Settings</h3>
      <form onSubmit={handleUpdate}>
        <div className="update-renewal-box d-flex justify-content-between">
          <h5>Auto Send Renewal DM Posts</h5>
          <label className="switch">
            <input type="checkbox" name="sendrenewal" checked={sendRenewal}   onChange={handleCheckboxChange} />
            
            <span className="slider round"></span>
          </label>
        </div>
        <div className="mt-5 btn-box ">
          <button type="submit" className="btn mr-auto big-btn hvr-sweep-to-right ">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>

    }
    
    </>
  );
};

export default UpdateRenewal;
