import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
import { BASE } from "../utils/Constants";
import { userRoles, getAdminInfo } from "../utils/RoleCheck";

export const getMe = createAsyncThunk("auth/getMe", async () => {
  const { data } = await AuthService.me();
  return data?.data;
});

const user = JSON.parse(localStorage.getItem("user"));

const loggedInUser = { loading: true,  profile: null, logintype: [], allInstitutesIds: [], userImage: null, adminInfo: null , reminder_time:""};

const initialState = {
  token: user ? user?.token : null,
  welcomeScreen: user ? user?.welcomeScreen : false,
  firstTime : false,
  ...loggedInUser,
};

const updateMe = (state, { payload }) => {
  state.profile = payload;
  if (payload?.profilepic) {
    state.userImage = BASE + "/" + payload?.profilepic;
  }
  state.logintype = userRoles(payload?.roledata);
  state.allInstitutesIds = payload?.roledata?.filter(data => !data?.is_deleted && data?.membership_status).map((data) => data?.instituteid);
  state.adminInfo = getAdminInfo(payload?.roledata);
  state.loading = false;
  state.firstTime = payload?.welcomeScreen === 1 ? false : true;
  // state.welcomeScreen = payload?.welcomeScreen;
  state.reminder_time = payload?.reminder_time;
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: updateMe,
    setUserImage: (state, action) => {
      state.userImage = BASE + "/" + action?.payload;
    },
    setWelcomeScreen: (state, action) => {
      state.welcomeScreen = action?.payload;
    },
    setToken: (state, action) => {
      state.token = action?.payload;
    },
    setReminderTime: (state, action) => {
      state.reminder_time = action?.payload;
    },
    logout: (state, action) => {
      const loggedOut = { token: null, welcomeScreen: false, ...loggedInUser };
      return loggedOut;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe?.fulfilled, updateMe);
  },
});

export const { setAuth, setUserImage, setWelcomeScreen, setToken,setReminderTime, logout } = authSlice?.actions;

export default authSlice?.reducer;
