import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import myprofile from "../../assets/images/DefaultUserIcon.png";
import { roleCheck } from "../../utils/RoleCheck";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/authSlice";
import NotificationList from "../NotificationList";

const PrivateNavbar = () => {
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  const sidebarList = () => {
    let arr = [
      {
        icon: "ti ti-user",
        item: "Profile",
        url: "/profile",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
      {
        icon: "ti ti-users",
        item: "Groups",
        url: "/groups",
        allowedRoles: ["teacher", "parent"],
      },
      {
        icon: "ti ti-users",
        item: "Members",
        url: "/members",
        allowedRoles: ["teacher", "parent"],
      },
      {
        icon: "ti ti-users",
        item: "Manage Groups",
        url: "/manage-groups",
        allowedRoles: ["admin"],
      },
      {
        icon: "ti ti-user",
        item: "Manage Members",
        url: "/manage-members",
        allowedRoles: ["admin"],
      },
      {
        icon: "ti ti-receipt",
        item: "Billing Summary",
        url: "/billing-summary",
        allowedRoles: ["admin", "parent", "member"],
      },
      {
        icon: "ti ti-calendar-event",
        item: "My Calendar",
        url: "/my-calendar",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
      {
        icon: "ti ti-message-2",
        item: "Send DM",
        url: "/sendmessage",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
      {
        icon: "ti ti-layout-grid",
        item: "Campaign Manager",
        url: "/campaign-manager",
        allowedRoles: ["admin"],
      },
      {
        icon: "ti ti-wallet",
        item: "Manage Financial Information",
        url: "/finance",
        allowedRoles: ["admin"],
      },
      { icon: "ti ti-settings", item: "Settings", url: "/settings/update-password", allowedRoles: ["admin", "teacher", "parent", "member"] },
      {
        icon: "ti ti-player-play",
        item: "Tutorial",
        url: "/tutorial",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
      {
        icon: "ti ti-file-text",
        item: "Terms & Conditions",
        url: "/termsandcondition",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
      {
        icon: "ti ti-lock",
        item: "Privacy Policy",
        url: "/privacypolicy",
        allowedRoles: ["admin", "teacher", "parent", "member"],
      },
    ];

    return arr.map((data, index) => (
      <div key={"sidebarList" + index}>
        {roleCheck(data.allowedRoles, auth?.logintype) ? (
          <li className="nav-item" data-bs-dismiss="offcanvas">
            <Link to={data.url} className="nav-link active" aria-current="page">
              <i className={data.icon}></i>
              {data.item}
            </Link>
          </li>
        ) : null}
      </div>
    ));
  };

  return (
    <div>
      <header className="inner-header sticky" id="fixed-header">
        <div className="container">
          <nav className="navbar d-flex justify-content-between align-items-center mt-2">
            <div className="nav-L">
              <a href="/home" className="navbar-brand desktop-view-logo" style={{ pointerEvents: auth?.welcomeScreen ? "unset" : "none" }}>
                <img src={logo} alt="logo" />
              </a>
              <Link to="/home" className="navbar-brand mob-logo-view " style={{ display: "none" }}></Link>
            </div>
            <div className="nav-R d-flex align-items-center menu">

              <NotificationList />
              <Link to="/profile" className="user-img ms-3" style={{ pointerEvents: auth?.welcomeScreen ? "unset" : "none" }}>
                <img
                  src={auth?.userImage ? auth?.userImage : myprofile}
                  alt="logo"
                  onError={(e) => {
                    e.target.src = myprofile;
                  }}
                />
              </Link>
              <Link
                to="#"
                className="navbar-toggler ms-3"
                type="button"
                aria-expanded="true"
                aria-label="Toggle navigation"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                style={{ pointerEvents: auth?.welcomeScreen ? "unset" : "none" }}
              >
                <span className="navbar-toggler-icon">
                  <i className="ti ti-menu-2"></i>
                </span>
              </Link>
              <div className="offcanvas offcanvas-end nav-sidebar-main" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <Link to="#" className="closebtn" id="close" data-bs-dismiss="offcanvas" aria-label="Close" data-backdrop="false">
                  ×
                </Link>
                <div className="nav-sidebar-inner">
                  <ul className="nav-sidebar-inner-list">
                    {sidebarList()}
                    <li className="nav-item">


                      <Link to="/contact" className="nav-link" target="_blank"> <i className="ti ti-phone"></i> Contact Us</Link>
                    </li>
                    {auth?.welcomeScreen && (
                      <li className="nav-item">
                        <button className="nav-link logout-btn" onClick={handleLogout}>
                          <i className="ti ti-logout"></i>Logout
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default PrivateNavbar;
