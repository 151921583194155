import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { Spinner } from "react-bootstrap";

const TermsCondition = () => {

  const [pageDetail, setPageDetail] = useState(null)

  async function getCMSDetail() {
    const response = await AuthService.getAllCms({ slug: "terms_and_conditions" });
    setPageDetail(response?.data?.data);
  }


  useEffect(function () {
    getCMSDetail();
  }, []);


  return (
    <>
      <div className="wraper-inner bg-grey  space-top">
        <section className="terms-box pt-4 pb-5">
          <div className="container">
            <div className="similar-shadow-box main-box pb-5" >

              {pageDetail?.title ? <div>
                <h3 className="inner-title mb-4 text-center">{pageDetail?.title}</h3>
                <div className="terms-content">
                  <div dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
                </div>
              </div>
                :
                <div className="main-box" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" variant="success" /> </div>
              }


            </div>
          </div>
        </section>


      </div>
    </>


  );
};

export default TermsCondition;
