import DateFormat from "../../../utils/DateFormat";

const PostTitle = ({ data }) => {
  return (
    <>
      <small>{DateFormat(data?.sponsered ? data?.start_date : data?.created_at, true)}</small>
      <h4>{data?.title}</h4>
    </>
  );
};

export default PostTitle;
