import { useSelector } from "react-redux";
import FinanceForm from "./FinanceForm";

const Add = () => {
  const { adminInfo } = useSelector((state) => state?.auth);
  const initialValues = {
    instituteid: adminInfo?.instituteid,
    financial_info: "bank",
    fname: "",
    lname: "",
    account_number: "",
    cnfrm_account_number : "",
    cvv: "",
    exp_date: "",
    routing_number: "",
    account_holder_type: "",
    card_no: "",
    type: "add",
  };
  return <FinanceForm initialValues={initialValues} type="Add" />;
};

export default Add;
